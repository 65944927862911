import request from '../utils/request'

// 影像协议
export function dcmApprove(data) {
    return request({
        url: "/dicom/approve",
        method: "post",
        data
    })
}


// 影像数据 
export function dcmGet(data) {
    return request({
        url: "/dicom/get",
        method: "post",
        data
    })
}