// import $ from "./jquery-2.0.0.min";
import FastClick from "../Public/ext/fastclick/fastclick"
import { onMousewheel, renderStudyLayout } from "../Public/tg/gui"
import { dcmGet } from "@/api/dcm"
window.meetingEnabled = false;
(function($, cornerstone, cornerstoneTools) {

    'use strict';

    var configuration = {
        magnifySize: 100,
        magnificationLevel: 2
    };

    var browserName;

    /** Remove the magnifying glass when the mouse event ends */
    function mouseUpCallback(e, eventData) {
        $(eventData.element).off('CornerstoneToolsMouseDrag', dragCallback);
        $(eventData.element).off('CornerstoneToolsMouseUp', mouseUpCallback);
        hideTool(eventData);
    }

    function hideTool(eventData) {
        $(eventData.element).find('.magnifyTool').hide();
        // Re-enable the mouse cursor
        document.body.style.cursor = 'default';
    }

    /** Draw the magnifying glass on mouseDown, and begin tracking mouse movements */
    function mouseDownCallback(e, eventData) {
        if (cornerstoneTools.isMouseButtonEnabled(eventData.which, e.data.mouseButtonMask)) {
            $(eventData.element).on('CornerstoneToolsMouseDrag', eventData, dragCallback);
            $(eventData.element).on('CornerstoneToolsMouseUp', eventData, mouseUpCallback);
            drawMagnificationTool(eventData);
            return false; // false = causes jquery to preventDefault() and stopPropagation() this event
        }
    }

    function dragEndCallback(e, eventData) {
        $(eventData.element).off('CornerstoneToolsDragEnd', dragEndCallback);
        $(eventData.element).off('CornerstoneToolsTouchEnd', dragEndCallback);
        hideTool(eventData);
    }

    /** Drag callback is triggered by both the touch and mouse magnify tools */
    function dragCallback(e, eventData) {
        drawMagnificationTool(eventData);
        if (eventData.isTouchEvent === true) {
            $(eventData.element).on('CornerstoneToolsDragEnd', dragEndCallback);
            $(eventData.element).on('CornerstoneToolsTouchEnd', dragEndCallback);
        }

        return false; // false = causes jquery to preventDefault() and stopPropagation() this event
    }

    /** Draws the magnifying glass */
    function drawMagnificationTool(eventData) {
        var magnify = $(eventData.element).find('.magnifyTool').get(0);

        if (!magnify) {
            magnify = createMagnificationCanvas(eventData.element);
        }

        var config = cornerstoneTools.magnify.getConfiguration();

        var magnifySize = config.magnifySize;
        var magnificationLevel = config.magnificationLevel;

        // The 'not' magnifyTool class here is necessary because cornerstone places
        // no classes of it's own on the canvas we want to select
        var canvas = $(eventData.element).find('canvas').not('.magnifyTool').get(0);
        var context = canvas.getContext('2d');
        context.setTransform(1, 0, 0, 1, 0, 0);

        var zoomCtx = magnify.getContext('2d');
        zoomCtx.setTransform(1, 0, 0, 1, 0, 0);

        var getSize = magnifySize / magnificationLevel;

        // Calculate the on-canvas location of the mouse pointer / touch
        var canvasLocation = cornerstone.pixelToCanvas(eventData.element, eventData.currentPoints.image);

        if (eventData.isTouchEvent === true) {
            canvasLocation.y -= 1.25 * getSize;
        }

        canvasLocation.x = Math.max(canvasLocation.x, 0);
        canvasLocation.x = Math.min(canvasLocation.x, canvas.width);

        canvasLocation.y = Math.max(canvasLocation.y, 0);
        canvasLocation.y = Math.min(canvasLocation.y, canvas.height);

        // Clear the rectangle
        zoomCtx.clearRect(0, 0, magnifySize, magnifySize);
        zoomCtx.fillStyle = 'transparent';

        // Fill it with the pixels that the mouse is clicking on
        zoomCtx.fillRect(0, 0, magnifySize, magnifySize);

        var copyFrom = {
            x: canvasLocation.x - 0.5 * getSize,
            y: canvasLocation.y - 0.5 * getSize
        };

        if (browserName === 'Safari') {
            // Safari breaks when trying to copy pixels with negative indices
            // This prevents proper Magnify usage
            copyFrom.x = Math.max(copyFrom.x, 0);
            copyFrom.y = Math.max(copyFrom.y, 0);
        }

        copyFrom.x = Math.min(copyFrom.x, canvas.width);
        copyFrom.y = Math.min(copyFrom.y, canvas.height);

        var scaledMagnify = {
            x: (canvas.width - copyFrom.x) * magnificationLevel,
            y: (canvas.height - copyFrom.y) * magnificationLevel
        };
        zoomCtx.drawImage(canvas, copyFrom.x, copyFrom.y, canvas.width - copyFrom.x, canvas.height - copyFrom.y, 0, 0, scaledMagnify.x, scaledMagnify.y);

        // Place the magnification tool at the same location as the pointer
        magnify.style.top = canvasLocation.y - 0.5 * magnifySize + 'px';
        magnify.style.left = canvasLocation.x - 0.5 * magnifySize + 'px';

        magnify.style.display = 'block';

        // Hide the mouse cursor, so the user can see better
        document.body.style.cursor = 'none';
    }

    /** Creates the magnifying glass canvas */
    function createMagnificationCanvas(element) {
        // If the magnifying glass canvas doesn't already exist
        if ($(element).find('.magnifyTool').length === 0) {
            // Create a canvas and append it as a child to the element
            var magnify = document.createElement('canvas');
            // The magnifyTool class is used to find the canvas later on
            magnify.classList.add('magnifyTool');

            var config = cornerstoneTools.magnify.getConfiguration();
            magnify.width = config.magnifySize;
            magnify.height = config.magnifySize;

            // Make sure position is absolute so the canvas can follow the mouse / touch
            magnify.style.position = 'absolute';
            element.appendChild(magnify);
            return magnify;
        }
    }

    /** Find the magnifying glass canvas and remove it */
    function removeMagnificationCanvas(element) {
        $(element).find('.magnifyTool').remove();
    }

    // --- Mouse tool activate / disable --- //
    function disable(element) {
        $(element).off('CornerstoneToolsMouseDown', mouseDownCallback);
        removeMagnificationCanvas(element);
    }

    function enable(element) {
        var config = cornerstoneTools.magnify.getConfiguration(config);

        if (!browserName) {
            var infoString = cornerstoneTools.getBrowserInfo();
            var info = infoString.split(' ');
            browserName = info[0];
        }

        createMagnificationCanvas(element);
    }

    function activate(element, mouseButtonMask) {
        var eventData = {
            mouseButtonMask: mouseButtonMask
        };

        $(element).off('CornerstoneToolsMouseDown', mouseDownCallback);

        $(element).on('CornerstoneToolsMouseDown', eventData, mouseDownCallback);
        createMagnificationCanvas(element);
    }

    // --- Touch tool activate / disable --- //
    function getConfiguration() {
        return configuration;
    }

    function setConfiguration(config) {
        configuration = config;
    }

    // module exports
    cornerstoneTools.magnify = {
        enable: enable,
        activate: activate,
        deactivate: disable,
        disable: disable,
        getConfiguration: getConfiguration,
        setConfiguration: setConfiguration
    };

    var options = {
        fireOnTouchStart: true,
        activateCallback: createMagnificationCanvas,
        disableCallback: removeMagnificationCanvas
    };
    cornerstoneTools.magnifyTouchDrag = cornerstoneTools.touchDragTool(dragCallback, options);

})($, cornerstone, cornerstoneTools);


function getQueryString(name) {
    var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
    var r = window.location.search.substr(1).match(reg);
    if (r != null) {
        return unescape(r[2]);
    }
    return null;
}

window.viewMode = 'normal';
window.studyInstanceUID = '1.2.840.113820.104.1827.120170117142517';
window.org = '4';
window.seriesInstanceUID = getQueryString('seriesInstanceUID');

// dcm
window.requestDicomUrl = "";
window.domainName = ""

// mr
window.requestDicomUrl = "";
window.domainName = ""

// ct
window.requestDicomUrl = "";
window.domainName = ""

window.study = {
    storage: null
};
window.that;
window.currentSeries = {};
window.currentTags = [];
window.imageSilder = null;
window.isSwitchSliceUseSlider = false;
window.isPseudocolor = false;
window.from = 'app';
window.frameRate = 1;
window.isPlaying = false;
export function viewInit(e){
    window.that = e;
}
$(document).ready(function() {
    FastClick.attach(document.body);

    var win_h = $(window).height();
    var win_w = $(window).width();

    $('#dicomImage,#dicomImageWrapper').height($(window).height() - 119).width($(window).width());
    $('#series-view').height($(window).height() - 66);
    //$('#loading').css({'left': ((win_w) / 2 - 25) + 'px', 'top': '0px'});
    $('#loading').css({ 'left': ((win_w) / 2 - 25) + 'px', 'top': ((win_h - 119) / 2 - 40) + 'px' });

    loadData();

    //gaolixin, dicom滚轮播放
    $(document).on("mousewheel DOMMouseScroll", onMousewheel);
});

export function loadData() {
    dcmGet({
        studyInstanceUID2:that.$route.query.studyInstanceUID2
    }).then(res =>{
        console.log(res);
        if(res.data.errorCode == 0){
            if(res.data.data.cusApproved == 0){
                that.agreementShow = true;
                console.log(that);
            }else{
                window.domainName = "http://"+res.data.data.host+'/';
                currentSeries = {};
                let result = eval("("+res.data.data.jsonData+")")
                window.study = {...result.result };
                window.study.storage = "dicomweb://" + res.data.host + "/"
                renderStudyLayerInfo();
                if (window.study.modality == 'CT') {
                    $('.ct-preset').show();
                }
                for (var i = 0; i < study.series.length; i++) {
                    if (study.series[i].series_description == '' || study.series[i].series_description == null) study.series[i].series_description = 'N/A';
                    var instanceUrls = [];
                    var instanceIds = study.series[i].instance_ids.split(',');
                    for (var j = 0; j < instanceIds.length; j++) {
                        if (org == "7" && study.modality != 'DR' && study.source != 'ManualUpload') {
                            instanceUrls.push('{0}{1}/{2}.{3}.jpg'.format(study.storage, study.study_instance_uid, study.series[i].series_number, instanceIds[j]));
                        } else {
                            if (study.source == 'jpg' || study.modality == 'MG' || study.modality == 'DR' || study.modality == 'CR' || study.modality == 'RF' || study.modality == 'DX') {
                                instanceUrls.push('{0}{1}/{2}.{3}.jpg'.format(study.storage, study.study_instance_uid, study.series[i].series_number, instanceIds[j]));
                            } else {
                                instanceUrls.push('{0}{1}/{2}.{3}.dcm'.format(study.storage, study.study_instance_uid, study.series[i].series_number, instanceIds[j]));
                            }
                        }
                    }
                    /*******本地测试开始********/
                    // var arr = [];
                    // instanceUrls.map(function (item) {
                    //     arr.push(item.replace('dicomweb://dcm.11zhushou.com/', 'dicomweb://localhost:7005/'));
                    // });
                    // study.series[i].urls = arr;
                    /*******本地测试结束********/
                    study.series[i].urls = instanceUrls;
                }
                renderStudyLayout(seriesInstanceUID);
            }
        }
    })
}

function renderStudyLayerInfo() {
    $('#patientName').html(study.patient_sex + '/' + study.patient_age);
    $('#patientId').html('PID: ' + study.patient_id);
    $('#accession_number').html('ANO: ' + study.accession_number);
    $('#modality').html(study.modality + '^' + study.bodypart);
    $('#protocol_name').html(study.protocol_name);
    $('#description').html(study.description);
    var studyDateTime = study.study_date;
    var studyDate = '';
    var studyTime = '';
    if (studyDateTime.length == 8) {
        studyDate = studyDateTime;
    }
    if (studyDateTime.length > 8) {
        studyDate = studyDateTime.substring(0, 8);
        studyTime = studyDateTime.substring(8);
    }
    $('#studyDate').html('Study Date: ' + studyDate);
    $('#studyTime').html('Study Time: ' + studyTime);
}


function goToBack() {
    history.go(-1);
}

var footer1 = null;
var footer2 = null;
var footer = null;


$(document).ready(function() {
    footer1 = $('#footer1');
    footer2 = $('#footer2');

    $('body').bind('touchmove', function(event) {
        event.preventDefault();
    }, { passive: false });
});

function closeCtrlBox() {
    $('.ctrl-box,#ctrl-close').hide();
}

export function showSlider() {
    $('#footer2').hide();
    if (currentSeries.urls && currentSeries.urls.length > 1) {
        $('#footer-slider').show();
    }
}
window.imageElement = $('#dicomImage').get(0);
window.imageIds = [];

// Listen for changes to the viewport so we can update the text overlays in the corner
function onViewportUpdated(e) {
    var viewport = cornerstone.getViewport(e.target)
    $('#wwwcLabel').text("WW/WC: " + Math.round(viewport.voi.windowWidth) + "/" + Math.round(viewport.voi.windowCenter));
    $('#zoomText').text("Zoom: " + viewport.scale.toFixed(2));
}

let timer = setInterval(() => {
    if ($("#dicomImage")) {
        $('#dicomImage').on("CornerstoneImageRendered", onViewportUpdated);
        $("#dicomImage").on("CornerstoneNewImage", onNewImage);
        clearInterval(timer)
    }
}, 500);


function onNewImage(e, data) {
    //var newImageIdIndex = stack.currentImageIdIndex;
    var stackToolDataSource = cornerstoneTools.getToolState(imageElement, 'stack');
    if (stackToolDataSource === undefined) {
        return;
    }
    var stackData = stackToolDataSource.data[0];

    var newImageIdIndex = stackData.currentImageIdIndex;

    // Update the slider value
    if (!isSwitchSliceUseSlider) imageSilder.slider('setValue', newImageIdIndex);

    // Populate the current slice span
    var currentValueSpan = document.getElementById("sliceText");
    currentValueSpan.textContent = "Im: " + (newImageIdIndex + 1) + "/" + imageIds.length;

    // if we are currently playing a clip then update the FPS
    var playClipToolData = cornerstoneTools.getToolState(imageElement, 'playClip');
    if (playClipToolData !== undefined && !$.isEmptyObject(playClipToolData.data)) {
        $("#frameRate").text("FPS: " + Math.round(data.frameRate));
    } else {
        if ($("#frameRate").text().length > 0) {
            $("#frameRate").text("");
        }
    }


}


export function specifyImageIndex(newImageIdIndex) {
    // Get the stack data
    var stackToolDataSource = cornerstoneTools.getToolState(imageElement, 'stack');
    if (stackToolDataSource === undefined) {
        return;
    }
    var stackData = stackToolDataSource.data[0];
    if (newImageIdIndex == 'next') {
        newImageIdIndex = stackData.currentImageIdIndex + 1;
    }
    if (newImageIdIndex == 'pre') {
        newImageIdIndex = stackData.currentImageIdIndex - 1;
    }
    // Switch images, if necessary
    if (newImageIdIndex !== stackData.currentImageIdIndex && stackData.imageIds[newImageIdIndex] !== undefined) {
        $('#loading').show();
        cornerstone.loadAndCacheImage(stackData.imageIds[newImageIdIndex]).then(function(image) {
            $('#loading').hide();
            var viewport = cornerstone.getViewport(imageElement);
            stackData.currentImageIdIndex = newImageIdIndex;
            cornerstone.displayImage(imageElement, image, viewport);
            cornerstoneTools.orientationMarkers.enable(imageElement);
            if (isPseudocolor) setPseudocolor();
        });
    }
}

export function disableAllTools() {
    cornerstoneTools.panTouchDrag.deactivate(imageElement);
    cornerstoneTools.rotateTouchDrag.deactivate(imageElement);
    cornerstoneTools.rotateTouch.disable(imageElement);
    cornerstoneTools.ellipticalRoiTouch.deactivate(imageElement);
    cornerstoneTools.angleTouch.deactivate(imageElement);
    cornerstoneTools.rectangleRoiTouch.deactivate(imageElement);
    cornerstoneTools.lengthTouch.deactivate(imageElement);
    cornerstoneTools.probeTouch.deactivate(imageElement);
    cornerstoneTools.zoomTouchDrag.deactivate(imageElement);
    cornerstoneTools.wwwcTouchDrag.deactivate(imageElement);
    cornerstoneTools.stackScrollTouchDrag.deactivate(imageElement);
    cornerstoneTools.magnifyTouchDrag.deactivate(imageElement);


    cornerstoneTools.wwwc.disable(imageElement);

    cornerstoneTools.length.deactivate(imageElement, 1);
    cornerstoneTools.rectangleRoi.deactivate(imageElement, 1);
    cornerstoneTools.ellipticalRoi.deactivate(imageElement, 1);
    cornerstoneTools.angle.deactivate(imageElement, 1);
    cornerstoneTools.probe.deactivate(imageElement, 1);

}

// image enable the dicomImage element
$(document).ready(function() {
    //cornerstone.enable(imageElement);
    var config = {
            drawAllMarkers: true
        }
        // Comment this out to draw only the top and left markers
    cornerstoneTools.orientationMarkers.setConfiguration(config);
});


function showQrcode() {
    var qrcodeDlgEle = document.getElementById('qrcodeDlg');
    var flag = qrcodeDlgEle.style.display;
    if (flag == 'none') {
        qrcodeDlgEle.style.display = '';
        document.getElementById('mask').style.display = '';
    } else {
        qrcodeDlgEle.style.display = 'none';
        document.getElementById('mask').style.display = 'none';
    }
}

function guid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0,
            v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

var curSessionId = guid();
var my_room = studyInstanceUID;
var socket = null;
var isEmitSwitchAction = true;
var isEmitScrollImageAction = true;

function enableMeeting() {
    meetingEnabled = true;
    socket = io("http://www..com:8091");

    socket.on('connect', function() {
        socket.emit('room', my_room);
    });

    socket.on('send_message', function(msg) {
        if (!meetingEnabled) return false;
        var msg1 = eval('(' + msg + ')'); //json数据 序列化成js对象
        if (msg1.my_name == curSessionId) return;
        var content = msg1.my_content.split('|');
        var eventName = content[0];
        var eventParm1 = content[1];
        var eventParm2 = content[2];
        var seriesIndex, imageIndex;
        switch (eventName) {
            case 'Layout': //布局
                var page = eventParm1;
                break;
            case 'WCWL': //窗宽窗位
                var id = eventParm1;
                doAction({
                    action1: 'SwitchFeatureGroup',
                    action2: 'windowing'
                }, document.getElementById('footer1_windowing'));
                break;
            case 'WCWL-Listener': //窗宽窗位监听
                var ww = content[1];
                var wc = content[2];
                var viewport = cornerstone.getViewport(imageElement);
                viewport.voi.windowWidth = parseFloat(ww);
                viewport.voi.windowCenter = parseFloat(wc);
                cornerstone.setViewport(imageElement, viewport);
                break;
            case 'Pan-Listener': //移动-监听
                var x = content[1];
                var y = content[2];
                var viewport = cornerstone.getViewport(imageElement);
                viewport.translation.x = parseFloat(x);
                viewport.translation.y = parseFloat(y);
                cornerstone.setViewport(imageElement, viewport);
                break;
            case 'Zoom-Listener': //缩放-监听
                var scale = content[1];
                var viewport = cornerstone.getViewport(imageElement);
                viewport.scale = parseFloat(scale);
                cornerstone.setViewport(imageElement, viewport);
                break;
            case 'SwitchSeries':
                try {
                    var stackIndex = parseInt(content[1]);
                    isEmitSwitchAction = false;
                    var seriesNumber = study.series[stackIndex].series_number
                    switchSeries(seriesNumber, 1);
                } catch (e) {}
                break;
            case 'Inverse': //反色
                meetingEnabled = false;
                doAction({
                    action1: 'Windowing',
                    action2: 'setToInvplain'
                }, document.getElementById('footer2_invplain'));

                disableAllTools();
                meetingEnabled = true;
                break;
            case 'Pan': //平移
            case 'Zoom': //缩放
                var id = eventParm1;
                doAction({
                    action1: 'SwitchFeatureGroup',
                    action2: 'zoomPan'
                }, document.getElementById('footer1_zoompan'));
                break;
            case 'RotationLeft': //左旋
                var id = eventParm1;
                var viewport = cornerstone.getViewport(imageElement);
                viewport.rotation -= 90;
                cornerstone.setViewport(imageElement, viewport);
                break;
            case 'RotationRight': //右旋
                var id = eventParm1;
                var viewport = cornerstone.getViewport(imageElement);
                viewport.rotation += 90;
                cornerstone.setViewport(imageElement, viewport);
                break;
            case 'Reset': //重置
                var id = eventParm1;
                meetingEnabled = false;

                doAction({ action1: 'MoreFeatures', action2: 'reset' }, document.getElementById('footer2_reset'));

                disableAllTools();
                meetingEnabled = true;
                break;
            case 'Eraser': //清理
                var id = eventParm1;
                var toolStateManager = cornerstoneTools.globalImageIdSpecificToolStateManager;
                toolStateManager.clear(imageElement)
                cornerstone.updateImage(imageElement);
                break;
            case 'Play': //播放
                var id = eventParm1;
                meetingEnabled = false;

                doAction({ action1: 'Cine', action2: 'play' }, document.getElementById('footer-slider-play-ctrl'));

                disableAllTools();
                meetingEnabled = true;
                break;
            case 'StopPlay':
                meetingEnabled = false;

                doAction({
                    action1: 'Cine',
                    action2: 'pause'
                }, document.getElementById('footer-slider-playing-ctrl'));

                disableAllTools();
                meetingEnabled = true;
                break;
            case 'M-Line': //测量-直线
                doAction({ action1: 'Measure', action2: 'line' }, document.getElementById('footer2_line'));
                break;
            case 'M-Angle': //测量-角度
                //action_m_angle();
                break;
            case 'M-Probe': //测量-探针
                doAction({ action1: 'Measure', action2: 'probe' }, document.getElementById('footer2_probe'));
                break;
            case 'M-Ellipse': //测量-椭圆
                doAction({ action1: 'Measure', action2: 'ellipse' }, document.getElementById('footer2_ellipse'));
                break;
            case 'M-Annotation': //测量-标注
                //action_m_annotation();
                break;
            case 'M-Rectangle': //测量-矩形
                doAction({ action1: 'Measure', action2: 'rectangle' }, document.getElementById('footer2_rectangle'));
                break;
            case 'Scroll': //滚动
                var pageId = parseInt(content[1]);
                isEmitScrollImageAction = false;
                specifyImageIndex(pageId);
                break;
            default:
        }
    });
}

function emitMessage(content) {
    if (!meetingEnabled) return false;
    var msg = {
        "my_room": my_room,
        "my_name": curSessionId,
        "my_content": content
    };
    socket.emit('send_message', JSON.stringify(msg));
}

function joinMeeting(obj) {
    if (!meetingEnabled) {
        if (window.confirm('开启会议模式后，你将不能对影像进行任何操作，除非退出会议，请你再次确认')) {
            enableMeeting();
            disableAllTools();
            switchSeries(study.series[0].series_number, 1);
            $(obj).html('<span style="color:red;">退出会议</span>');
            //禁止序列切换
            hideSeriesView();
            $('#footer-series-tool').removeClass('selected');
        }
    } else {
        if (window.confirm('关闭会议模式后，你将不再接收到会议直播，请你再次确认')) {
            meetingEnabled = false;
            switchSeries(study.series[0].series_number, 1);
            $(obj).html('加入会议');
        }
    }
};
