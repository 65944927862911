import Hammer from "../js/hammer.min"
var cornerstoneTools;
"undefined" === typeof cornerstone && (cornerstone = {});
"undefined" === typeof cornerstoneTools && (cornerstoneTools = { referenceLines: {}, orientation: {} });
(function(c, g, d) {
    function a(a) {
        if ("mousewheel" !== a.originalEvent.type || 0 !== a.originalEvent.wheelDeltaY)
            if ("DOMMouseScroll" !== a.originalEvent.type || 1 !== a.originalEvent.axis) {
                var d = a.currentTarget,
                    f = g.pageToPixel(d, a.pageX || a.originalEvent.pageX, a.pageY || a.originalEvent.pageY);
                a = window.event || a;
                var h = Math.max(-1, Math.min(1, a.wheelDelta || -a.detail || -a.originalEvent.detail));
                a = {
                    element: d,
                    viewport: g.getViewport(d),
                    image: g.getEnabledElement(d).image,
                    direction: h,
                    pageX: a.pageX || a.originalEvent.pageX,
                    pageY: a.pageY || a.originalEvent.pageY,
                    imageX: f.x,
                    imageY: f.y
                };
                c(d).trigger("CornerstoneToolsMouseWheel", a)
            }
    }

    d.mouseWheelInput = {
        enable: function(b) {
            c(b).on("mousewheel DOMMouseScroll", a)
        },
        disable: function(b) {
            c(b).unbind("mousewheel DOMMouseScroll", a)
        }
    }
})($, cornerstone, cornerstoneTools);
(function(c, g, d, a) {
    function b() {
        l = !1
    }

    function e(a) {
        c(a.element).trigger("CornerstoneToolsMouseDownActivate", a)
    }

    function f(f) {
        var b = f.currentTarget,
            h = {
                page: d.point.pageToPoint(f),
                image: g.pageToPixel(b, f.pageX, f.pageY),
                client: { x: f.clientX, y: f.clientY }
            };
        h.canvas = g.pixelToCanvas(b, h.image);
        var m = a.copyPoints(h);
        f = {
            event: f,
            which: f.which,
            viewport: g.getViewport(b),
            image: g.getEnabledElement(b).image,
            element: b,
            startPoints: h,
            lastPoints: m,
            currentPoints: h,
            deltaPoints: { x: 0, y: 0 },
            type: "CornerstoneToolsMouseDoubleClick"
        };
        b = c.Event("CornerstoneToolsMouseDoubleClick", f);
        c(f.element).trigger(b, f)
    }

    function h(f) {
        function h(f) {
            var b = {
                page: d.point.pageToPoint(f),
                image: g.pageToPixel(k, f.pageX, f.pageY),
                client: { x: f.clientX, y: f.clientY }
            };
            b.canvas = g.pixelToCanvas(k, b.image);
            var m = {
                    page: d.point.subtract(b.page, r.page),
                    image: d.point.subtract(b.image, r.image),
                    client: d.point.subtract(b.client, r.client),
                    canvas: d.point.subtract(b.canvas, r.canvas)
                },
                m = {
                    which: y,
                    viewport: g.getViewport(k),
                    image: g.getEnabledElement(k).image,
                    element: k,
                    startPoints: t,
                    lastPoints: r,
                    currentPoints: b,
                    deltaPoints: m,
                    type: "CornerstoneToolsMouseDrag"
                };
            c(m.element).trigger("CornerstoneToolsMouseDrag", m);
            r = a.copyPoints(b);
            return a.pauseEvent(f)
        }

        function p(f) {
            clearTimeout(n);
            var a = "CornerstoneToolsMouseUp";
            l && (a = "CornerstoneToolsMouseClick");
            var b = {
                page: d.point.pageToPoint(f),
                image: g.pageToPixel(k, f.pageX, f.pageY),
                client: { x: f.clientX, y: f.clientY }
            };
            b.canvas = g.pixelToCanvas(k, b.image);
            var e = {
                page: d.point.subtract(b.page, r.page),
                image: d.point.subtract(b.image, r.image),
                client: d.point.subtract(b.client, r.client),
                canvas: d.point.subtract(b.canvas, r.canvas)
            };
            f = {
                event: f,
                which: y,
                viewport: g.getViewport(k),
                image: g.getEnabledElement(k).image,
                element: k,
                startPoints: t,
                lastPoints: r,
                currentPoints: b,
                deltaPoints: e,
                type: a
            };
            a = c.Event(a, f);
            c(f.element).trigger(a, f);
            c(document).off("mousemove", h);
            c(document).off("mouseup", p);
            c(f.element).on("mousemove", m);
            l = !0
        }

        n = setTimeout(b, 200);
        var k = f.currentTarget;
        c(k).off("mousemove", m);
        var t = {
            page: d.point.pageToPoint(f),
            image: g.pageToPixel(k, f.pageX, f.pageY),
            client: { x: f.clientX, y: f.clientY }
        };
        t.canvas = g.pixelToCanvas(k, t.image);
        var r = a.copyPoints(t),
            x = {
                event: f,
                which: f.which,
                viewport: g.getViewport(k),
                image: g.getEnabledElement(k).image,
                element: k,
                startPoints: t,
                lastPoints: r,
                currentPoints: t,
                deltaPoints: { x: 0, y: 0 },
                type: "CornerstoneToolsMouseDown"
            },
            v = c.Event("CornerstoneToolsMouseDown", x);
        c(x.element).trigger(v, x);
        if (!1 === v.isImmediatePropagationStopped() && !0 === e(x)) return a.pauseEvent(f);
        var y = f.which;
        c(document).on("mousemove", h);
        c(document).on("mouseup", p);
        return a.pauseEvent(f)
    }

    function m(f) {
        var b = f.currentTarget,
            h = {
                page: d.point.pageToPoint(f),
                image: g.pageToPixel(b, f.pageX, f.pageY),
                client: { x: f.clientX, y: f.clientY }
            };
        h.canvas = g.pixelToCanvas(b, h.image);
        var m = a.copyPoints(h),
            e = f.which;
        f = {
            page: d.point.pageToPoint(f),
            image: g.pageToPixel(b, f.pageX, f.pageY),
            client: { x: f.clientX, y: f.clientY }
        };
        f.canvas = g.pixelToCanvas(b, f.image);
        var l = {
                page: d.point.subtract(f.page, m.page),
                image: d.point.subtract(f.image, m.image),
                client: d.point.subtract(f.client, m.client),
                canvas: d.point.subtract(f.canvas, m.canvas)
            },
            h = {
                which: e,
                viewport: g.getViewport(b),
                image: g.getEnabledElement(b).image,
                element: b,
                startPoints: h,
                lastPoints: m,
                currentPoints: f,
                deltaPoints: l,
                type: "CornerstoneToolsMouseMove"
            };
        c(b).trigger("CornerstoneToolsMouseMove", h);
        a.copyPoints(f)
    }

    function k(a) {
        c(a).off("mousedown", h);
        c(a).off("mousemove", m);
        c(a).off("dblclick", f)
    }

    var l, n;
    a.mouseInput = {
        enable: function(a) {
            k(a);
            c(a).on("mousedown", h);
            c(a).on("mousemove", m);
            c(a).on("dblclick", f)
        },
        disable: k
    }
})($, cornerstone, cornerstoneMath, cornerstoneTools);
(function(c, g, d, a) {
    function b(b) {
        var e = b.target.parentNode,
            u;
        b.preventDefault();
        try {
            "hd" == viewMode ? (c("#viewPaneTable .imageRangeWrapper").hide(), c(e).parent().find(".imageRangeWrapper").show()) : showSlider()
        } catch (t) {}
        switch (b.type) {
            case "tap":
                k = {
                    page: d.point.pageToPoint(b.pointers[0]),
                    image: g.pageToPixel(e, b.pointers[0].pageX, b.pointers[0].pageY),
                    client: { x: b.pointers[0].clientX, y: b.pointers[0].clientY }
                };
                k.canvas = g.pixelToCanvas(e, k.image);
                u = "CornerstoneToolsTap";
                q = {
                    event: b,
                    viewport: g.getViewport(e),
                    image: g.getEnabledElement(e).image,
                    element: e,
                    currentPoints: k,
                    type: u
                };
                u = c.Event(u, q);
                c(e).trigger(u, q);
                break;
            case "doubletap":
                k = {
                    page: d.point.pageToPoint(b.pointers[0]),
                    image: g.pageToPixel(e, b.pointers[0].pageX, b.pointers[0].pageY),
                    client: { x: b.pointers[0].clientX, y: b.pointers[0].clientY }
                };
                k.canvas = g.pixelToCanvas(e, k.image);
                u = "CornerstoneToolsDoubleTap";
                q = {
                    event: b,
                    viewport: g.getViewport(e),
                    image: g.getEnabledElement(e).image,
                    element: e,
                    currentPoints: k,
                    type: u
                };
                u = c.Event(u, q);
                c(e).trigger(u, q);
                break;
            case "press":
                k = {
                    page: d.point.pageToPoint(b.pointers[0]),
                    image: g.pageToPixel(e, b.pointers[0].pageX, b.pointers[0].pageY),
                    client: { x: b.pointers[0].clientX, y: b.pointers[0].clientY }
                };
                k.canvas = g.pixelToCanvas(e, k.image);
                u = "CornerstoneToolsTouchPress";
                q = {
                    event: b,
                    viewport: g.getViewport(e),
                    image: g.getEnabledElement(e).image,
                    element: e,
                    currentPoints: k,
                    type: u
                };
                u = c.Event(u, q);
                c(e).trigger(u, q);
                break;
            case "pinch":
                var r = f - b.scale;
                f = b.scale;
                u = "CornerstoneToolsTouchPinch";
                q = {
                    event: b,
                    viewport: g.getViewport(e),
                    image: g.getEnabledElement(e).image,
                    element: e,
                    direction: 0 > r ? 1 : -1,
                    type: u
                };
                u = c.Event(u, q);
                c(e).trigger(u, q);
                break;
            case "panstart":
                m = {
                    page: d.point.pageToPoint(b.pointers[0]),
                    image: g.pageToPixel(e, b.pointers[0].pageX, b.pointers[0].pageY),
                    client: { x: b.pointers[0].clientX, y: b.pointers[0].clientY }
                };
                m.canvas = g.pixelToCanvas(e, m.image);
                u = 1 === b.pointers.length ? "CornerstoneToolsDragStart" : "CornerstoneToolsMultiTouchDragStart";
                q = {
                    event: b.srcEvent,
                    viewport: g.getViewport(e),
                    image: g.getEnabledElement(e).image,
                    element: e,
                    startPoints: m,
                    lastPoints: l,
                    currentPoints: m,
                    deltaPoints: { x: 0, y: 0 },
                    type: u
                };
                u = c.Event(u, q);
                c(q.element).trigger(u, q);
                l = a.copyPoints(m);
                !1 === u.isImmediatePropagationStopped() && (b = q, c(b.element).trigger("CornerstoneToolsDragStartActive", b));
                break;
            case "panmove":
                k = {
                    page: d.point.pageToPoint(b.pointers[0]),
                    image: g.pageToPixel(e, b.pointers[0].pageX, b.pointers[0].pageY),
                    client: { x: b.pointers[0].clientX, y: b.pointers[0].clientY }
                };
                k.canvas = g.pixelToCanvas(e, k.image);
                n = {
                    page: d.point.subtract(k.page, l.page),
                    image: d.point.subtract(k.image, l.image),
                    client: d.point.subtract(k.client, l.client),
                    canvas: d.point.subtract(k.canvas, l.canvas)
                };
                u = "CornerstoneToolsTouchDrag";
                1 < b.pointers.length && (u = "CornerstoneToolsMultiTouchDrag");
                q = {
                    viewport: g.getViewport(e),
                    image: g.getEnabledElement(e).image,
                    element: e,
                    startPoints: m,
                    lastPoints: l,
                    currentPoints: k,
                    deltaPoints: n,
                    type: u
                };
                u = c.Event(u, q);
                c(e).trigger(u, q);
                l = a.copyPoints(k);
                break;
            case "panend":
                if (!l) break;
                k = {
                    page: d.point.pageToPoint(b.pointers[0]),
                    image: g.pageToPixel(e, b.pointers[0].pageX, b.pointers[0].pageY),
                    client: { x: b.pointers[0].clientX, y: b.pointers[0].clientY }
                };
                k.canvas = g.pixelToCanvas(e, k.image);
                n = {
                    page: d.point.subtract(k.page, l.page),
                    image: d.point.subtract(k.image, l.image),
                    client: d.point.subtract(k.client, l.client),
                    canvas: d.point.subtract(k.canvas, l.canvas)
                };
                u = "CornerstoneToolsDragEnd";
                1 < b.pointers.length && (u = "CornerstoneToolsMultiTouchDragEnd");
                q = {
                    event: b.srcEvent,
                    viewport: g.getViewport(e),
                    image: g.getEnabledElement(e).image,
                    element: e,
                    startPoints: m,
                    lastPoints: l,
                    currentPoints: k,
                    deltaPoints: n,
                    type: u
                };
                u = c.Event(u, q);
                c(e).trigger(u, q);
                return a.pauseEvent(b);
            case "rotate":
                r = b.rotation - h, h = b.rotation, u = "CornerstoneToolsTouchRotate", q = {
                    event: b.srcEvent,
                    viewport: g.getViewport(e),
                    image: g.getEnabledElement(e).image,
                    element: e,
                    rotation: r,
                    type: u
                }, u = c.Event(u, q), c(e).trigger(u, q)
        }
        return !1
    }

    function e(f) {
        (f = c(f).data("hammer")) && f.off("press tap doubletap panstart panmove panend pinch rotate", b)
    }

    var f = 1,
        h = 0,
        m, k, l, n, q;
    a.touchInput = {
        enable: function(f) {
            e(f);
            var h = new Hammer(f, { inputClass: Hammer.SUPPORT_POINTER_EVENTS ? Hammer.PointerEventInput : Hammer.TouchInput });
            h.set({
                transform_always_block: !0,
                transform_min_scale: .01,
                drag_block_horizontal: !0,
                drag_block_vertical: !0,
                drag_min_distance: 0
            });
            var m = new Hammer.Pan({
                    pointers: 0,
                    direction: Hammer.DIRECTION_ALL
                }),
                d = new Hammer.Pinch({ threshold: .25 }),
                l = new Hammer.Rotate({ threshold: .05 }),
                g = new Hammer.Press({ threshold: 10 });
            d.recognizeWith(m);
            d.recognizeWith(l);
            h.add([g, m, d, l]);
            h.on("press tap doubletap panstart panmove panend pinch rotate", b);
            c(f).data("hammer", h);
            a.preventGhostClick(f)
        },
        disable: e
    }
})($, cornerstone, cornerstoneMath, cornerstoneTools);
(function(c, g, d) {
    d.simpleMouseButtonTool = function(a) {
        var b = {};
        return {
            activate: function(b, f, h) {
                c(b).off("CornerstoneToolsMouseDownActivate", a);
                f = { mouseButtonMask: f, options: h };
                c(b).on("CornerstoneToolsMouseDownActivate", f, a)
            },
            disable: function(b) {
                c(b).off("CornerstoneToolsMouseDownActivate", a)
            },
            enable: function(b) {
                c(b).off("CornerstoneToolsMouseDownActivate", a)
            },
            deactivate: function(b) {
                c(b).off("CornerstoneToolsMouseDownActivate", a)
            },
            getConfiguration: function() {
                return b
            },
            setConfiguration: function(a) {
                b = a
            }
        }
    }
})($, cornerstone, cornerstoneTools);
(function(c, g, d, a) {
    a.mouseButtonTool = function(b) {
        function e(e) {
            var l = e.element,
                k = b.createNewMeasurement(e);
            if (k) {
                var p = { mouseButtonMask: e.which };
                a.addToolState(e.element, b.toolType, k);
                c(l).off("CornerstoneToolsMouseMove", b.mouseMoveCallback || h);
                c(l).off("CornerstoneToolsMouseDown", b.mouseDownCallback || d);
                c(l).off("CornerstoneToolsMouseDownActivate", b.mouseDownActivateCallback || f);
                b.mouseDoubleClickCallback && c(l).off("CornerstoneToolsMouseDoubleClick", b.mouseDoubleClickCallback);
                g.updateImage(l);
                (1 === Object.keys(k.handles).length ? a.moveHandle : a.moveNewHandle)(e, k.handles.end, function() {
                    k.active = !1;
                    k.invalidated = !0;
                    a.anyHandlesOutsideImage(e, k.handles) && a.removeToolState(l, b.toolType, k);
                    c(l).on("CornerstoneToolsMouseMove", p, b.mouseMoveCallback || h);
                    c(l).on("CornerstoneToolsMouseDown", p, b.mouseDownCallback || d);
                    c(l).on("CornerstoneToolsMouseDownActivate", p, b.mouseDownActivateCallback || f);
                    if (b.mouseDoubleClickCallback) c(l).on("CornerstoneToolsMouseDoubleClick", p, b.mouseDoubleClickCallback);
                    g.updateImage(l)
                })
            }
        }

        function f(f, c) {
            if (a.isMouseButtonEnabled(c.which, f.data.mouseButtonMask)) return b.addNewMeasurement ? b.addNewMeasurement(c) : e(c), !1
        }

        function h(f, c) {
            a.toolCoordinates.setCoords(c);
            if (0 === c.which) {
                var e = a.getToolState(c.element, b.toolType);
                if (void 0 !== e) {
                    for (var h = !1, d = 0; d < e.data.length; d++) {
                        var m = c.currentPoints.canvas,
                            l = e.data[d];
                        !0 === a.handleActivator(c.element, l.handles, m) && (h = !0);
                        if (b.pointNearTool(c.element, l, m) && !l.active || !b.pointNearTool(c.element, l, m) && l.active) l.active = !l.active, h = !0
                    }!0 === h && g.updateImage(c.element)
                }
            }
        }

        function d(f, e) {
            function m() {
                l.active = !1;
                l.invalidated = !0;
                a.anyHandlesOutsideImage(e, l.handles) && a.removeToolState(k, b.toolType, l);
                g.updateImage(k);
                c(k).on("CornerstoneToolsMouseMove", e, b.mouseMoveCallback || h)
            }

            var l, k = e.element;
            if (a.isMouseButtonEnabled(e.which, f.data.mouseButtonMask)) {
                var t = e.startPoints.canvas,
                    r = a.getToolState(f.currentTarget, b.toolType),
                    x;
                if (r)
                    for (x = 0; x < r.data.length; x++) {
                        l = r.data[x];
                        var v = a.getHandleNearImagePoint(k, l, t, 25);
                        if (v) return c(k).off("CornerstoneToolsMouseMove", b.mouseMoveCallback || h), l.active = !0, a.moveHandle(e, v, m), f.stopImmediatePropagation(), !1
                    }
                if (r && b.pointNearTool)
                    for (v = {
                            deleteIfHandleOutsideImage: !0,
                            preventHandleOutsideImage: !1
                        }, x = 0; x < r.data.length; x++)
                        if (l = r.data[x], b.pointNearTool(k, l, t)) return c(k).off("CornerstoneToolsMouseMove", b.mouseMoveCallback || h), a.moveAllHandles(f, l, r, v, m), f.stopImmediatePropagation(), !1
            }
        }

        var k = {},
            l = {
                enable: function(a) {
                    c(a).off("CornerstoneImageRendered", b.onImageRendered);
                    c(a).off("CornerstoneToolsMouseMove", b.mouseMoveCallback || h);
                    c(a).off("CornerstoneToolsMouseDown", b.mouseDownCallback || d);
                    c(a).off("CornerstoneToolsMouseDownActivate", b.mouseDownActivateCallback || f);
                    b.mouseDoubleClickCallback && c(a).off("CornerstoneToolsMouseDoubleClick", b.mouseDoubleClickCallback);
                    c(a).on("CornerstoneImageRendered", b.onImageRendered);
                    g.updateImage(a)
                },
                disable: function(a) {
                    c(a).off("CornerstoneImageRendered", b.onImageRendered);
                    c(a).off("CornerstoneToolsMouseMove", b.mouseMoveCallback || h);
                    c(a).off("CornerstoneToolsMouseDown", b.mouseDownCallback || d);
                    c(a).off("CornerstoneToolsMouseDownActivate", b.mouseDownActivateCallback || f);
                    b.mouseDoubleClickCallback && c(a).off("CornerstoneToolsMouseDoubleClick", b.mouseDoubleClickCallback);
                    g.updateImage(a)
                },
                activate: function(a, e) {
                    var l = { mouseButtonMask: e };
                    c(a).off("CornerstoneImageRendered", b.onImageRendered);
                    c(a).off("CornerstoneToolsMouseMove", b.mouseMoveCallback || h);
                    c(a).off("CornerstoneToolsMouseDown", b.mouseDownCallback || d);
                    c(a).off("CornerstoneToolsMouseDownActivate", b.mouseDownActivateCallback || f);
                    c(a).on("CornerstoneImageRendered", b.onImageRendered);
                    c(a).on("CornerstoneToolsMouseMove", l, b.mouseMoveCallback || h);
                    c(a).on("CornerstoneToolsMouseDown", l, b.mouseDownCallback || d);
                    c(a).on("CornerstoneToolsMouseDownActivate", l, b.mouseDownActivateCallback || f);
                    b.mouseDoubleClickCallback && (c(a).off("CornerstoneToolsMouseDoubleClick", b.mouseDoubleClickCallback), c(a).on("CornerstoneToolsMouseDoubleClick", l, b.mouseDoubleClickCallback));
                    g.updateImage(a)
                },
                deactivate: function(a, e) {
                    var l = { mouseButtonMask: e };
                    c(a).off("CornerstoneImageRendered", b.onImageRendered);
                    c(a).off("CornerstoneToolsMouseMove", b.mouseMoveCallback || h);
                    c(a).off("CornerstoneToolsMouseDown", b.mouseDownCallback || d);
                    c(a).off("CornerstoneToolsMouseDownActivate", b.mouseDownActivateCallback || f);
                    c(a).on("CornerstoneImageRendered", b.onImageRendered);
                    c(a).on("CornerstoneToolsMouseMove", l, b.mouseMoveCallback || h);
                    c(a).on("CornerstoneToolsMouseDown", l, b.mouseDownCallback || d);
                    b.mouseDoubleClickCallback && (c(a).off("CornerstoneToolsMouseDoubleClick", b.mouseDoubleClickCallback), c(a).on("CornerstoneToolsMouseDoubleClick", l, b.mouseDoubleClickCallback));
                    g.updateImage(a)
                },
                getConfiguration: function() {
                    return k
                },
                setConfiguration: function(a) {
                    k = a
                },
                mouseDownCallback: d,
                mouseMoveCallback: h,
                mouseDownActivateCallback: f
            };
        b.pointNearTool && (l.pointNearTool = b.pointNearTool);
        b.mouseDoubleClickCallback && (l.mouseDoubleClickCallback = b.mouseDoubleClickCallback);
        b.addNewMeasurement && (l.addNewMeasurement = b.addNewMeasurement);
        return l
    }
})($, cornerstone, cornerstoneMath, cornerstoneTools);
(function(c, g, d, a) {
    a.mouseButtonRectangleTool = function(b, e) {
        function f(f) {
            var h = b.createNewMeasurement(f);
            h && (a.addToolState(f.element, b.toolType, h), c(f.element).off("CornerstoneToolsMouseMove", d), a.moveHandle(f, h.handles.end, function() {
                h.active = !1;
                a.anyHandlesOutsideImage(f, h.handles) && a.removeToolState(f.element, b.toolType, h);
                c(f.element).on("CornerstoneToolsMouseMove", d)
            }, e))
        }

        function h(b, c) {
            if (a.isMouseButtonEnabled(c.which, b.data.mouseButtonMask)) return f(c), !1
        }

        function d(f, c) {
            a.toolCoordinates.setCoords(c);
            if (0 === c.which) {
                var e = a.getToolState(c.element, b.toolType);
                if (void 0 !== e) {
                    for (var h = !1, m = c.currentPoints.canvas, k = 0; k < e.data.length; k++) {
                        var t = e.data[k];
                        !0 === a.handleActivator(c.element, t.handles, m) && (h = !0);
                        if (b.pointInsideRect(c.element, t, m) && !t.active || !b.pointInsideRect(c.element, t, m) && t.active) t.active = !t.active, h = !0
                    }!0 === h && g.updateImage(c.element)
                }
            }
        }

        function k(f, h) {
            function k() {
                w.active = !1;
                a.anyHandlesOutsideImage(h, w.handles) && a.removeToolState(h.element, b.toolType, w);
                g.updateImage(h.element);
                c(h.element).on("CornerstoneToolsMouseMove", d)
            }

            var w;
            if (a.isMouseButtonEnabled(h.which, f.data.mouseButtonMask)) {
                var p = h.startPoints.canvas,
                    u = a.getToolState(f.currentTarget, b.toolType),
                    t;
                if (void 0 !== u)
                    for (t = 0; t < u.data.length; t++) {
                        w = u.data[t];
                        var r = a.getHandleNearImagePoint(h.element, w, p);
                        if (void 0 !== r) return c(h.element).off("CornerstoneToolsMouseMove", d), w.active = !0, a.moveHandle(h, r, k, e), f.stopImmediatePropagation(), !1
                    }
                if (void 0 !== u && void 0 !== b.pointInsideRect)
                    for (t = 0; t < u.data.length; t++)
                        if (w = u.data[t], b.pointInsideRect(h.element, w, p)) return c(h.element).off("CornerstoneToolsMouseMove", d), a.moveAllHandles(f, w, u, !1, e), c(h.element).on("CornerstoneToolsMouseMove", d), f.stopImmediatePropagation(), !1
            }
        }

        return {
            enable: function(f) {
                c(f).off("CornerstoneImageRendered", b.onImageRendered);
                c(f).off("CornerstoneToolsMouseMove", d);
                c(f).off("CornerstoneToolsMouseDown", k);
                c(f).off("CornerstoneToolsMouseDownActivate", h);
                c(f).on("CornerstoneImageRendered", b.onImageRendered);
                g.updateImage(f)
            },
            disable: function(f) {
                c(f).off("CornerstoneImageRendered", b.onImageRendered);
                c(f).off("CornerstoneToolsMouseMove", d);
                c(f).off("CornerstoneToolsMouseDown", k);
                c(f).off("CornerstoneToolsMouseDownActivate", h);
                g.updateImage(f)
            },
            activate: function(f, a) {
                var e = { mouseButtonMask: a };
                c(f).off("CornerstoneImageRendered", b.onImageRendered);
                c(f).off("CornerstoneToolsMouseMove", d);
                c(f).off("CornerstoneToolsMouseDown", k);
                c(f).off("CornerstoneToolsMouseDownActivate", h);
                c(f).on("CornerstoneImageRendered", b.onImageRendered);
                c(f).on("CornerstoneToolsMouseMove", e, d);
                c(f).on("CornerstoneToolsMouseDown", e, k);
                c(f).on("CornerstoneToolsMouseDownActivate", e, h);
                g.updateImage(f)
            },
            deactivate: function(f, a) {
                var e = { mouseButtonMask: a };
                c(f).off("CornerstoneImageRendered", b.onImageRendered);
                c(f).off("CornerstoneToolsMouseMove", d);
                c(f).off("CornerstoneToolsMouseDown", k);
                c(f).off("CornerstoneToolsMouseDownActivate", h);
                c(f).on("CornerstoneImageRendered", b.onImageRendered);
                c(f).on("CornerstoneToolsMouseMove", e, d);
                c(f).on("CornerstoneToolsMouseDown", e, k);
                g.updateImage(f)
            }
        }
    }
})($, cornerstone, cornerstoneMath, cornerstoneTools);
(function(c, g, d) {
    d.mouseWheelTool = function(a) {
        return {
            activate: function(b) {
                c(b).off("CornerstoneToolsMouseWheel", a);
                c(b).on("CornerstoneToolsMouseWheel", {}, a)
            },
            disable: function(b) {
                c(b).off("CornerstoneToolsMouseWheel", a)
            },
            enable: function(b) {
                c(b).off("CornerstoneToolsMouseWheel", a)
            },
            deactivate: function(b) {
                c(b).off("CornerstoneToolsMouseWheel", a)
            }
        }
    }
})($, cornerstone, cornerstoneTools);
(function(c, g, d) {
    d.touchDragTool = function(a) {
        return {
            activate: function(b) {
                c(b).off("CornerstoneToolsTouchDrag", a);
                c(b).on("CornerstoneToolsTouchDrag", a)
            },
            disable: function(b) {
                c(b).off("CornerstoneToolsTouchDrag", a)
            },
            enable: function(b) {
                c(b).off("CornerstoneToolsTouchDrag", a)
            },
            deactivate: function(b) {
                c(b).off("CornerstoneToolsTouchDrag", a)
            }
        }
    }
})($, cornerstone, cornerstoneTools);
(function(c, g, d) {
    d.touchPinchTool = function(a) {
        return {
            activate: function(b) {
                c(b).off("CornerstoneToolsTouchPinch", a);
                c(b).on("CornerstoneToolsTouchPinch", {}, a)
            },
            disable: function(b) {
                c(b).off("CornerstoneToolsTouchPinch", a)
            },
            enable: function(b) {
                c(b).off("CornerstoneToolsTouchPinch", a)
            },
            deactivate: function(b) {
                c(b).off("CornerstoneToolsTouchPinch", a)
            }
        }
    }
})($, cornerstone, cornerstoneTools);
(function(c, g, d, a) {
    function b(f) {
        Object.keys(f).forEach(function(a) {
            f[a].active = !1
        })
    }

    function e(f) {
        if (f)
            for (var a = 0; a < f.data.length; a++) {
                var c = f.data[a];
                c.active = !1;
                c.handles && b(c.handles)
            }
    }

    a.touchTool = function(f) {
        function b(e) {
            var h = e.element,
                p = f.createNewMeasurement(e);
            p && (a.addToolState(h, f.toolType, p), 1 === Object.keys(p.handles).length && "CornerstoneToolsTap" === e.type ? (p.active = !1, p.handles.end.active = !1, p.handles.end.highlight = !1, p.invalidated = !0, a.anyHandlesOutsideImage(e, p.handles) && a.removeToolState(h, f.toolType, p), g.updateImage(h)) : (c(h).off("CornerstoneToolsDragStartActive", f.touchDownActivateCallback || d), c(h).off("CornerstoneToolsTouchDrag", f.touchMoveCallback || k), c(h).off("CornerstoneToolsTap", f.tapCallback || l), g.updateImage(h), a.moveNewHandleTouch(e, p.handles.end, function() {
                p.active = !1;
                p.invalidated = !0;
                a.anyHandlesOutsideImage(e, p.handles) && a.removeToolState(h, f.toolType, p);
                c(h).on("CornerstoneToolsDragStartActive", f.touchDownActivateCallback || d);
                c(h).on("CornerstoneToolsTouchDrag", f.touchMoveCallback || k);
                c(h).on("CornerstoneToolsTap", f.tapCallback || l);
                g.updateImage(h)
            })))
        }

        function d(a, c) {
            f.addNewMeasurement ? f.addNewMeasurement(c) : b(c);
            return !1
        }

        function k(b, c) {
            a.toolCoordinates.setCoords(c);
            var e = a.getToolState(c.element, f.toolType);
            if (void 0 !== e) {
                for (var h = !1, d = 0; d < e.data.length; d++) !0 === a.handleActivator(c.element, e.data[d].handles, c.currentPoints.canvas) && (h = !0);
                !0 === h && g.updateImage(c.element);
                return !1
            }
        }

        function l(b, h) {
            function p() {
                e(r);
                a.anyHandlesOutsideImage(h, x.handles) && a.removeToolState(n, f.toolType, x);
                g.updateImage(n);
                c(n).on("CornerstoneToolsTouchDrag", f.touchMoveCallback || k);
                c(n).on("CornerstoneToolsDragStartActive", f.touchDownActivateCallback || d);
                c(n).on("CornerstoneToolsTap", f.tapCallback || l)
            }

            var n = h.element,
                t = h.currentPoints.canvas,
                r = a.getToolState(b.currentTarget, f.toolType),
                x, v;
            e(r);
            if (r)
                for (v = 0; v < r.data.length; v++) {
                    x = r.data[v];
                    var y = a.getHandleNearImagePoint(n, x, t, 75);
                    if (y) return c(n).off("CornerstoneToolsTouchDrag", f.touchMoveCallback || k), c(n).off("CornerstoneToolsDragStartActive", f.touchDownActivateCallback || d), c(n).off("CornerstoneToolsTap", f.tapCallback || l), x.active = !0, y.active = !0, g.updateImage(n), a.touchMoveHandle(b, y, p), b.stopImmediatePropagation(), !1
                }
            if (r && f.pointNearTool)
                for (v = 0; v < r.data.length; v++)
                    if (x = r.data[v], f.pointNearTool(n, x, t)) return c(n).off("CornerstoneToolsTouchDrag", f.touchMoveCallback || k), c(n).off("CornerstoneToolsDragStartActive", f.touchDownActivateCallback || d), c(n).off("CornerstoneToolsTap", f.tapCallback || l), x.active = !0, g.updateImage(n), a.touchMoveAllHandles(b, x, r, !0, p), b.stopImmediatePropagation(), !1;
            f.touchDownActivateCallback ? f.touchDownActivateCallback(b, h) : d(b, h);
            return !1
        }

        var n = {
            enable: function(a) {
                c(a).off("CornerstoneImageRendered", f.onImageRendered);
                c(a).off("CornerstoneToolsTouchDrag", f.touchMoveCallback || k);
                c(a).off("CornerstoneToolsDragStartActive", f.touchDownActivateCallback || d);
                c(a).off("CornerstoneToolsTap", f.tapCallback || l);
                c(a).on("CornerstoneImageRendered", f.onImageRendered);
                f.doubleTapCallback && c(a).off("CornerstoneToolsDoubleTap", f.doubleTapCallback);
                f.pressCallback && c(a).off("CornerstoneToolsTouchPress", f.pressCallback);
                g.updateImage(a)
            },
            disable: function(a) {
                c(a).off("CornerstoneImageRendered", f.onImageRendered);
                c(a).off("CornerstoneToolsTouchDrag", f.touchMoveCallback || k);
                c(a).off("CornerstoneToolsDragStartActive", f.touchDownActivateCallback || d);
                c(a).off("CornerstoneToolsTap", f.tapCallback || l);
                f.doubleTapCallback && c(a).off("CornerstoneToolsDoubleTap", f.doubleTapCallback);
                f.pressCallback && c(a).off("CornerstoneToolsTouchPress", f.pressCallback);
                g.updateImage(a)
            },
            activate: function(a) {
                c(a).off("CornerstoneImageRendered", f.onImageRendered);
                c(a).off("CornerstoneToolsTouchDrag", f.touchMoveCallback || k);
                c(a).off("CornerstoneToolsDragStartActive", f.touchDownActivateCallback || d);
                c(a).off("CornerstoneToolsTap", f.tapCallback || l);
                c(a).on("CornerstoneImageRendered", f.onImageRendered);
                c(a).on("CornerstoneToolsTouchDrag", f.touchMoveCallback || k);
                c(a).on("CornerstoneToolsDragStartActive", f.touchDownActivateCallback || d);
                c(a).on("CornerstoneToolsTap", f.tapCallback || l);
                f.doubleTapCallback && (c(a).off("CornerstoneToolsDoubleTap", f.doubleTapCallback), c(a).on("CornerstoneToolsDoubleTap", f.doubleTapCallback));
                f.pressCallback && (c(a).off("CornerstoneToolsTouchPress", f.pressCallback), c(a).on("CornerstoneToolsTouchPress", f.pressCallback));
                g.updateImage(a)
            },
            deactivate: function(a) {
                c(a).off("CornerstoneImageRendered", f.onImageRendered);
                c(a).off("CornerstoneToolsTouchDrag", f.touchMoveCallback || k);
                c(a).off("CornerstoneToolsDragStartActive", f.touchDownActivateCallback || d);
                c(a).off("CornerstoneToolsTap", f.tapCallback || l);
                c(a).on("CornerstoneImageRendered", f.onImageRendered);
                c(a).on("CornerstoneToolsTouchDrag", f.touchMoveCallback || k);
                f.doubleTapCallback && (c(a).off("CornerstoneToolsDoubleTap", f.doubleTapCallback), c(a).on("CornerstoneToolsDoubleTap", f.doubleTapCallback));
                f.pressCallback && (c(a).off("CornerstoneToolsTouchPress", f.pressCallback), c(a).on("CornerstoneToolsTouchPress", f.pressCallback));
                g.updateImage(a)
            },
            touchMoveCallback: k,
            touchDownActivateCallback: d,
            tapCallback: l
        };
        f.doubleTapCallback && (n.doubleTapCallback = f.doubleTapCallback);
        f.pressCallback && (n.pressCallback = f.pressCallback);
        f.addNewMeasurement && (n.addNewMeasurement = f.addNewMeasurement);
        f.touchStartCallback && (n.touchStartCallback = f.touchStartCallback);
        return n
    }
})($, cornerstone, cornerstoneMath, cornerstoneTools);
(function(c, g, d, a) {
    function b(a) {
        return {
            visible: !0,
            active: !0,
            handles: {
                start: {
                    x: a.currentPoints.image.x - 20,
                    y: a.currentPoints.image.y + 10,
                    highlight: !0,
                    active: !1
                },
                end: { x: a.currentPoints.image.x, y: a.currentPoints.image.y, highlight: !0, active: !0 },
                start2: { x: a.currentPoints.image.x - 20, y: a.currentPoints.image.y + 10, highlight: !0, active: !1 },
                end2: { x: a.currentPoints.image.x, y: a.currentPoints.image.y + 20, highlight: !0, active: !1 }
            }
        }
    }

    function e(a, f, b) {
        var c = {
                start: g.pixelToCanvas(a, f.handles.start),
                end: g.pixelToCanvas(a, f.handles.end)
            },
            e = d.lineSegment.distanceToPoint(c, b);
        if (5 > e) return !0;
        c.start = g.pixelToCanvas(a, f.handles.start2);
        c.end = g.pixelToCanvas(a, f.handles.end2);
        e = d.lineSegment.distanceToPoint(c, b);
        return 5 > e
    }

    function f(f, b) {
        var c = a.getToolState(f.currentTarget, "angle");
        if (void 0 !== c) {
            var e = b.canvasContext.canvas.getContext("2d");
            e.setTransform(1, 0, 0, 1, 0, 0);
            for (var d, q = a.toolStyle.getToolWidth(), w = a.textStyle.getFont(), p = a.angle.getConfiguration(), u = 0; u < c.data.length; u++) {
                e.save();
                p && p.shadow && (e.shadowColor = "#000000", e.shadowOffsetX = 1, e.shadowOffsetY = 1);
                var t = c.data[u];
                d = t.active ? a.toolColors.getActiveColor() : a.toolColors.getToolColor();
                e.beginPath();
                e.strokeStyle = d;
                e.lineWidth = q;
                var r = g.pixelToCanvas(b.element, t.handles.start),
                    x = g.pixelToCanvas(b.element, t.handles.end);
                e.moveTo(r.x, r.y);
                e.lineTo(x.x, x.y);
                r = g.pixelToCanvas(b.element, t.handles.start2);
                x = g.pixelToCanvas(b.element, t.handles.end2);
                e.moveTo(r.x, r.y);
                e.lineTo(x.x, x.y);
                e.stroke();
                a.drawHandles(e, b, t.handles);
                e.fillStyle = d;
                var v = (Math.ceil(t.handles.start.x) - Math.ceil(t.handles.end.x)) * b.image.columnPixelSpacing,
                    y = (Math.ceil(t.handles.start.y) - Math.ceil(t.handles.end.y)) * b.image.rowPixelSpacing,
                    A = (Math.ceil(t.handles.start2.x) - Math.ceil(t.handles.end2.x)) * b.image.columnPixelSpacing,
                    t = (Math.ceil(t.handles.start2.y) - Math.ceil(t.handles.end2.y)) * b.image.rowPixelSpacing,
                    v = Math.acos(Math.abs((v * A + y * t) / (Math.sqrt(v * v + y * y) * Math.sqrt(A * A + t * t)))),
                    v = 180 / Math.PI * v,
                    v = a.roundToDecimal(v, 2).toString() + String.fromCharCode(176),
                    y = (r.x + x.x) / 2,
                    r = (r.y + x.y) / 2;
                e.font = w;
                a.drawTextBox(e, v, y, r, d);
                e.restore()
            }
        }
    }

    a.angle = a.mouseButtonTool({ createNewMeasurement: b, onImageRendered: f, pointNearTool: e, toolType: "angle" });
    a.angleTouch = a.touchTool({ createNewMeasurement: b, onImageRendered: f, pointNearTool: e, toolType: "angle" })
})($, cornerstone, cornerstoneMath, cornerstoneTools);
(function(c, g, d, a) {
    function b(a) {
        return {
            visible: !0,
            active: !0,
            handles: {
                start: { x: a.currentPoints.image.x, y: a.currentPoints.image.y, highlight: !0, active: !1 },
                end: { x: a.currentPoints.image.x, y: a.currentPoints.image.y, highlight: !0, active: !1 }
            }
        }
    }

    function e(a, f, b) {
        a = { start: g.pixelToCanvas(a, f.handles.start), end: g.pixelToCanvas(a, f.handles.end) };
        return 25 > d.lineSegment.distanceToPoint(a, b)
    }

    function f(a, f, b, e, c) {
        var d = Math.atan2(b.y - f.y, b.x - f.x);
        a.beginPath();
        a.moveTo(f.x, f.y);
        a.lineTo(b.x, b.y);
        a.strokeStyle = e;
        a.lineWidth = c;
        a.stroke();
        a.beginPath();
        a.moveTo(b.x, b.y);
        a.lineTo(b.x - 10 * Math.cos(d - Math.PI / 7), b.y - 10 * Math.sin(d - Math.PI / 7));
        a.lineTo(b.x - 10 * Math.cos(d + Math.PI / 7), b.y - 10 * Math.sin(d + Math.PI / 7));
        a.lineTo(b.x, b.y);
        a.lineTo(b.x - 10 * Math.cos(d - Math.PI / 7), b.y - 10 * Math.sin(d - Math.PI / 7));
        a.strokeStyle = e;
        a.lineWidth = c;
        a.stroke();
        a.fillStyle = e;
        a.fill()
    }

    function h(b, e) {
        var c, d, h = a.getToolState(b.currentTarget, "arrowAnnotate");
        if (void 0 !== h) {
            var w = e.canvasContext.canvas.getContext("2d");
            w.setTransform(1, 0, 0, 1, 0, 0);
            for (var p, u = a.toolStyle.getToolWidth(), t = a.textStyle.getFont(), r = a.arrowAnnotate.getConfiguration(), x = 0; x < h.data.length; x++) {
                w.save();
                var v = h.data[x];
                p = v.active ? a.toolColors.getActiveColor() : a.toolColors.getToolColor();
                var y = g.pixelToCanvas(e.element, v.handles.start),
                    A = g.pixelToCanvas(e.element, v.handles.end);
                r.arrowFirst ? f(w, A, y, p, u) : f(w, y, A, p, u);
                r.drawHandles ? a.drawHandles(w, e, v.handles, p) : r.drawHandlesOnHover && v.handles.start.active ? a.drawHandles(w, e, [v.handles.start], p) : r.drawHandlesOnHover && v.handles.end.active && a.drawHandles(w, e, [v.handles.end], p);
                if (v.annotationText && "" !== v.annotationText) {
                    w.font = t;
                    var z = 13;
                    c = A.x - y.x;
                    d = A.y - y.y;
                    r.arrowFirst ? (0 > c && (z = -z - w.measureText(v.annotationText).width), c = c + y.x + z, d = d + y.y + 6.5) : (0 < c && (z = -z - w.measureText(v.annotationText).width), c = -c + A.x + z, d = -d + A.y + 6.5);
                    a.drawTextBox(w, v.annotationText, c, d, p)
                }
                w.restore()
            }
        }
    }

    a.arrowAnnotate = a.mouseButtonTool({
        addNewMeasurement: function(f) {
            function e(b) {
                null !== b ? d.annotationText = b : a.removeToolState(f.element, "arrowAnnotate", d);
                d.active = !1;
                g.updateImage(f.element)
            }

            var d = b(f),
                h = { mouseButtonMask: f.which };
            a.addToolState(f.element, "arrowAnnotate", d);
            c(f.element).off("CornerstoneToolsMouseMove", a.arrowAnnotate.mouseMoveCallback);
            c(f.element).off("CornerstoneToolsMouseDown", a.arrowAnnotate.mouseDownCallback);
            c(f.element).off("CornerstoneToolsMouseDownActivate", a.arrowAnnotate.mouseDownActivateCallback);
            g.updateImage(f.element);
            a.moveNewHandle(f, d.handles.end, function() {
                a.anyHandlesOutsideImage(f, d.handles) && a.removeToolState(f.element, "arrowAnnotate", d);
                var b = a.arrowAnnotate.getConfiguration();
                void 0 === d.annotationText && b.getTextCallback(e);
                c(f.element).on("CornerstoneToolsMouseMove", h, a.arrowAnnotate.mouseMoveCallback);
                c(f.element).on("CornerstoneToolsMouseDown", h, a.arrowAnnotate.mouseDownCallback);
                c(f.element).on("CornerstoneToolsMouseDownActivate", h, a.arrowAnnotate.mouseDownActivateCallback);
                g.updateImage(f.element)
            })
        },
        createNewMeasurement: b,
        onImageRendered: h,
        pointNearTool: e,
        toolType: "arrowAnnotate"
    });
    a.arrowAnnotate.setConfiguration({
        getTextCallback: function(a) {
            a(prompt("Enter your annotation:"))
        },
        drawHandles: !1,
        drawHandlesOnHover: !0,
        arrowFirst: !0
    });
    a.arrowAnnotateTouch = a.touchTool({
        addNewMeasurement: function(f) {
            function e(f) {
                null !== f ? h.annotationText = f : a.removeToolState(d, "arrowAnnotate", h);
                h.active = !1;
                g.updateImage(d)
            }

            var d = f.element,
                h = b(f);
            a.addToolState(d, "arrowAnnotate", h);
            c(d).off("CornerstoneToolsTouchDrag", a.arrowAnnotateTouch.touchMoveCallback);
            c(d).off("CornerstoneToolsDragStartActive", a.arrowAnnotateTouch.touchDownActivateCallback);
            c(d).off("CornerstoneToolsTap", a.arrowAnnotateTouch.tapCallback);
            g.updateImage(d);
            a.moveNewHandleTouch(f, h.handles.end, function() {
                g.updateImage(d);
                a.anyHandlesOutsideImage(f, h.handles) && a.removeToolState(d, "arrowAnnotate", h);
                var b = a.arrowAnnotate.getConfiguration();
                void 0 === h.annotationText && b.getTextCallback(e);
                c(d).on("CornerstoneToolsTouchDrag", a.arrowAnnotateTouch.touchMoveCallback);
                c(d).on("CornerstoneToolsDragStartActive", a.arrowAnnotateTouch.touchDownActivateCallback);
                c(d).on("CornerstoneToolsTap", a.arrowAnnotateTouch.tapCallback)
            })
        },
        createNewMeasurement: b,
        onImageRendered: h,
        pointNearTool: e,
        toolType: "arrowAnnotate"
    })
})($, cornerstone, cornerstoneMath, cornerstoneTools);
(function(c, g, d) {
    function a(a, f) {
        var b = d.getToolState(a.currentTarget, "crosshairs");
        if (b) {
            var e = a.currentTarget,
                h = g.getEnabledElement(e).image.imageId,
                h = d.metaData.get("imagePlane", h),
                k = d.imagePointToPatientPoint(f.currentPoints.image, h),
                b = b.data[0].synchronizationContext.getSourceElements();
            c.each(b, function(a, f) {
                if (f !== e) {
                    var b = Number.MAX_VALUE,
                        h = -1,
                        m = d.getToolState(f, "stack");
                    if (void 0 !== m) {
                        var l = m.data[0];
                        c.each(l.imageIds, function(a, f) {
                            var e = d.metaData.get("imagePlane", f),
                                c = e.imagePositionPatient,
                                g = e.rowCosines.clone(),
                                e = e.columnCosines.clone().clone().cross(g.clone()),
                                c = Math.abs(e.clone().dot(c) - e.clone().dot(k));
                            c < b && (b = c, h = a)
                        });
                        if (h !== l.currentImageIdIndex && -1 !== h && void 0 !== l.imageIds[h]) {
                            var m = d.loadHandlerManager.getStartLoadHandler(),
                                p = d.loadHandlerManager.getEndLoadHandler(),
                                n = d.loadHandlerManager.getErrorLoadingHandler();
                            m && m(f);
                            g.loadAndCacheImage(l.imageIds[h]).then(function(a) {
                                var b = g.getViewport(f);
                                l.currentImageIdIndex = h;
                                g.displayImage(f, a, b);
                                p && p(f)
                            }, function(a) {
                                var b = l.imageIds[h];
                                n && n(f, b, a)
                            })
                        }
                    }
                }
            })
        }
    }

    function b(a, e) {
        c(e.element).off("CornerstoneToolsMouseDrag", f);
        c(e.element).off("CornerstoneToolsMouseUp", b)
    }

    function e(e, h) {
        if (d.isMouseButtonEnabled(h.which, e.data.mouseButtonMask)) return c(h.element).on("CornerstoneToolsMouseDrag", f), c(h.element).on("CornerstoneToolsMouseUp", b), a(e, h), !1
    }

    function f(f, b) {
        a(f, b);
        return !1
    }

    function h(a, f, b) {
        f = { mouseButtonMask: f };
        d.getToolState(a, "crosshairs");
        d.addToolState(a, "crosshairs", { synchronizationContext: b });
        c(a).off("CornerstoneToolsMouseDown", e);
        c(a).on("CornerstoneToolsMouseDown", f, e)
    }

    function m(a) {
        c(a).off("CornerstoneToolsMouseDown", e)
    }

    function k(a, f) {
        c(f.element).off("CornerstoneToolsTouchDrag", n);
        c(f.element).off("CornerstoneToolsDragEnd", k)
    }

    function l(f, b) {
        c(b.element).on("CornerstoneToolsTouchDrag", n);
        c(b.element).on("CornerstoneToolsDragEnd", k);
        a(f, b);
        return !1
    }

    function n(f, b) {
        a(f, b);
        return !1
    }

    function q(a, f) {
        d.getToolState(a, "crosshairs");
        d.addToolState(a, "crosshairs", { synchronizationContext: f });
        c(a).off("CornerstoneToolsDragStart", l);
        c(a).on("CornerstoneToolsDragStart", l)
    }

    function w(a) {
        c(a).off("CornerstoneToolsDragStart", l)
    }

    d.crosshairs = { activate: h, deactivate: m, enable: h, disable: m };
    d.crosshairsTouch = { activate: q, deactivate: w, enable: q, disable: w }
})($, cornerstone, cornerstoneTools);
(function(c, g, d) {
    d.displayTool = function(a) {
        var b = {};
        return {
            disable: function(b) {
                c(b).off("CornerstoneImageRendered", a)
            },
            enable: function(b) {
                c(b).off("CornerstoneImageRendered", a);
                c(b).on("CornerstoneImageRendered", a);
                g.updateImage(b)
            },
            getConfiguration: function() {
                return b
            },
            setConfiguration: function(a) {
                b = a
            }
        }
    }
})($, cornerstone, cornerstoneTools);
(function(c, g, d) {
    function a(a) {
        var b = g.getEnabledElement(a.element);
        g.updateImage(a.element);
        b = b.canvas.getContext("2d");
        b.setTransform(1, 0, 0, 1, 0, 0);
        var e = d.toolColors.getActiveColor(),
            c = d.textStyle.getFont(),
            l = d.textStyle.getFontSize();
        b.save();
        var n = Math.round(a.currentPoints.image.x),
            q = Math.round(a.currentPoints.image.y),
            w;
        if (!(0 > n || 0 > q || n >= a.image.columns || q >= a.image.rows)) {
            if (a.image.color) w = d.getRGBPixels(a.element, n, q, 1, 1), n = "" + n + ", " + q, q = "R: " + w[0] + " G: " + w[1] + " B: " + w[2] + " A: " + w[3];
            else {
                w = g.getStoredPixels(a.element, n, q, 1, 1);
                var p = w[0],
                    u = p * a.image.slope + a.image.intercept;
                w = d.calculateSUV(a.image, p);
                n = "" + n + ", " + q;
                q = "SP: " + p + " MO: " + parseFloat(u.toFixed(3));
                w && (q += " SUV: " + parseFloat(w.toFixed(3)))
            }
            a = g.pixelToCanvas(a.element, { x: a.currentPoints.image.x + 3, y: a.currentPoints.image.y - 3 });
            b.font = c;
            b.fillStyle = e;
            d.drawTextBox(b, q, a.x, a.y + l + 5, e);
            d.drawTextBox(b, n, a.x, a.y, e);
            b.restore()
        }
    }

    function b(a, d) {
        c(d.element).off("CornerstoneToolsMouseDrag", e);
        c(d.element).off("CornerstoneToolsMouseUp", b);
        g.updateImage(d.element)
    }

    function e(a, b) {
        d.dragProbe.strategy(b);
        return !1
    }

    d.dragProbe = d.simpleMouseButtonTool(function(a, h) {
        if (d.isMouseButtonEnabled(h.which, a.data.mouseButtonMask)) return c(h.element).on("CornerstoneToolsMouseDrag", e), c(h.element).on("CornerstoneToolsMouseUp", b), d.dragProbe.strategy(h), !1
    });
    d.dragProbe.strategies = {
        default: a,
        minimal: function(a) {
            var b = g.getEnabledElement(a.element);
            g.updateImage(a.element);
            b = b.canvas.getContext("2d");
            b.setTransform(1, 0, 0, 1, 0, 0);
            var e = d.toolColors.getActiveColor(),
                c = d.textStyle.getFont();
            b.save();
            var l = Math.round(a.currentPoints.image.x),
                n = Math.round(a.currentPoints.image.y);
            0 > l || 0 > n || l >= a.image.columns || n >= a.image.rows || (a.image.color ? (l = g.getStoredPixels(a.element, l, n, 3, 1), l = "R: " + l[0] + " G: " + l[1] + " B: " + l[2]) : (l = g.getStoredPixels(a.element, l, n, 1, 1), l = parseFloat((l[0] * a.image.slope + a.image.intercept).toFixed(3))), a = g.pixelToCanvas(a.element, {
                x: a.currentPoints.image.x + 4,
                y: a.currentPoints.image.y - 4
            }), b.font = c, b.fillStyle = e, d.drawTextBox(b, l, a.x, a.y, e), b.restore())
        }
    };
    d.dragProbe.strategy = a;
    d.dragProbeTouch = d.touchDragTool(e)
})($, cornerstone, cornerstoneTools);
(function(c, g, d, a) {
    function b(a) {
        return {
            visible: !0,
            active: !0,
            invalidated: !0,
            handles: {
                start: { x: a.currentPoints.image.x, y: a.currentPoints.image.y, highlight: !0, active: !1 },
                end: { x: a.currentPoints.image.x, y: a.currentPoints.image.y, highlight: !0, active: !0 }
            }
        }
    }

    function e(a, f) {
        var b = a.width / 2,
            e = a.height / 2;
        if (0 >= b || 0 >= e) return !1;
        var c = f.x - (a.left + b),
            d = f.y - (a.top + e);
        return 1 >= c * c / (b * b) + d * d / (e * e)
    }

    function f(a, f, b, c) {
        var d = g.pixelToCanvas(a, f.handles.start);
        f = g.pixelToCanvas(a, f.handles.end);
        a = {
            left: Math.min(d.x, f.x) + c / 2,
            top: Math.min(d.y, f.y) + c / 2,
            width: Math.abs(d.x - f.x) - c,
            height: Math.abs(d.y - f.y) - c
        };
        c = {
            left: Math.min(d.x, f.x) - c / 2,
            top: Math.min(d.y, f.y) - c / 2,
            width: Math.abs(d.x - f.x) + c,
            height: Math.abs(d.y - f.y) + c
        };
        d = e(a, b);
        return e(c, b) && !d ? !0 : !1
    }

    function h(f, b) {
        var c = a.getToolState(f.currentTarget, "ellipticalRoi");
        if (void 0 !== c) {
            var d = b.canvasContext.canvas.getContext("2d");
            d.setTransform(1, 0, 0, 1, 0, 0);
            for (var h, w = a.toolStyle.getToolWidth(), p = a.textStyle.getFont(), u = a.textStyle.getFontSize(), t = 0; t < c.data.length; t++) {
                d.save();
                var r = c.data[t];
                h = r.active ? a.toolColors.getActiveColor() : a.toolColors.getToolColor();
                var x = g.pixelToCanvas(b.element, r.handles.start),
                    v = g.pixelToCanvas(b.element, r.handles.end),
                    y = Math.abs(x.x - v.x),
                    A = Math.abs(x.y - v.y),
                    z = Math.min(x.x, v.x),
                    B = Math.min(x.y, v.y),
                    C = (x.x + v.x) / 2,
                    x = (x.y + v.y) / 2;
                d.beginPath();
                d.strokeStyle = h;
                d.lineWidth = w;
                a.drawEllipse(d, z, B, y, A);
                d.closePath();
                a.drawHandles(d, b, r.handles, h);
                d.font = p;
                var I;
                if (r.invalidated) {
                    var z = Math.abs(r.handles.start.x - r.handles.end.x),
                        B = Math.abs(r.handles.start.y - r.handles.end.y),
                        E = Math.min(r.handles.start.x, r.handles.end.x),
                        F = Math.min(r.handles.start.y, r.handles.end.y);
                    for (var v = g.getPixels(b.element, E, F, z, B), D = {
                            left: E,
                            top: F,
                            width: z,
                            height: B
                        }, H = 0, G = E = F = 0, J = D.top; J < D.top + D.height; J++)
                        for (var K = D.left; K < D.left + D.width; K++) !0 === e(D, {
                            x: K,
                            y: J
                        }) && (H += v[G], F += v[G] * v[G], E++), G++;
                    0 === E ? v = {
                        count: E,
                        mean: 0,
                        variance: 0,
                        stdDev: 0
                    } : (v = H / E, F = F / E - v * v, v = { count: E, mean: v, variance: F, stdDev: Math.sqrt(F) });
                    z = z * b.image.columnPixelSpacing / 2 * Math.PI * (B * b.image.rowPixelSpacing / 2);
                    r.invalidated = !1;
                    isNaN(z) || (r.area = z);
                    isNaN(v.mean) || isNaN(v.stdDev) || (r.meanStdDev = v)
                } else v = r.meanStdDev, z = r.area;
                void 0 !== z ? (I = "\u9762\u79ef: " + z.toFixed(2) + " mm" + String.fromCharCode(178), r = d.measureText(I)) : (r = "\u6807\u51c6\u5dee: " + v.stdDev.toFixed(2), r = d.measureText(r));
                y = C < b.image.columns / 2 ? C + y / 2 : C - y / 2 - r.width;
                A = x < b.image.rows / 2 ? x + A / 2 : x - A / 2;
                d.fillStyle = h;
                v && (a.drawTextBox(d, "\u5e73\u5747\u503c: " + v.mean.toFixed(2), y, A - u - 5, h), a.drawTextBox(d, "\u6807\u51c6\u5dee: " + v.stdDev.toFixed(2), y, A, h));
                void 0 === z || isNaN(z) || a.drawTextBox(d, I, y, A + u + 5, h);
                d.restore()
            }
        }
    }

    a.ellipticalRoi = a.mouseButtonTool({
        createNewMeasurement: b,
        onImageRendered: h,
        pointNearTool: function(a, b, e) {
            return f(a, b, e, 15)
        },
        toolType: "ellipticalRoi"
    });
    a.ellipticalRoiTouch = a.touchTool({
        createNewMeasurement: b,
        onImageRendered: h,
        pointNearTool: function(a, b, e) {
            return f(a, b, e, 25)
        },
        toolType: "ellipticalRoi"
    })
})($, cornerstone, cornerstoneMath, cornerstoneTools);
(function(c, g, d, a) {
    function b(f) {
        var b = a.getToolState(f.element, "freehand");
        if (void 0 !== b) {
            var e = a.freehand.getConfiguration(),
                b = b.data[e.currentTool],
                d = {
                    x: f.currentPoints.image.x,
                    y: f.currentPoints.image.y,
                    highlight: !0,
                    active: !0,
                    lines: []
                };
            b.handles.length && b.handles[e.currentHandle - 1].lines.push(f.currentPoints.image);
            b.handles.push(d);
            e.currentHandle += 1;
            e.freehand = !1;
            g.updateImage(f.element)
        }
    }

    function e(f, b) {
        var e = a.getToolState(f.element, "freehand");
        if (void 0 !== e && (e = e.data[b], void 0 !== e.handles))
            for (var c = f.currentPoints.canvas, h = 0; h < e.handles.length; h++) {
                var k = g.pixelToCanvas(f.element, e.handles[h]);
                if (5 > d.point.distance(k, c)) return h
            }
    }

    function f(b, e) {
        c(e.element).off("CornerstoneToolsMouseUp", f);
        if (void 0 !== a.getToolState(e.element, "freehand")) {
            var d = a.freehand.getConfiguration();
            e.event.shiftKey || (d.freehand = !1);
            g.updateImage(e.element)
        }
    }

    function h(f, b) {
        var d = a.getToolState(b.element, "freehand");
        if (void 0 !== d) {
            var c = a.freehand.getConfiguration(),
                d = d.data[c.currentTool];
            c.mouseLocation.handles.start.x = b.currentPoints.image.x;
            c.mouseLocation.handles.start.y = b.currentPoints.image.y;
            var h = c.currentHandle;
            if (c.modifying && (d.active = !0, d.highlight = !0, d.handles[h].x = c.mouseLocation.handles.start.x, d.handles[h].y = c.mouseLocation.handles.start.y, h)) {
                var k = d.handles[h - 1].lines[d.handles[h - 1].lines.length - 1];
                k.x = c.mouseLocation.handles.start.x;
                k.y = c.mouseLocation.handles.start.y
            }
            c.freehand ? d.handles[h - 1].lines.push(b.currentPoints.image) : (h = e(b, c.currentTool), void 0 !== h && h < d.handles.length - 1 && (c.mouseLocation.handles.start.x = d.handles[h].x, c.mouseLocation.handles.start.y = d.handles[h].y));
            g.updateImage(b.element)
        }
    }

    function m(f, b) {
        var e = a.getToolState(f.element, "freehand");
        if (void 0 !== e) {
            var d = a.freehand.getConfiguration(),
                e = e.data[d.currentTool];
            e.active = !1;
            e.highlight = !1;
            void 0 !== b && e.handles[d.currentHandle - 1].lines.push(e.handles[b]);
            d.modifying && (d.modifying = !1);
            d.currentHandle = 0;
            d.currentTool = -1;
            c(f.element).off("CornerstoneToolsMouseMove", h);
            g.updateImage(f.element)
        }
    }

    function k(d, g) {
        if (a.isMouseButtonEnabled(g.which, d.data.mouseButtonMask)) {
            var k = a.getToolState(g.element, "freehand"),
                l, n = a.freehand.getConfiguration();
            l = n.currentTool;
            if (n.modifying) m(g);
            else {
                if (0 > l) {
                    a: {
                        k = a.getToolState(g.element, "freehand");
                        if (void 0 !== k)
                            for (var r = 0; r < k.data.length; r++)
                                if (l = e(g, r), void 0 !== l) {
                                    l = { handleNearby: l, toolIndex: r };
                                    break a
                                }
                        l = void 0
                    }
                    l ? (k = l.handleNearby, l = l.toolIndex, void 0 !== k && (c(g.element).on("CornerstoneToolsMouseMove", h), c(g.element).on("CornerstoneToolsMouseUp", f), n.modifying = !0, n.currentHandle = k, n.currentTool = l)) : (c(g.element).on("CornerstoneToolsMouseMove", h), c(g.element).on("CornerstoneToolsMouseUp", f), n = a.freehand.getConfiguration(), n.mouseLocation.handles.start.x = g.currentPoints.image.x, n.mouseLocation.handles.start.y = g.currentPoints.image.y, a.addToolState(g.element, "freehand", {
                        visible: !0,
                        active: !0,
                        handles: []
                    }), k = a.getToolState(g.element, "freehand"), n.currentTool = k.data.length - 1, b(g))
                }
                else 0 <= l && k.data[l].active && (k = e(g, l), void 0 !== k ? m(g, k) : g.event.shiftKey ? n.freehand = !0 : b(g));
                return !1
            }
        }
    }

    function l(f, b) {
        var e = a.getToolState(f.currentTarget, "freehand");
        if (void 0 !== e) {
            var d = a.freehand.getConfiguration(),
                c = b.canvasContext.canvas.getContext("2d");
            c.setTransform(1, 0, 0, 1, 0, 0);
            for (var h, k = a.toolStyle.getToolWidth(), l = a.toolColors.getFillColor(), m = 0; m < e.data.length; m++) {
                c.save();
                var n = e.data[m];
                n.active ? (h = a.toolColors.getActiveColor(), l = a.toolColors.getFillColor()) : (h = a.toolColors.getToolColor(), l = a.toolColors.getToolColor());
                var z;
                if (n.handles.length)
                    for (var B = 0; B < n.handles.length; B++) {
                        z = n.handles[B];
                        z = g.pixelToCanvas(b.element, z);
                        c.beginPath();
                        c.strokeStyle = h;
                        c.lineWidth = k;
                        c.moveTo(z.x, z.y);
                        for (z = 0; z < n.handles[B].lines.length; z++) {
                            var C = g.pixelToCanvas(b.element, n.handles[B].lines[z]);
                            c.lineTo(C.x, C.y);
                            c.stroke()
                        }
                        z = g.pixelToCanvas(b.element, d.mouseLocation.handles.start);
                        B !== n.handles.length - 1 || !n.active || d.freehand || d.modifying || (c.lineTo(z.x, z.y), c.stroke())
                    }
                n.active && a.drawHandles(c, b, d.mouseLocation.handles, h, l);
                a.drawHandles(c, b, n.handles, h, l);
                c.restore()
            }
        }
    }

    var n = {
        mouseLocation: { handles: { start: { highlight: !0, active: !0 } } },
        freehand: !1,
        modifying: !1,
        currentHandle: 0,
        currentTool: -1
    };
    a.freehand = {
        enable: function(a) {
            c(a).off("CornerstoneToolsMouseDown", k);
            c(a).off("CornerstoneToolsMouseUp", f);
            c(a).off("CornerstoneToolsMouseMove", h);
            c(a).off("CornerstoneImageRendered", l);
            c(a).on("CornerstoneImageRendered", l);
            g.updateImage(a)
        },
        disable: function(a) {
            c(a).off("CornerstoneToolsMouseDown", k);
            c(a).off("CornerstoneToolsMouseUp", f);
            c(a).off("CornerstoneToolsMouseMove", h);
            c(a).off("CornerstoneImageRendered", l);
            g.updateImage(a)
        },
        activate: function(a, b) {
            var e = { mouseButtonMask: b };
            c(a).off("CornerstoneToolsMouseDown", e, k);
            c(a).off("CornerstoneToolsMouseUp", f);
            c(a).off("CornerstoneToolsMouseMove", h);
            c(a).off("CornerstoneImageRendered", l);
            c(a).on("CornerstoneImageRendered", l);
            c(a).on("CornerstoneToolsMouseDown", e, k);
            g.updateImage(a)
        },
        deactivate: function(a) {
            c(a).off("CornerstoneToolsMouseDown", k);
            c(a).off("CornerstoneToolsMouseUp", f);
            c(a).off("CornerstoneToolsMouseMove", h);
            c(a).off("CornerstoneImageRendered", l);
            c(a).on("CornerstoneImageRendered", l);
            g.updateImage(a)
        },
        getConfiguration: function() {
            return n
        },
        setConfiguration: function(a) {
            n = a
        }
    }
})($, cornerstone, cornerstoneMath, cornerstoneTools);
(function(c, g, d, a) {
    function b(b) {
        var f = a.getToolState(b.event.currentTarget, "highlight");
        if (!(f && f.data && 0 < f.data.length)) return {
            visible: !0,
            active: !0,
            handles: {
                start: { x: b.currentPoints.image.x, y: b.currentPoints.image.y, highlight: !0, active: !1 },
                end: { x: b.currentPoints.image.x, y: b.currentPoints.image.y, highlight: !0, active: !0 }
            }
        }
    }

    function e(a, b, f) {
        var e = g.pixelToCanvas(a, b.handles.start),
            d = g.pixelToCanvas(a, b.handles.end);
        a = Math.min(e.x, d.x);
        b = Math.min(e.y, d.y);
        var c = Math.abs(e.x - d.x),
            e = Math.abs(e.y - d.y),
            d = !1;
        f.x >= a && f.x <= a + c && f.y >= b && f.y <= b + e && (d = !0);
        return d
    }

    function f(a, b, f) {
        var e = g.pixelToCanvas(a, b.handles.start);
        a = g.pixelToCanvas(a, b.handles.end);
        e = {
            left: Math.min(e.x, a.x),
            top: Math.min(e.y, a.y),
            width: Math.abs(e.x - a.x),
            height: Math.abs(e.y - a.y)
        };
        return 5 > d.rect.distanceToPoint(e, f)
    }

    function h(b, f) {
        var e = a.getToolState(b.currentTarget, "highlight");
        if (void 0 !== e) {
            var d = f.canvasContext.canvas.getContext("2d");
            d.setTransform(1, 0, 0, 1, 0, 0);
            var c = a.toolStyle.getToolWidth();
            d.save();
            var h = e.data[0];
            if (h) {
                var e = h.active ? a.toolColors.getActiveColor() : a.toolColors.getToolColor(),
                    p = g.pixelToCanvas(f.element, h.handles.start),
                    u = g.pixelToCanvas(f.element, h.handles.end),
                    t = Math.min(p.x, u.x),
                    r = Math.min(p.y, u.y),
                    x = Math.abs(p.x - u.x),
                    p = Math.abs(p.y - u.y);
                d.beginPath();
                d.strokeStyle = "transparent";
                d.rect(0, 0, d.canvas.clientWidth, d.canvas.clientHeight);
                d.rect(x + t, r, -x, p);
                d.stroke();
                d.fillStyle = "rgba(0,0,0,0.7)";
                d.fill();
                d.closePath();
                d.beginPath();
                d.strokeStyle = e;
                d.lineWidth = c;
                d.setLineDash([4]);
                d.strokeRect(t, r, x, p);
                d.setLineDash([]);
                a.drawHandles(d, f, h.handles, e);
                d.restore()
            }
        }
    }

    a.highlight = a.mouseButtonRectangleTool({
        createNewMeasurement: b,
        onImageRendered: h,
        pointNearTool: f,
        pointInsideRect: e,
        toolType: "highlight"
    }, !0);
    a.highlightTouch = a.touchTool({
        createNewMeasurement: b,
        onImageRendered: h,
        pointNearTool: f,
        pointInsideRect: e,
        toolType: "highlight"
    }, !0)
})($, cornerstone, cornerstoneMath, cornerstoneTools);
(function(c, g, d) {
    d.keyboardTool = function(a) {
        var b = {};
        return {
            activate: function(b) {
                c(b).off("CornerstoneToolsKeyDown", a);
                c(b).on("CornerstoneToolsKeyDown", a)
            },
            disable: function(b) {
                c(b).off("CornerstoneToolsKeyDown", a)
            },
            enable: function(b) {
                c(b).off("CornerstoneToolsKeyDown", a)
            },
            deactivate: function(b) {
                c(b).off("CornerstoneToolsKeyDown", a)
            },
            getConfiguration: function() {
                return b
            },
            setConfiguration: function(a) {
                b = a
            }
        }
    }
})($, cornerstone, cornerstoneTools);
(function(c, g, d, a) {
    function b(a) {
        return {
            visible: !0,
            active: !0,
            handles: {
                start: { x: a.currentPoints.image.x, y: a.currentPoints.image.y, highlight: !0, active: !1 },
                end: { x: a.currentPoints.image.x, y: a.currentPoints.image.y, highlight: !0, active: !0 }
            }
        }
    }

    function e(a, b, f) {
        a = { start: g.pixelToCanvas(a, b.handles.start), end: g.pixelToCanvas(a, b.handles.end) };
        return 25 > d.lineSegment.distanceToPoint(a, f)
    }

    function f(b, f) {
        var d = a.getToolState(b.currentTarget, "length");
        if (void 0 !== d) {
            var e = f.canvasContext.canvas.getContext("2d");
            e.setTransform(1, 0, 0, 1, 0, 0);
            for (var c, q = a.toolStyle.getToolWidth(), w = a.textStyle.getFont(), p = a.length.getConfiguration(), u = 0; u < d.data.length; u++) {
                e.save();
                p && p.shadow && (e.shadowColor = "#000000", e.shadowOffsetX = 1, e.shadowOffsetY = 1);
                var t = d.data[u];
                c = t.active ? a.toolColors.getActiveColor() : a.toolColors.getToolColor();
                var r = g.pixelToCanvas(f.element, t.handles.start),
                    x = g.pixelToCanvas(f.element, t.handles.end);
                e.beginPath();
                e.strokeStyle = c;
                e.lineWidth = q;
                e.moveTo(r.x, r.y);
                e.lineTo(x.x, x.y);
                e.stroke();
                a.drawHandles(e, f, t.handles, c);
                e.fillStyle = c;
                e.font = w;
                var v = " mm";
                f.image.rowPixelSpacing && f.image.columnPixelSpacing || (v = " pixels");
                var y = (t.handles.start.x - t.handles.end.x) * (f.image.columnPixelSpacing || 1),
                    t = (t.handles.start.y - t.handles.end.y) * (f.image.rowPixelSpacing || 1),
                    v = "" + Math.sqrt(y * y + t * t).toFixed(2) + v;
                a.drawTextBox(e, v, (r.x + x.x) / 2 + 5, (r.y + x.y) / 2, c);
                e.restore()
            }
        }
    }
    a.length = a.mouseButtonTool({ createNewMeasurement: b, onImageRendered: f, pointNearTool: e, toolType: "length" });
    a.lengthTouch = a.touchTool({ createNewMeasurement: b, onImageRendered: f, pointNearTool: e, toolType: "length" })
})($, cornerstone, cornerstoneMath, cornerstoneTools);
(function(c, g, d) {
    function a(b, f) {
        c(f.element).off("CornerstoneToolsMouseDrag", e);
        c(f.element).off("CornerstoneToolsMouseUp", a);
        c(f.element).find(".magnifyTool").hide();
        document.body.style.cursor = "default"
    }

    function b(b, h) {
        if (d.isMouseButtonEnabled(h.which, b.data.mouseButtonMask)) return c(h.element).on("CornerstoneToolsMouseDrag", h, e), c(h.element).on("CornerstoneToolsMouseUp", h, a), f(h), !1
    }

    function e(a, b) {
        f(b);
        return !1
    }

    function f(a) {
        var b = c(a.element).find(".magnifyTool").get(0);
        void 0 === b && h(a.element);
        var f = d.magnify.getConfiguration(),
            e = f.magnifySize,
            k = f.magnificationLevel,
            f = c(a.element).find("canvas").not(".magnifyTool").get(0);
        f.getContext("2d").setTransform(1, 0, 0, 1, 0, 0);
        var l = b.getContext("2d");
        l.setTransform(1, 0, 0, 1, 0, 0);
        a = g.pixelToCanvas(a.element, a.currentPoints.image);
        a.x = Math.max(a.x, 0);
        a.x = Math.min(a.x, f.width);
        a.y = Math.max(a.y, 0);
        a.y = Math.min(a.y, f.height);
        l.clearRect(0, 0, e, e);
        l.fillStyle = "transparent";
        l.fillRect(0, 0, e, e);
        var m = e * k,
            k = e / k;
        l.drawImage(f, a.x - .5 * k, a.y - .5 * k, e, e, 0, 0, m, m);
        b.style.top = a.y - .5 * e + "px";
        b.style.left = a.x - .5 * e + "px";
        b.style.display = "block";
        document.body.style.cursor = "none"
    }

    function h(a) {
        if (0 === c(a).find(".magnifyTool").length) {
            var b = document.createElement("canvas");
            b.classList.add("magnifyTool");
            var f = d.magnify.getConfiguration();
            b.width = f.magnifySize;
            b.height = f.magnifySize;
            b.style.position = "absolute";
            a.appendChild(b)
        }
    }

    function m(a) {
        c(a).find(".magnifyTool").remove()
    }

    function k(a) {
        c(a).off("CornerstoneToolsMouseDown", b);
        m(a)
    }

    function l(a) {
        var b = d.magnify.getConfiguration(b);
        h(a)
    }

    function n(a) {
        c(a).off("CornerstoneToolsTouchDrag", e);
        c(a).off("CornerstoneToolsDragStart", e);
        m(a)
    }

    var q = { magnifySize: 100, magnificationLevel: 2 };
    d.magnify = {
        enable: l,
        activate: function(a, f) {
            var e = { mouseButtonMask: f };
            c(a).off("CornerstoneToolsMouseDown", b);
            c(a).on("CornerstoneToolsMouseDown", e, b);
            h(a)
        },
        deactivate: k,
        disable: k,
        getConfiguration: function() {
            return q
        },
        setConfiguration: function(a) {
            q = a
        }
    };
    d.magnifyTouchDrag = {
        enable: l,
        activate: function(a) {
            c(a).off("CornerstoneToolsTouchDrag", e);
            c(a).off("CornerstoneToolsDragStart", e);
            c(a).on("CornerstoneToolsTouchDrag", e);
            c(a).on("CornerstoneToolsDragStart", e);
            h(a)
        },
        deactivate: n,
        disable: n
    }
})($, cornerstone, cornerstoneTools);
(function(c, g, d) {
    d.orientationMarkers = d.displayTool(function(a, b) {
        var e = b.element,
            f;
        f = g.getEnabledElement(e);
        var c = d.metaData.get("imagePlane", f.image.imageId);
        if (c && c.rowCosines && c.columnCosines) {
            f = d.orientation.getOrientationString(c.rowCosines);
            var c = d.orientation.getOrientationString(c.columnCosines),
                m = d.orientation.invertOrientationString(f);
            f = { top: d.orientation.invertOrientationString(c), bottom: c, left: m, right: f }
        } else f = void 0;
        if (f) {
            var k = g.getEnabledElement(e),
                l;
            l = { x: k.image.width / 2, y: 5 };
            m = g.pixelToCanvas(e, l);
            l = { x: k.image.width / 2, y: k.image.height - 5 };
            c = g.pixelToCanvas(e, l);
            l = { x: 5, y: k.image.height / 2 };
            var n = g.pixelToCanvas(e, l);
            l = { x: k.image.width - 10, y: k.image.height / 2 };
            e = g.pixelToCanvas(e, l);
            k = b.canvasContext.canvas.getContext("2d");
            k.setTransform(1, 0, 0, 1, 0, 0);
            l = d.toolColors.getToolColor();
            var q = k.measureText(f.top).width,
                w = k.measureText(f.left).width,
                p = k.measureText(f.right).width,
                u = k.measureText(f.bottom).width;
            d.drawTextBox(k, f.top, m.x - q / 2, m.y, l);
            d.drawTextBox(k, f.left, n.x - w / 2, n.y, l);
            (m = d.orientationMarkers.getConfiguration()) && m.drawAllMarkers && (d.drawTextBox(k, f.right, e.x - p / 2, e.y, l), d.drawTextBox(k, f.bottom, c.x - u / 2, c.y, l))
        }
    })
})($, cornerstone, cornerstoneTools);
(function(c, g, d) {
    function a(e, f) {
        c(f.element).off("CornerstoneToolsMouseDrag", b);
        c(f.element).off("CornerstoneToolsMouseUp", a)
    }

    function b(a, b) {
        b.viewport.translation.x += b.deltaPoints.page.x / b.viewport.scale;
        b.viewport.translation.y += b.deltaPoints.page.y / b.viewport.scale;
        g.setViewport(b.element, b.viewport);
        return !1
    }

    d.pan = d.simpleMouseButtonTool(function(e, f) {
        if (d.isMouseButtonEnabled(f.which, e.data.mouseButtonMask)) return c(f.element).on("CornerstoneToolsMouseDrag", b), c(f.element).on("CornerstoneToolsMouseUp", a), !1
    });
    d.panTouchDrag = d.touchDragTool(function(a, b) {
        b.viewport.translation.x += b.deltaPoints.page.x / b.viewport.scale;
        b.viewport.translation.y += b.deltaPoints.page.y / b.viewport.scale;
        g.setViewport(b.element, b.viewport);
        return !1
    })
})($, cornerstone, cornerstoneTools);
(function(c, g, d) {
    function a(a, e) {
        e.viewport.translation.x += e.deltaPoints.page.x / e.viewport.scale;
        e.viewport.translation.y += e.deltaPoints.page.y / e.viewport.scale;
        g.setViewport(e.element, e.viewport);
        return !1
    }

    d.panMultiTouch = {
        activate: function(b) {
            c(b).off("CornerstoneToolsMultiTouchDrag", a);
            c(b).on("CornerstoneToolsMultiTouchDrag", a)
        },
        disable: function(b) {
            c(b).off("CornerstoneToolsMultiTouchDrag", a)
        }
    }
})($, cornerstone, cornerstoneTools);
(function(c, g, d) {
    function a(a) {
        return {
            visible: !0,
            active: !0,
            handles: { end: { x: a.currentPoints.image.x, y: a.currentPoints.image.y, highlight: !0, active: !0 } }
        }
    }

    function b(a, b, e) {
        a = g.pixelToCanvas(a, b.handles.end);
        return 5 > cornerstoneMath.point.distance(a, e)
    }

    function e(a, b) {
        var e = d.getToolState(a.currentTarget, "probe");
        if (e) {
            var c = b.canvasContext.canvas.getContext("2d");
            c.setTransform(1, 0, 0, 1, 0, 0);
            for (var l, n = d.textStyle.getFont(), q = d.textStyle.getFontSize(), w = 0; w < e.data.length; w++) {
                c.save();
                var p = e.data[w];
                l = p.active ? d.toolColors.getActiveColor() : d.toolColors.getToolColor();
                d.drawHandles(c, b, p.handles, l);
                var u = Math.round(p.handles.end.x),
                    t = Math.round(p.handles.end.y),
                    r, x;
                if (0 > u || 0 > t || u >= b.image.columns || t >= b.image.rows) break;
                if (b.image.color) x = "" + u + ", " + t, r = d.getRGBPixels(b.element, u, t, 1, 1), u = "R: " + r[0] + " G: " + r[1] + " B: " + r[2];
                else {
                    r = g.getStoredPixels(b.element, u, t, 1, 1);
                    var v = r[0],
                        y = v * b.image.slope + b.image.intercept;
                    r = d.calculateSUV(b.image, v);
                    x = "" + u + ", " + t;
                    u = "SP: " + v + " MO: " + parseFloat(y.toFixed(3));
                    r && (u += " SUV: " + parseFloat(r.toFixed(3)))
                }
                p = g.pixelToCanvas(b.element, { x: p.handles.end.x + 3, y: p.handles.end.y - 3 });
                c.font = n;
                c.fillStyle = l;
                d.drawTextBox(c, u, p.x, p.y + q + 5, l);
                d.drawTextBox(c, x, p.x, p.y, l);
                c.restore()
            }
        }
    }

    d.probe = d.mouseButtonTool({ createNewMeasurement: a, onImageRendered: e, pointNearTool: b, toolType: "probe" });
    d.probeTouch = d.touchTool({ createNewMeasurement: a, onImageRendered: e, pointNearTool: b, toolType: "probe" })
})($, cornerstone, cornerstoneTools);
(function(c, g, d, a) {
    function b(a) {
        return {
            visible: !0,
            active: !0,
            handles: {
                start: { x: a.currentPoints.image.x, y: a.currentPoints.image.y, highlight: !0, active: !1 },
                end: { x: a.currentPoints.image.x, y: a.currentPoints.image.y, highlight: !0, active: !0 }
            }
        }
    }

    function e(a, b, f) {
        var e = g.pixelToCanvas(a, b.handles.start);
        a = g.pixelToCanvas(a, b.handles.end);
        e = {
            left: Math.min(e.x, a.x),
            top: Math.min(e.y, a.y),
            width: Math.abs(e.x - a.x),
            height: Math.abs(e.y - a.y)
        };
        return 5 > d.rect.distanceToPoint(e, f)
    }

    function f(b, f) {
        var e, d, c = a.getToolState(b.currentTarget, "rectangleRoi");
        if (void 0 !== c) {
            var q = f.canvasContext.canvas.getContext("2d");
            q.setTransform(1, 0, 0, 1, 0, 0);
            for (var w, p = a.toolStyle.getToolWidth(), u = a.textStyle.getFont(), t = a.textStyle.getFontSize(), r = 0; r < c.data.length; r++) {
                q.save();
                e = c.data[r];
                w = e.active ? a.toolColors.getActiveColor() : a.toolColors.getToolColor();
                var x = g.pixelToCanvas(f.element, e.handles.start);
                d = g.pixelToCanvas(f.element, e.handles.end);
                var v = Math.abs(x.x - d.x),
                    y = Math.abs(x.y - d.y),
                    A = Math.min(x.x, d.x),
                    z = Math.min(x.y, d.y),
                    B = (x.x + d.x) / 2,
                    x = (x.y + d.y) / 2;
                q.beginPath();
                q.strokeStyle = w;
                q.lineWidth = p;
                q.rect(A, z, v, y);
                q.stroke();
                a.drawHandles(q, f, e.handles, w);
                A = Math.abs(e.handles.start.x - e.handles.end.x);
                z = Math.abs(e.handles.start.y - e.handles.end.y);
                d = Math.min(e.handles.start.x, e.handles.end.x);
                var C = Math.min(e.handles.start.y, e.handles.end.y);
                e = g.getPixels(f.element, d, C, A, z);
                var I = C,
                    E = A,
                    F = z;
                for (var D = 0, H = C = 0, G = 0, J = I; J < I + F; J++)
                    for (var K = d; K < d + E; K++) D += e[G], C += e[G] * e[G], H++, G++;
                0 === H ? d = e = 0 : (e = D / H, d = C / H - e * e, d = Math.sqrt(d));
                z = A * f.image.columnPixelSpacing * z * f.image.rowPixelSpacing;
                A = "\u9762\u79ef: " + z.toFixed(2) + " mm^2";
                q.font = u;
                z = q.measureText(z);
                v = B < f.image.columns / 2 ? B + v / 2 : B - v / 2 - z.width;
                y = x < f.image.rows / 2 ? x + y / 2 : x - y / 2;
                q.fillStyle = w;
                a.drawTextBox(q, "\u5e73\u5747\u503c: " + e.toFixed(2), v, y - t - 5, w);
                a.drawTextBox(q, "\u6807\u51c6\u5dee: " + d.toFixed(2), v, y, w);
                a.drawTextBox(q, A, v, y + t + 5, w);
                q.restore()
            }
        }
    }

    a.rectangleRoi = a.mouseButtonTool({
        createNewMeasurement: b,
        onImageRendered: f,
        pointNearTool: e,
        toolType: "rectangleRoi"
    });
    a.rectangleRoiTouch = a.touchTool({
        createNewMeasurement: b,
        onImageRendered: f,
        pointNearTool: e,
        toolType: "rectangleRoi"
    })
})($, cornerstone, cornerstoneMath, cornerstoneTools);
(function(c, g, d) {
    function a(a) {
        var b = a.element.getBoundingClientRect(a.element),
            b = -1 * Math.atan2(-1 * (a.currentPoints.client.y - b.top - a.element.clientHeight / 2), a.currentPoints.client.x - b.left - a.element.clientWidth / 2) * (180 / Math.PI) + 90;
        a.viewport.rotation = b
    }

    function b(a, d) {
        c(d.element).off("CornerstoneToolsMouseDrag", e);
        c(d.element).off("CornerstoneToolsMouseUp", b)
    }

    function e(a, b) {
        d.rotate.strategy(b);
        g.setViewport(b.element, b.viewport);
        a.stopPropagation();
        return !1
    }

    d.rotate = d.simpleMouseButtonTool(function(a, h) {
        if (d.isMouseButtonEnabled(h.which, a.data.mouseButtonMask)) return c(h.element).on("CornerstoneToolsMouseDrag", e), c(h.element).on("CornerstoneToolsMouseUp", b), !1
    });
    d.rotate.strategies = {
        default: a,
        horizontal: function(a) {
            a.viewport.rotation += a.deltaPoints.page.x / a.viewport.scale
        },
        vertical: function(a) {
            a.viewport.rotation += a.deltaPoints.page.y / a.viewport.scale
        }
    };
    d.rotate.strategy = a;
    d.rotateTouchDrag = d.touchDragTool(function(a, b) {
        d.rotate.strategy(b);
        g.setViewport(b.element, b.viewport);
        return !1
    })
})($, cornerstone, cornerstoneTools);
(function(c, g, d) {
    function a(a, e) {
        e.viewport.rotation += e.rotation;
        g.setViewport(e.element, e.viewport);
        return !1
    }

    d.rotateTouch = {
        activate: function(b) {
            c(b).off("CornerstoneToolsTouchRotate", a);
            c(b).on("CornerstoneToolsTouchRotate", a)
        },
        disable: function(b) {
            c(b).off("CornerstoneToolsTouchRotate", a)
        }
    }
})($, cornerstone, cornerstoneTools);
(function(c, g, d) {
    d.saveAs = function(a, b) {
        var e = c(a).find("canvas").get(0),
            f = document.createElement("a");
        f.download = b;
        f.href = e.toDataURL();
        document.createEvent ? (e = document.createEvent("MouseEvents"), e.initMouseEvent("click", !0, !0, window, 0, 0, 0, 0, 0, !1, !1, !1, !1, 0, null), f.dispatchEvent(e)) : f.fireEvent && f.fireEvent("onclick")
    }
})($, cornerstone, cornerstoneTools);
(function(c, g, d, a) {
    function b(a) {
        return {
            visible: !0,
            active: !0,
            handles: {
                start: { x: a.currentPoints.image.x, y: a.currentPoints.image.y, highlight: !0, active: !1 },
                middle: { x: a.currentPoints.image.x, y: a.currentPoints.image.y, highlight: !0, active: !0 },
                end: { x: a.currentPoints.image.x, y: a.currentPoints.image.y, highlight: !0, active: !1 }
            }
        }
    }

    function e(a, b, f) {
        var e = {
                start: g.pixelToCanvas(a, b.handles.start),
                end: g.pixelToCanvas(a, b.handles.middle)
            },
            c = d.lineSegment.distanceToPoint(e, f);
        if (5 > c) return !0;
        e.start = g.pixelToCanvas(a, b.handles.middle);
        e.end = g.pixelToCanvas(a, b.handles.end);
        c = d.lineSegment.distanceToPoint(e, f);
        return 5 > c
    }

    function f(a) {
        return Math.sqrt(Math.pow(a.x, 2) + Math.pow(a.y, 2))
    }

    function h(b, e) {
        var d = a.getToolState(b.currentTarget, "simpleAngle");
        if (void 0 !== d) {
            var c = e.canvasContext.canvas.getContext("2d");
            c.setTransform(1, 0, 0, 1, 0, 0);
            for (var h, w = a.toolStyle.getToolWidth(), p = a.textStyle.getFont(), u = 0; u < d.data.length; u++) {
                c.save();
                var t = d.data[u];
                h = t.active ? a.toolColors.getActiveColor() : a.toolColors.getToolColor();
                c.beginPath();
                c.strokeStyle = h;
                c.lineWidth = w;
                var r = g.pixelToCanvas(e.element, t.handles.start),
                    x = g.pixelToCanvas(e.element, t.handles.middle);
                c.moveTo(r.x, r.y);
                c.lineTo(x.x, x.y);
                var v = g.pixelToCanvas(e.element, t.handles.end);
                c.lineTo(v.x, v.y);
                c.stroke();
                a.drawHandles(c, e, t.handles, h);
                c.fillStyle = h;
                var y = e.image.columnPixelSpacing || 1,
                    A = e.image.rowPixelSpacing || 1,
                    v = "";
                e.image.rowPixelSpacing && e.image.columnPixelSpacing || (v = " (isotropic)");
                var z = {
                        x: (Math.ceil(t.handles.middle.x) - Math.ceil(t.handles.start.x)) * y,
                        y: (Math.ceil(t.handles.middle.y) - Math.ceil(t.handles.start.y)) * A
                    },
                    B = {
                        x: (Math.ceil(t.handles.end.x) - Math.ceil(t.handles.middle.x)) * y,
                        y: (Math.ceil(t.handles.end.y) - Math.ceil(t.handles.middle.y)) * A
                    },
                    t = {
                        x: (Math.ceil(t.handles.end.x) - Math.ceil(t.handles.start.x)) * y,
                        y: (Math.ceil(t.handles.end.y) - Math.ceil(t.handles.start.y)) * A
                    },
                    z = f(z),
                    B = f(B),
                    t = f(t),
                    B = Math.acos((Math.pow(z, 2) + Math.pow(B, 2) - Math.pow(t, 2)) / (2 * z * B)),
                    B = 180 / Math.PI * B;
                if (B = a.roundToDecimal(B, 2)) v = B.toString() + String.fromCharCode(176) + v, B = x.x + 15, t = x.y + 15, c.font = p, B = c.measureText(v).width, B = 0 > x.x - r.x ? x.x - 15 - B - 10 : x.x + 15, t = x.y, a.drawTextBox(c, v, B, t, h);
                c.restore()
            }
        }
    }

    a.simpleAngle = a.mouseButtonTool({
        createNewMeasurement: b,
        addNewMeasurement: function(f) {
            var e = b(f),
                d = f.element,
                h = { mouseButtonMask: f.which };
            a.addToolState(d, "simpleAngle", e);
            c(d).off("CornerstoneToolsMouseMove", a.simpleAngle.mouseMoveCallback);
            c(d).off("CornerstoneToolsMouseDrag", a.simpleAngle.mouseMoveCallback);
            c(d).off("CornerstoneToolsMouseDown", a.simpleAngle.mouseDownCallback);
            c(d).off("CornerstoneToolsMouseDownActivate", a.simpleAngle.mouseDownActivateCallback);
            g.updateImage(d);
            a.moveNewHandle(f, e.handles.middle, function() {
                e.active = !1;
                a.anyHandlesOutsideImage(f, e.handles) && a.removeToolState(d, "simpleAngle", e);
                e.handles.end.active = !0;
                g.updateImage(d);
                a.moveNewHandle(f, e.handles.end, function() {
                    e.active = !1;
                    a.anyHandlesOutsideImage(f, e.handles) && a.removeToolState(d, "simpleAngle", e);
                    c(d).on("CornerstoneToolsMouseMove", a.simpleAngle.mouseMoveCallback);
                    c(d).on("CornerstoneToolsMouseDrag", a.simpleAngle.mouseMoveCallback);
                    c(d).on("CornerstoneToolsMouseDown", h, a.simpleAngle.mouseDownCallback);
                    c(d).on("CornerstoneToolsMouseDownActivate", h, a.simpleAngle.mouseDownActivateCallback);
                    g.updateImage(d)
                })
            })
        },
        onImageRendered: h,
        pointNearTool: e,
        toolType: "simpleAngle"
    });
    a.simpleAngleTouch = a.touchTool({
        createNewMeasurement: b,
        addNewMeasurement: function(f) {
            var e = b(f),
                d = f.element;
            a.addToolState(d, "simpleAngle", e);
            c(d).off("CornerstoneToolsTouchDrag", a.simpleAngleTouch.touchMoveCallback);
            c(d).off("CornerstoneToolsDragStartActive", a.simpleAngleTouch.touchDownActivateCallback);
            c(d).off("CornerstoneToolsTap", a.simpleAngleTouch.tapCallback);
            g.updateImage(d);
            a.moveNewHandleTouch(f, e.handles.middle, function() {
                e.active = !1;
                a.anyHandlesOutsideImage(f, e.handles) && a.removeToolState(d, "simpleAngle", e);
                e.handles.end.active = !0;
                g.updateImage(d);
                a.moveNewHandleTouch(f, e.handles.end, function() {
                    e.active = !1;
                    a.anyHandlesOutsideImage(f, e.handles) && a.removeToolState(d, "simpleAngle", e);
                    c(d).on("CornerstoneToolsTouchDrag", a.simpleAngleTouch.touchMoveCallback);
                    c(d).on("CornerstoneToolsDragStartActive", a.simpleAngleTouch.touchDownActivateCallback);
                    c(d).on("CornerstoneToolsTap", a.simpleAngleTouch.tapCallback);
                    g.updateImage(d)
                })
            })
        },
        onImageRendered: h,
        pointNearTool: e,
        toolType: "simpleAngle"
    })
})($, cornerstone, cornerstoneMath, cornerstoneTools);
(function(c, g, d) {
    function a(a) {
        var b = d.textMarker.getConfiguration();
        if (b.current) {
            a = {
                visible: !0,
                active: !0,
                text: b.current,
                handles: { end: { x: a.currentPoints.image.x, y: a.currentPoints.image.y, highlight: !0, active: !0 } }
            };
            var f = b.markers.indexOf(b.current);
            b.ascending ? (f += 1, f >= b.markers.length && (f = b.loop ? f - b.markers.length : -1)) : (--f, 0 > f && (f = b.loop ? f + b.markers.length : -1));
            b.current = b.markers[f];
            return a
        }
    }

    function b(a, b, f) {
        a = g.pixelToCanvas(a, b.handles.end);
        return 10 > cornerstoneMath.rect.distanceToPoint({
            left: a.x - b.textWidth / 2,
            top: a.y,
            width: b.textWidth,
            height: b.textHeight
        }, f)
    }

    function e(a, b) {
        var f = d.getToolState(b.element, "textMarker");
        if (f) {
            var e = b.canvasContext.canvas.getContext("2d");
            e.setTransform(1, 0, 0, 1, 0, 0);
            for (var c = d.textStyle.getFont(), q = d.textStyle.getFontSize(), w = 0; w < f.data.length; w++) {
                var p = f.data[w],
                    u = d.toolColors.getToolColor();
                p.active && (u = d.toolColors.getActiveColor());
                e.save();
                e.font = c;
                e.fillStyle = u;
                var t = e.measureText(p.text);
                p.textWidth = t.width;
                p.textHeight = q;
                t = g.pixelToCanvas(b.element, { x: p.handles.end.x, y: p.handles.end.y });
                d.drawTextBox(e, p.text, t.x - p.textWidth / 2, t.y, u);
                e.restore()
            }
        }
    }

    function f(a, f) {
        function e(a, b, f) {
            !0 === f ? d.removeToolState(c, "textMarker", a) : a.text = b;
            a.active = !1;
            g.updateImage(c)
        }

        var c = f.element,
            n;
        if (a.data && a.data.mouseButtonMask && !d.isMouseButtonEnabled(f.which, a.data.mouseButtonMask)) return !1;
        var q = d.textMarker.getConfiguration(),
            w = f.currentPoints.canvas,
            p = d.getToolState(c, "textMarker");
        if (!p) return !1;
        for (var u = 0; u < p.data.length; u++)
            if (n = p.data[u], b(c, n, w)) {
                n.active = !0;
                g.updateImage(c);
                q.changeTextCallback(n, e);
                a.stopImmediatePropagation();
                break
            }
        return !1
    }

    d.textMarker = d.mouseButtonTool({
        createNewMeasurement: a,
        onImageRendered: e,
        pointNearTool: b,
        toolType: "textMarker",
        mouseDoubleClickCallback: f
    });
    d.textMarkerTouch = d.touchTool({
        createNewMeasurement: a,
        onImageRendered: e,
        pointNearTool: b,
        toolType: "textMarker",
        pressCallback: f
    })
})($, cornerstone, cornerstoneTools);
(function(c, g, d) {
    function a(b, d) {
        c(d.element).off("CornerstoneToolsMouseDrag", e);
        c(d.element).off("CornerstoneToolsMouseUp", a)
    }

    function b(a) {
        var b = (a.image.maxPixelValue * a.image.slope + a.image.intercept - (a.image.minPixelValue * a.image.slope + a.image.intercept)) / 1024,
            e = a.deltaPoints.page.y * b;
        a.viewport.voi.windowWidth += a.deltaPoints.page.x * b;
        a.viewport.voi.windowCenter += e
    }

    function e(a, b) {
        d.wwwc.strategy(b);
        g.setViewport(b.element, b.viewport);
        return !1
    }

    d.wwwc = d.simpleMouseButtonTool(function(b, h) {
        if (d.isMouseButtonEnabled(h.which, b.data.mouseButtonMask)) return c(h.element).on("CornerstoneToolsMouseDrag", e), c(h.element).on("CornerstoneToolsMouseUp", a), !1
    });
    d.wwwc.strategies = { default: b };
    d.wwwc.strategy = b;
    d.wwwcTouchDrag = d.touchDragTool(function(a, b) {
        var e = (b.image.maxPixelValue * b.image.slope + b.image.intercept - (b.image.minPixelValue * b.image.slope + b.image.intercept)) / 1024,
            c = b.deltaPoints.page.x * e,
            e = b.deltaPoints.page.y * e,
            l = d.wwwc.getConfiguration();
        l.orientation ? 0 === l.orientation ? (b.viewport.voi.windowWidth += c, b.viewport.voi.windowCenter += e) : (b.viewport.voi.windowWidth += e, b.viewport.voi.windowCenter += c) : (b.viewport.voi.windowWidth += c, b.viewport.voi.windowCenter += e);
        g.setViewport(b.element, b.viewport)
    })
})($, cornerstone, cornerstoneTools);
(function(c, g, d, a) {
    function b(f, d) {
        c(d.element).off("CornerstoneToolsMouseMove", k);
        c(d.element).off("CornerstoneToolsMouseDrag", k);
        c(d.element).off("CornerstoneToolsMouseUp", b);
        c(d.element).off("CornerstoneToolsMouseClick", b);
        var g = a.getToolState(d.element, "wwwcRegion");
        g && g.data.length && (g.data[0].endPoint = {
            x: d.currentPoints.image.x,
            y: d.currentPoints.image.y
        }, e(d), g = { mouseButtonMask: d.which }, c(d.element).on("CornerstoneToolsMouseDown", g, h))
    }

    function e(b) {
        var f, e, d, c = a.getToolState(b.element, "wwwcRegion");
        if (void 0 !== c && c.data.length) {
            e = c.data[0].startPoint;
            var h = c.data[0].endPoint;
            d = Math.abs(e.x - h.x);
            f = Math.abs(e.y - h.y);
            var l = Math.min(e.x, h.x);
            e = Math.min(e.y, h.y);
            l = Math.max(l, 0);
            l = Math.min(l, b.image.width);
            e = Math.max(e, 0);
            e = Math.min(e, b.image.height);
            d = Math.floor(Math.min(d, Math.abs(b.image.width - l)));
            f = Math.floor(Math.min(f, Math.abs(b.image.height - e)));
            f = a.getLuminance(b.element, l, e, d, f);
            l = b.image.minPixelValue;
            e = b.image.maxPixelValue;
            d = f.length;
            if (2 > d) d = f = l;
            else {
                for (var h = l, k = l = 0; k < d; k++) {
                    var m = f[k];
                    e = Math.min(e, m);
                    h = Math.max(h, m);
                    l += m
                }
                f = e;
                e = h;
                d = l / d
            }
            l = a.wwwcRegion.getConfiguration();
            h = g.getViewport(b.element);
            h.voi.windowWidth = Math.max(Math.abs(e - f), l.minWindowWidth);
            h.voi.windowCenter = d;
            g.setViewport(b.element, h);
            c.data = [];
            g.updateImage(b.element)
        }
    }

    function f(a, f) {
        var e = f.element;
        c(e).off("CornerstoneToolsMouseMove");
        c(e).off("CornerstoneToolsMouseDrag");
        c(e).on("CornerstoneToolsMouseMove", k);
        c(e).on("CornerstoneToolsMouseDrag", k);
        c(e).on("CornerstoneToolsMouseClick", b);
        if ("CornerstoneToolsMouseDrag" === a.type) c(e).on("CornerstoneToolsMouseUp", b)
    }

    function h(e, d) {
        if (a.isMouseButtonEnabled(d.which, e.data.mouseButtonMask)) return c(d.element).on("CornerstoneToolsMouseDrag", d, f), c(d.element).on("CornerstoneToolsMouseMove", d, f), c(d.element).on("CornerstoneToolsMouseUp", b), c(d.element).off("CornerstoneToolsMouseDown"), m(d), !1
    }

    function m(b) {
        var e = a.getToolState(b.element, "wwwcRegion");
        e && e.data && (e.data = []);
        a.addToolState(b.element, "wwwcRegion", { startPoint: { x: b.currentPoints.image.x, y: b.currentPoints.image.y } })
    }

    function k(b, e) {
        var f = a.getToolState(e.element, "wwwcRegion");
        void 0 !== f && f.data.length && (f.data[0].endPoint = {
            x: e.currentPoints.image.x,
            y: e.currentPoints.image.y
        }, g.updateImage(e.element))
    }

    function l(b, e) {
        var f = a.getToolState(e.element, "wwwcRegion");
        if (void 0 !== f && f.data.length) {
            var d = f.data[0].startPoint,
                h = f.data[0].endPoint;
            if (d && h) {
                f = c(e.element).find("canvas").get(0).getContext("2d");
                f.setTransform(1, 0, 0, 1, 0, 0);
                var l = a.toolColors.getActiveColor(),
                    k = g.pixelToCanvas(e.element, d),
                    m = g.pixelToCanvas(e.element, h),
                    d = Math.min(k.x, m.x),
                    h = Math.min(k.y, m.y),
                    n = Math.abs(k.x - m.x),
                    k = Math.abs(k.y - m.y),
                    m = a.toolStyle.getToolWidth();
                f.save();
                f.beginPath();
                f.strokeStyle = l;
                f.lineWidth = m;
                f.rect(d, h, n, k);
                f.stroke();
                f.restore()
            }
        }
    }

    function n(a) {
        c(a).off("CornerstoneToolsMouseDown", h);
        c(a).off("CornerstoneImageRendered", l);
        g.updateImage(a)
    }

    function q(a) {
        c(a).off("CornerstoneToolsTouchDrag", k);
        c(a).off("CornerstoneToolsDragStart", m);
        c(a).off("CornerstoneToolsDragEnd", e);
        c(a).off("CornerstoneImageRendered", l)
    }

    var w = { minWindowWidth: 10 };
    a.wwwcRegion = {
        activate: function(e, f) {
            var d = { mouseButtonMask: f };
            a.getToolState(e, "wwwcRegion") || a.addToolState(e, "wwwcRegion", []);
            c(e).off("CornerstoneToolsMouseDown", h);
            c(e).off("CornerstoneToolsMouseUp", b);
            c(e).off("CornerstoneToolsMouseDrag", k);
            c(e).off("CornerstoneImageRendered", l);
            c(e).on("CornerstoneToolsMouseDown", d, h);
            c(e).on("CornerstoneImageRendered", l);
            g.updateImage(e)
        },
        deactivate: n,
        disable: n,
        setConfiguration: function(a) {
            w = a
        },
        getConfiguration: function() {
            return w
        }
    };
    a.wwwcRegionTouch = {
        activate: function(b) {
            void 0 === a.getToolState(b, "wwwcRegion") && a.addToolState(b, "wwwcRegion", []);
            c(b).off("CornerstoneToolsTouchDrag", k);
            c(b).off("CornerstoneToolsDragStart", m);
            c(b).off("CornerstoneToolsDragEnd", e);
            c(b).off("CornerstoneImageRendered", l);
            c(b).on("CornerstoneToolsTouchDrag", k);
            c(b).on("CornerstoneToolsDragStart", m);
            c(b).on("CornerstoneToolsDragEnd", e);
            c(b).on("CornerstoneImageRendered", l)
        },
        deactivate: q,
        disable: q
    }
})($, cornerstone, cornerstoneMath, cornerstoneTools);
(function(c, g, d) {
    function a(a, b) {
        var e = d.zoom.getConfiguration(),
            f = Math.log(a.scale) / Math.log(1.7) + b,
            f = Math.pow(1.7, f);
        a.scale = f;
        e.maxScale && f > e.maxScale ? a.scale = e.maxScale : e.minScale && f < e.minScale && (a.scale = e.minScale);
        return a
    }

    function b(a, b, e) {
        a.x = Math.max(a.x, 0);
        a.y = Math.max(a.y, 0);
        a.x = Math.min(a.x, b);
        a.y = Math.min(a.y, e);
        return a
    }

    function e(a, b) {
        if (0 !== b.rotation) {
            var e = b.rotation * Math.PI / 180,
                f = Math.cos(e),
                e = Math.sin(e),
                d = a.x * e + a.y * f;
            a.x = a.x * f - a.y * e;
            a.y = d
        }
        b.hflip && (a.x *= -1);
        b.vflip && (a.y *= -1);
        return a
    }

    function f(b, f) {
        var d = b.element,
            c = a(b.viewport, f);
        g.setViewport(d, c);
        var h = g.pageToPixel(d, b.startPoints.page.x, b.startPoints.page.y),
            h = {
                x: b.startPoints.image.x - h.x,
                y: b.startPoints.image.y - h.y
            },
            h = e(h, c);
        c.translation.x -= h.x;
        c.translation.y -= h.y;
        g.setViewport(d, c)
    }

    function h(a, b) {
        c(b.element).off("CornerstoneToolsMouseDrag", m);
        c(b.element).off("CornerstoneToolsMouseUp", h)
    }

    function m(a, b) {
        if (!b.deltaPoints.page.y) return !1;
        d.zoom.strategy(b, b.deltaPoints.page.y / 100);
        return !1
    }

    var k;
    d.zoom = d.simpleMouseButtonTool(function(a, b) {
        if (d.isMouseButtonEnabled(b.which, a.data.mouseButtonMask)) return k = b.startPoints, c(b.element).on("CornerstoneToolsMouseDrag", m), c(b.element).on("CornerstoneToolsMouseUp", h), !1
    });
    d.zoom.strategies = {
        default: f,
        translate: function(f, c) {
            var h = f.element,
                m = f.image,
                p = a(f.viewport, c);
            g.setViewport(h, p);
            var u = d.zoom.getConfiguration(),
                t;
            if (0 > c)
                if (m = {
                        x: 3 > p.scale ? p.translation.x / 8 : 0,
                        y: 3 > p.scale ? p.translation.y / 8 : 0
                    }, .01 > Math.abs(p.translation.x)) p.translation.x = 0, m.x = 0;
                else if (.01 > Math.abs(p.translation.y)) p.translation.y = 0, m.y = 0;
            else {
                if (.01 > Math.abs(p.translation.x) && .01 > Math.abs(p.translation.y)) return g.setViewport(h, p), !1
            } else {
                t = g.pageToPixel(h, k.page.x, k.page.y);
                u && u.preventZoomOutsideImage && (k.image = b(k.image, m.width, m.height), b(t, m.width, m.height));
                u = m.width / 2 - k.image.x;
                t = m.height / 2 - k.image.y;
                var r = p.translation.x - u,
                    x = p.translation.y - t,
                    m = { x: r / 8, y: x / 8 };
                if (.01 > Math.abs(r)) p.translation.x = u, m.x = 0;
                else if (.01 > Math.abs(x)) p.translation.y = t, m.y = 0;
                else if (.01 > Math.abs(r) && .01 > Math.abs(x)) return g.setViewport(h, p), !1
            }
            m = e(m, p);
            if (!m.x && !m.y) return !1;
            p.translation.x -= m.x;
            p.translation.y -= m.y;
            g.setViewport(h, p)
        }
    };
    d.zoom.strategy = f;
    d.zoomWheel = d.mouseWheelTool(function(b, e) {
        var f = a(e.viewport, -e.direction / 12);
        g.setViewport(e.element, f)
    });
    d.zoomTouchPinch = d.touchPinchTool(function(b, e) {
        var f = a(e.viewport, e.direction / 30);
        g.setViewport(e.element, f)
    });
    d.zoomTouchDrag = d.touchDragTool(m)
})($, cornerstone, cornerstoneTools);
(function(c, g, d) {
    function a(a) {
        var b = a.currentTarget,
            d = g.pageToPixel(b, e, f);
        a = window.event || a;
        d = {
            element: b,
            viewport: g.getViewport(b),
            image: g.getEnabledElement(b).image,
            pageX: e,
            pageY: f,
            imageX: d.x,
            imageY: d.y,
            keyCode: a.keyCode,
            which: a.which
        };
        "keydown" === a.type ? c(b).trigger("CornerstoneToolsKeyDown", d) : "keypress" === a.type ? c(b).trigger("CornerstoneToolsKeyPress", d) : "keyup" === a.type && c(b).trigger("CornerstoneToolsKeyUp", d)
    }

    function b(a) {
        e = a.pageX || a.originalEvent.pageX;
        f = a.pageY || a.originalEvent.pageY
    }

    var e, f;
    d.keyboardInput = {
        enable: function(e) {
            c(e).bind("keydown keypress keyup", a);
            c(e).on("mousemove", b)
        },
        disable: function(b) {
            c(b).unbind("keydown keypress keyup", a)
        }
    }
})($, cornerstone, cornerstoneTools);
(function(c) {
    c.preventGhostClick = function(c) {
        function d(a, d) {
            var c = Date.now();
            if (a !== b) {
                if (2E3 >= c - e) return d.preventDefault(), d.stopPropagation(), d.stopImmediatePropagation(), !1;
                b = a
            }
            e = c
        }

        function a(a, b) {
            a.forEach(function(a) {
                c.addEventListener(a, d.bind(null, b), !0)
            })
        }

        var b, e;
        a(["mousedown", "mouseup", "mousemove"], 0);
        a(["touchstart", "touchend"], 1)
    }
})(cornerstoneTools);
(function(c, g, d, a) {
    a.anyHandlesOutsideImage = function(a, e) {
        var f = a.image,
            c = { left: 0, top: 0, width: f.width, height: f.height },
            g = !1;
        Object.keys(e).forEach(function(a) {
            !1 === d.point.insideRect(e[a], c) && (g = !0)
        });
        return g
    }
})($, cornerstone, cornerstoneMath, cornerstoneTools);
(function(c, g) {
    g.drawHandles = function(d, a, b, e, f) {
        d.strokeStyle = e;
        Object.keys(b).forEach(function(e) {
            e = b[e];
            d.beginPath();
            d.lineWidth = e.active ? g.toolStyle.getActiveWidth() : g.toolStyle.getToolWidth();
            e = c.pixelToCanvas(a.element, e);
            d.arc(e.x, e.y, 6, 0, 2 * Math.PI);
            f && (d.fillStyle = f, d.fill());
            d.stroke()
        })
    }
})(cornerstone, cornerstoneTools);
(function(c, g, d, a) {
    a.getHandleNearImagePoint = function(a, e, f, c) {
        var m;
        if (e.handles) return Object.keys(e.handles).forEach(function(k) {
            k = e.handles[k];
            var l = g.pixelToCanvas(a, k);
            d.point.distanceSquared(l, f) < c && (m = k)
        }), m
    }
})($, cornerstone, cornerstoneMath, cornerstoneTools);
(function(c, g, d, a) {
    function b(a, b, e) {
        var c;
        Object.keys(b).forEach(function(l) {
            l = b[l];
            var n = g.pixelToCanvas(a, l);
            36 >= d.point.distance(n, e) && (c = l)
        });
        return c
    }

    function e(a) {
        var b;
        Object.keys(a).forEach(function(e) {
            e = a[e];
            !0 === e.active && (b = e)
        });
        return b
    }

    a.handleActivator = function(a, d, c) {
        var g = e(d);
        a = b(a, d, c);
        return g !== a ? (void 0 !== a && (a.active = !0), void 0 !== g && (g.active = !1), !0) : !1
    }
})($, cornerstone, cornerstoneMath, cornerstoneTools);
(function(c, g, d, a) {
    a.moveAllHandles = function(a, e, f, h, m) {
        function k(a, b) {
            e.active = !0;
            Object.keys(e.handles).forEach(function(a) {
                a = e.handles[a];
                a.x += b.deltaPoints.image.x;
                a.y += b.deltaPoints.image.y;
                !0 === h.preventHandleOutsideImage && (a.x = Math.max(a.x, 0), a.x = Math.min(a.x, b.image.width), a.y = Math.max(a.y, 0), a.y = Math.min(a.y, b.image.height))
            });
            g.updateImage(n);
            return !1
        }

        function l(a, b) {
            e.active = !1;
            e.invalidated = !0;
            c(n).off("CornerstoneToolsMouseDrag", k);
            c(n).off("CornerstoneToolsMouseUp", l);
            c(n).off("CornerstoneToolsMouseClick", l);
            if (!0 === h.deleteIfHandleOutsideImage) {
                var p = b.image,
                    u = !1,
                    t = { top: 0, left: 0, width: p.width, height: p.height };
                Object.keys(e.handles).forEach(function(a) {
                    a = e.handles[a];
                    a.active = !1;
                    !1 === d.point.insideRect(a, t) && (u = !0)
                });
                if (u) {
                    var r = -1;
                    f.data.forEach(function(a, b) {
                        a === e && (r = b)
                    }); -
                    1 !== r && f.data.splice(r, 1)
                }
            }
            g.updateImage(n);
            m()
        }

        var n = a.element;
        c(n).on("CornerstoneToolsMouseDrag", k);
        c(n).on("CornerstoneToolsMouseUp", l);
        c(n).on("CornerstoneToolsMouseClick", l);
        return !0
    }
})($, cornerstone, cornerstoneMath, cornerstoneTools);
(function(c, g, d) {
    d.moveHandle = function(a, b, e, f) {
        function d(a, e) {
            b.active = !0;
            b.x = e.currentPoints.image.x;
            b.y = e.currentPoints.image.y;
            f && (b.x = Math.max(b.x, 0), b.x = Math.min(b.x, e.image.width), b.y = Math.max(b.y, 0), b.y = Math.min(b.y, e.image.height));
            g.updateImage(k)
        }

        function m() {
            b.active = !1;
            c(k).off("CornerstoneToolsMouseDrag", d);
            c(k).off("CornerstoneToolsMouseUp", m);
            c(k).off("CornerstoneToolsMouseClick", m);
            g.updateImage(k);
            e()
        }

        var k = a.element;
        c(k).on("CornerstoneToolsMouseDrag", d);
        c(k).on("CornerstoneToolsMouseUp", m);
        c(k).on("CornerstoneToolsMouseClick", m)
    }
})($, cornerstone, cornerstoneTools);
(function(c, g, d) {
    d.moveNewHandle = function(a, b, e, f) {
        function d(a, e) {
            b.active = !0;
            b.x = e.currentPoints.image.x;
            b.y = e.currentPoints.image.y;
            f && (b.x = Math.max(b.x, 0), b.x = Math.min(b.x, e.image.width), b.y = Math.max(b.y, 0), b.y = Math.min(b.y, e.image.height));
            g.updateImage(l)
        }

        function m(a) {
            c(l).off("CornerstoneToolsMouseMove");
            c(l).off("CornerstoneToolsMouseDrag");
            c(l).on("CornerstoneToolsMouseMove", d);
            c(l).on("CornerstoneToolsMouseDrag", d);
            c(l).on("CornerstoneToolsMouseClick", k);
            if ("CornerstoneToolsMouseDrag" === a.type) c(l).on("CornerstoneToolsMouseUp", k)
        }

        function k() {
            c(l).off("CornerstoneToolsMouseMove", d);
            c(l).off("CornerstoneToolsMouseDrag", d);
            c(l).off("CornerstoneToolsMouseClick", k);
            c(l).off("CornerstoneToolsMouseUp", k);
            b.active = !1;
            g.updateImage(l);
            e()
        }

        var l = a.element;
        c(l).on("CornerstoneToolsMouseDrag", m);
        c(l).on("CornerstoneToolsMouseMove", m)
    }
})($, cornerstone, cornerstoneTools);
(function(c, g, d) {
    d.moveNewHandleTouch = function(a, b, e, f) {
        function d(a, e) {
            b.active = !0;
            b.x = e.currentPoints.image.x;
            b.y = e.currentPoints.image.y;
            f && (b.x = Math.max(b.x, 0), b.x = Math.min(b.x, e.image.width), b.y = Math.max(b.y, 0), b.y = Math.min(b.y, e.image.height));
            g.updateImage(k)
        }

        function m(a, n) {
            c(k).off("CornerstoneToolsTouchDrag", d);
            c(k).off("CornerstoneToolsTap", m);
            c(k).off("CornerstoneToolsDragEnd", m);
            b.active = !1;
            b.x = n.currentPoints.image.x;
            b.y = n.currentPoints.image.y;
            f && (b.x = Math.max(b.x, 0), b.x = Math.min(b.x, n.image.width), b.y = Math.max(b.y, 0), b.y = Math.min(b.y, n.image.height));
            g.updateImage(k);
            e()
        }

        var k = a.element;
        c(k).on("CornerstoneToolsTouchDrag", d);
        c(k).on("CornerstoneToolsDragEnd", m);
        c(k).on("CornerstoneToolsTap", m)
    }
})($, cornerstone, cornerstoneTools);
(function(c, g, d, a) {
    a.touchMoveAllHandles = function(a, e, f, h, m) {
        function k(a, b) {
            e.active = !0;
            Object.keys(e.handles).forEach(function(a) {
                a = e.handles[a];
                a.x += b.deltaPoints.image.x;
                a.y += b.deltaPoints.image.y
            });
            g.updateImage(n);
            return !1
        }

        function l(a, b) {
            e.active = !1;
            e.invalidated = !1;
            c(n).off("CornerstoneToolsTouchDrag", k);
            c(n).off("CornerstoneToolsDragEnd", l);
            c(n).off("CornerstoneToolsTap", l);
            if (!0 === h) {
                var p = b.image,
                    u = !1,
                    t = { top: 0, left: 0, width: p.width, height: p.height };
                Object.keys(e.handles).forEach(function(a) {
                    !1 === d.point.insideRect(e.handles[a], t) && (u = !0)
                });
                if (u) {
                    for (var p = -1, r = 0; r < f.data.length; r++) f.data[r] === e && (p = r); -
                    1 !== p && f.data.splice(p, 1)
                }
            }
            m();
            g.updateImage(n)
        }

        var n = a.element;
        c(n).on("CornerstoneToolsTouchDrag", k);
        c(n).on("CornerstoneToolsDragEnd", l);
        c(n).on("CornerstoneToolsTap", l);
        return !0
    }
})($, cornerstone, cornerstoneMath, cornerstoneTools);
(function(c, g, d) {
    d.touchMoveHandle = function(a, b, e) {
        function f(a, e) {
            b.active = !0;
            b.x = e.currentPoints.image.x;
            b.y = e.currentPoints.image.y;
            g.updateImage(m)
        }

        function d() {
            b.active = !1;
            c(m).off("CornerstoneToolsTouchDrag", f);
            c(m).off("CornerstoneToolsDragEnd", d);
            c(m).off("CornerstoneToolsTap", d);
            g.updateImage(m);
            e()
        }

        var m = a.element;
        c(m).on("CornerstoneToolsTouchDrag", f);
        c(m).on("CornerstoneToolsDragEnd", d);
        c(m).on("CornerstoneToolsTap", d)
    }
})($, cornerstone, cornerstoneTools);
(function(c, g) {
    g.LineSampleMeasurement = function() {
        var d = this;
        d.samples = [];
        this.set = function(a) {
            d.samples = a;
            c(d).trigger("CornerstoneLineSampleUpdated")
        }
    }
})($, cornerstoneTools);
(function(c, g) {
    g.MeasurementManager = new function() {
        var d = this;
        d.measurements = [];
        this.add = function(a) {
            a = { index: d.measurements.push(a), measurement: a };
            c(d).trigger("CornerstoneMeasurementAdded", a)
        };
        this.remove = function(a) {
            var b = d.measurements[a];
            d.measurements.splice(a, 1);
            a = { index: a, measurement: b };
            c(d).trigger("CornerstoneMeasurementRemoved", a)
        }
    }
})($, cornerstoneTools);
(function(c, g, d) {
    var a = [];
    d.metaData = {
        addProvider: function(b) {
            a.push(b)
        },
        removeProvider: function(b) {
            b = a.indexOf(b); -
            1 !== b && a.splice(b, 1)
        },
        get: function(b, e) {
            var f;
            c.each(a, function(a, d) {
                f = d(b, e);
                if (void 0 !== f) return !0
            });
            return f
        }
    }
})($, cornerstone, cornerstoneTools);
(function(c, g) {
    g.orientation.getOrientationString = function(d) {
        var a = "",
            b = 0 > d.x ? "R" : "L",
            e = 0 > d.y ? "A" : "P",
            f = 0 > d.z ? "F" : "H";
        d = new c.Vector3(Math.abs(d.x), Math.abs(d.y), Math.abs(d.z));
        for (var g = 0; 3 > g; g++)
            if (1E-4 < d.x && d.x > d.y && d.x > d.z) a += b, d.x = 0;
            else if (1E-4 < d.y && d.y > d.x && d.y > d.z) a += e, d.y = 0;
        else if (1E-4 < d.z && d.z > d.x && d.z > d.y) a += f, d.z = 0;
        else break;
        return a
    }
})(cornerstoneMath, cornerstoneTools);
(function(c) {
    c.orientation.invertOrientationString = function(c) {
        c = c.replace("H", "f");
        c = c.replace("F", "h");
        c = c.replace("R", "l");
        c = c.replace("L", "r");
        c = c.replace("A", "p");
        c = c.replace("P", "a");
        return c = c.toUpperCase()
    }
})(cornerstoneTools);
(function(c) {
    c.referenceLines.calculateReferenceLine = function(g, d) {
        var a = c.planePlaneIntersection(g, d);
        if (a) return {
            start: c.projectPatientPointToImagePlane(a.start, g),
            end: c.projectPatientPointToImagePlane(a.end, g)
        }
    }
})(cornerstoneTools);
(function(c, g, d) {
    function a(a, e) {
        var f = d.getToolState(a.currentTarget, "referenceLines");
        if (void 0 !== f) {
            var h = f.data[0].synchronizationContext.getSourceElements(),
                m = f.data[0].renderer,
                k = e.canvasContext.canvas.getContext("2d");
            g.setToPixelCoordinateSystem(e.enabledElement, k);
            c.each(h, function(f, d) {
                d !== a.currentTarget && m(k, e, a.currentTarget, d)
            })
        }
    }

    d.referenceLines.tool = {
        enable: function(b, e, f) {
            f = f || d.referenceLines.renderActiveReferenceLine;
            d.addToolState(b, "referenceLines", { synchronizationContext: e, renderer: f });
            c(b).on("CornerstoneImageRendered", a);
            g.updateImage(b)
        },
        disable: function(b) {
            c(b).off("CornerstoneImageRendered", a);
            g.updateImage(b)
        }
    }
})($, cornerstone, cornerstoneTools);
(function(c, g, d) {
    d.referenceLines.renderActiveReferenceLine = function(a, b, e, f) {
        e = g.getEnabledElement(e).image;
        var c = g.getEnabledElement(f).image;
        if (e && c && (f = d.metaData.get("imagePlane", e.imageId), e = d.metaData.get("imagePlane", c.imageId), f.frameOfReferenceUID === e.frameOfReferenceUID)) {
            var c = f.rowCosines.clone().cross(f.columnCosines),
                m = e.rowCosines.clone().cross(e.columnCosines),
                c = c.angleTo(m),
                c = Math.abs(c);
            .5 > c || !(e = d.referenceLines.calculateReferenceLine(f, e)) || (f = g.pixelToCanvas(b.element, e.start), b = g.pixelToCanvas(b.element, e.end), e = d.toolColors.getActiveColor(), c = d.toolStyle.getToolWidth(), a.setTransform(1, 0, 0, 1, 0, 0), a.save(), a.beginPath(), a.strokeStyle = e, a.lineWidth = c, a.moveTo(f.x, f.y), a.lineTo(b.x, b.y), a.stroke(), a.restore())
        }
    }
})($, cornerstone, cornerstoneTools);
(function(c, g) {
    var d = { interaction: [], thumbnail: [], prefetch: [] },
        a = {
            interaction: 0,
            thumbnail: 0,
            prefetch: 0
        },
        b = 6,
        e = 6,
        f = 5,
        h = !1;
    g.requestPoolManager = function() {
        function m() {
            h && setTimeout(function() {
                var a = l();
                a ? k(a) : h = !1
            }, 20)
        }

        function k(b) {
            var e = b.type;
            a[e]++;
            h = !0;
            var f = b.imageId,
                d = b.doneCallback,
                g = b.failCallback;
            (b = c.imageCache.getImagePromise(f)) ? b.then(function(b) {
                a[e]--;
                d(b);
                m()
            }, function(b) {
                a[e]--;
                g(b)
            }): c.loadAndCacheImage(f).then(function(b) {
                a[e]--;
                d(b);
                m()
            }, function(b) {
                a[e]--;
                g(b)
            })
        }

        function l() {
            if (d.interaction.length && a.interaction < b) return d.interaction.shift();
            if (d.thumbnail.length && a.thumbnail < e) return d.thumbnail.shift();
            if (d.prefetch.length && a.prefetch < f) return d.prefetch.shift();
            d.interaction.length || d.thumbnail.length || d.prefetch.length || (h = !1);
            return !1
        }

        return {
            addRequest: function(a, b, e, f, g) {
                if (!d.hasOwnProperty(e)) throw "Request type must be one of interaction, thumbnail, or prefetch";
                a && b && (a = {
                    type: e,
                    imageId: b,
                    doneCallback: f,
                    failCallback: g
                }, (b = c.imageCache.getImagePromise(b)) ? b.then(function(a) {
                    f(a)
                }, function(a) {
                    g(a)
                }) : d[e].push(a))
            },
            clearRequestStack: function(a) {
                if (!d.hasOwnProperty(a)) throw "Request type must be one of interaction, thumbnail, or prefetch";
                d[a] = []
            },
            startGrabbing: function() {
                if (!h) {
                    var a = g.getMaxSimultaneousRequests();
                    b = a;
                    e = a - 2;
                    f = a - 1;
                    for (var d = 0; d < a; d++) {
                        var c = l();
                        c && k(c)
                    }
                }
            },
            getRequestPool: function() {
                return d
            }
        }
    }()
})(cornerstone, cornerstoneTools);
(function(c, g, d) {
    d.playClip = function(a, b) {
        if (void 0 === a) throw "playClip: element must not be undefined";
        if (void 0 === b) {
            b = 2;
        }

        //gaolixin
        if (b > 10) {
            b = 10;
        }
        // b = 2;
        var e = d.getToolState(a, "stack");
        if (void 0 !== e && void 0 !== e.data && 0 !== e.data.length) {
            var f = e.data[0],
                e = d.getToolState(a, "playClip"),
                h;
            void 0 === e || 0 === e.data.length ? (h = {
                intervalId: void 0,
                framesPerSecond: b,
                lastFrameTimeStamp: void 0,
                frameRate: 0,
                loop: !0
            }, d.addToolState(a, "playClip", h)) : (h = e.data[0], h.framesPerSecond = b);
            void 0 === h.intervalId && (h.intervalId = setInterval(function() {
                var b = f.currentImageIdIndex;
                0 < h.framesPerSecond ? b++ : b--;
                if (!h.loop && (b >= f.imageIds.length || 0 > b)) {
                    var e = { element: a },
                        l = c.Event("CornerstoneToolsClipStopped", e);
                    c(a).trigger(l, e);
                    clearInterval(h.intervalId);
                    h.intervalId = void 0
                } else if (b >= f.imageIds.length && (b = 0), 0 > b && (b = f.imageIds.length - 1), b !== f.currentImageIdIndex) {
                    var e = d.loadHandlerManager.getStartLoadHandler(),
                        n = d.loadHandlerManager.getEndLoadHandler(),
                        q = d.loadHandlerManager.getErrorLoadingHandler();
                    e && e(a);
                    var w = g.getViewport(a);
                    g.loadAndCacheImage(f.imageIds[b]).then(function(e) {
                        f.currentImageIdIndex = b;
                        g.displayImage(a, e, w);
                        n && n(a)
                    }, function(e) {
                        var d = f.imageIds[b];
                        q && q(a, d, e)
                    })
                }
            }, 1E3 / Math.abs(h.framesPerSecond)))
        }
    };
    d.stopClip = function(a) {
        a = d.getToolState(a, "playClip");
        void 0 !== a && 0 !== a.data.length && (a = a.data[0], clearInterval(a.intervalId), a.intervalId = void 0)
    }
})($, cornerstone, cornerstoneTools);
(function(c, g, d) {
    d.scrollIndicator = d.displayTool(function(a, b) {
        var e = b.element,
            f = b.enabledElement.canvas.width,
            c = b.enabledElement.canvas.height;
        if (!f || !c) return !1;
        var g = b.enabledElement.canvas.getContext("2d");
        g.setTransform(1, 0, 0, 1, 0, 0);
        g.save();
        var k = d.scrollIndicator.getConfiguration();
        g.fillStyle = k.backgroundColor;
        g.fillRect(0, c - 6, f, 6);
        (e = d.getToolState(e, "stack")) && e.data && e.data.length && (f /= e.data[0].imageIds.length, e = f * e.data[0].currentImageIdIndex, g.fillStyle = k.fillColor, g.fillRect(e, c - 6, f, 6), g.restore())
    });
    d.scrollIndicator.setConfiguration({ backgroundColor: "rgb(19, 63, 141)", fillColor: "white" })
})($, cornerstone, cornerstoneTools);
(function(c, g, d) {
    function a(a, b) {
        return a - b
    }

    function b(a, b) {
        var e = [],
            f = [];
        a.forEach(function(a) {
            a < b ? e.push(a) : a > b && f.push(a)
        });
        return { low: a.indexOf(Math.max.apply(null, e)), high: a.indexOf(Math.min.apply(null, f)) }
    }

    function e(e) {
        function f(a) {
            a = m.indicesToRequest.indexOf(a); -
            1 < a && m.indicesToRequest.splice(a, 1)
        }

        function c(a) {
            a = l.imageIds.indexOf(a.imageId);
            f(a)
        }

        function h(a) {
            console.log("prefetch errored: " + a)
        }

        var k = d.getToolState(e, "stack");
        if (k && k.data && k.data.length) {
            var l = k.data[0];
            if (k = d.getToolState(e, "stackPrefetch")) {
                var m = k.data[0];
                m && m.indicesToRequest && m.indicesToRequest.length || (m.enabled = !1);
                if (!1 !== m.enabled && (k.data[0].indicesToRequest.sort(a), m.indicesToRequest.slice().forEach(function(a) {
                        var b = l.imageIds[a];
                        b && (b = g.imageCache.getImagePromise(b)) && "resolved" === b.state() && f(a)
                    }), m.indicesToRequest.length)) {
                    k = d.requestPoolManager;
                    k.clearRequestStack("prefetch");
                    for (var n = b(m.indicesToRequest, l.currentImageIdIndex), y, A = n.low, n = n.high; 0 < A || n < m.indicesToRequest.length;) 0 <= A && (y = m.indicesToRequest[A--], y = l.imageIds[y], k.addRequest(e, y, "prefetch", c, h)), n < m.indicesToRequest.length && (y = m.indicesToRequest[n++], y = l.imageIds[y], k.addRequest(e, y, "prefetch", c, h));
                    k.startGrabbing()
                }
            }
        }
    }

    function f(a) {
        (a = d.getToolState(a.data.element, "stackPrefetch")) && a.data && a.data.length && d.requestPoolManager.clearRequestStack("prefetch")
    }

    function h(a, b) {
        var e = a.data.element,
            f = d.getToolState(e, "stack");
        f && f.data && f.data.length && (f = f.data[0].imageIds.indexOf(b.imageId), 0 > f || (e = d.getToolState(e, "stackPrefetch")) && e.data && e.data.length && e.data[0].indicesToRequest.push(f))
    }

    function m(a) {
        clearTimeout(l);
        l = setTimeout(function() {
            e(a.currentTarget)
        }, n)
    }

    var k = {},
        l, n;
    d.stackPrefetch = {
        enable: function(a) {
            d.getToolState(a, "stackPrefetch").data = [];
            var b = d.getToolState(a, "stack");
            if (b && b.data && b.data.length) {
                b = b.data[0];
                var k, l = b.imageIds.length - 1;
                k = Math.round(0) || 0;
                var l = Math.round(l) || 0,
                    n = [];
                k = l - k + 1;
                if (!(0 >= k))
                    for (; k--;) n[k] = l--;
                l = { indicesToRequest: n, enabled: !0, direction: 1 };
                b = l.indicesToRequest.indexOf(b.currentImageIdIndex);
                l.indicesToRequest.splice(b, 1);
                d.addToolState(a, "stackPrefetch", l);
                e(a);
                c(a).off("CornerstoneNewImage", m);
                c(a).on("CornerstoneNewImage", m);
                c(g).off("CornerstoneImageCacheFull", f);
                c(g).on("CornerstoneImageCacheFull", { element: a }, f);
                c(g).off("CornerstoneImageCachePromiseRemoved", h);
                c(g).on("CornerstoneImageCachePromiseRemoved", { element: a }, h)
            }
        },
        disable: function(a) {
            c(a).off("CornerstoneNewImage", m);
            c(g).off("CornerstoneImageCacheFull", f);
            c(g).off("CornerstoneImageCachePromiseRemoved", h);
            (a = d.getToolState(a, "stackPrefetch")) && a.data.length && (a.data[0].enabled = !1, d.requestPoolManager.clearRequestStack("prefetch"))
        },
        getConfiguration: function() {
            return k
        },
        setConfiguration: function(a) {
            k = a
        }
    }
})($, cornerstone, cornerstoneTools);
(function(c, g, d) {
    function a(e, f) {
        c(f.element).off("CornerstoneToolsMouseDrag", b);
        c(f.element).off("CornerstoneToolsMouseUp", a)
    }

    function b(a, b) {
        a.data.deltaY += b.deltaPoints.page.y;
        var g = b.element,
            m = d.getToolState(b.element, "stack");
        if (void 0 !== m && void 0 !== m.data && 0 !== m.data.length) {
            var m = m.data[0],
                k = c(b.element).height() / Math.max(m.imageIds.length, 8);
            void 0 !== a.data.options && void 0 !== a.data.options.stackScrollSpeed && (k = a.data.options.stackScrollSpeed);
            if (a.data.deltaY >= k || a.data.deltaY <= -k) m = a.data.deltaY % k, k = Math.round(a.data.deltaY / k), a.data.deltaY = m, d.scroll(g, k);
            return !1
        }
    }

    d.stackScroll = d.simpleMouseButtonTool(function(e, f) {
        if (d.isMouseButtonEnabled(f.which, e.data.mouseButtonMask)) {
            var g = { deltaY: 0, options: e.data.options };
            c(f.element).on("CornerstoneToolsMouseDrag", g, b);
            c(f.element).on("CornerstoneToolsMouseUp", a);
            e.stopImmediatePropagation();
            return !1
        }
    });
    d.stackScrollWheel = d.mouseWheelTool(function(a, b) {
        d.scroll(b.element, -b.direction)
    });
    d.stackScrollTouchDrag = d.touchDragTool(function(a, b) {
        var c = b.element;
        b.deltaY = b.deltaY || 0;
        b.deltaY += b.deltaPoints.page.y;
        var g = d.getToolState(b.element, "stack");
        if (void 0 !== g && void 0 !== g.data && 0 !== g.data.length) {
            if (3 <= b.deltaY || -3 >= b.deltaY) {
                var g = b.deltaY % 3,
                    k = Math.round(b.deltaY / 3);
                b.deltaY = g;
                d.scroll(c, k)
            }
            return !1
        }
    })
})($, cornerstone, cornerstoneTools);
(function(c) {
    c.stackScrollKeyboard = c.keyboardTool(function(g, d) {
        var a = d.keyCode;
        if (38 === a || 40 === a) {
            var b = 1;
            40 === a && (b = -1);
            c.scroll(d.element, b)
        }
    })
})(cornerstoneTools);
(function(c, g, d) {
    d.appState = {
        save: function(a) {
            var b = {
                imageIdToolState: d.globalImageIdSpecificToolStateManager.saveToolState(),
                elementToolState: {},
                elementViewport: {}
            };
            a.forEach(function(a) {
                var f = d.getElementToolStateManager(a);
                f !== d.globalImageIdSpecificToolStateManager && (b.elementToolState[a.id] = f.saveToolState(), b.elementViewport[a.id] = g.getViewport(a))
            });
            return b
        },
        restore: function(a) {
            if (a.hasOwnProperty("imageIdToolState") && a.hasOwnProperty("elementToolState") && a.hasOwnProperty("elementViewport")) return d.globalImageIdSpecificToolStateManager.restoreToolState(a.imageIdToolState), Object.keys(a.elementViewport).forEach(function(b) {
                var e = document.getElementById(b);
                if (e && a.elementToolState.hasOwnProperty(b)) {
                    var f = d.getElementToolStateManager(e);
                    f !== d.globalImageIdSpecificToolStateManager && (f.restoreToolState(a.elementToolState[b]), g.setViewport(e, a.elementViewport[b]), g.updateImage(e))
                }
            }), a
        }
    }
})($, cornerstone, cornerstoneTools);
(function(c, g, d) {
    function a() {
        var a = {};
        return {
            get: function(e, f) {
                if (!1 !== a.hasOwnProperty(e)) {
                    var d = a[e];
                    if (!1 !== d.hasOwnProperty(f)) return d[f]
                }
            },
            add: function(e, f, d) {
                !1 === a.hasOwnProperty(e) && (a[e] = {});
                e = a[e];
                !1 === e.hasOwnProperty(f) && (e[f] = { data: [] });
                e[f].data.push(d)
            },
            remove: function(e, f, d) {
                if (!1 !== a.hasOwnProperty(e) && (e = a[e], !1 !== e.hasOwnProperty(f))) {
                    f = e[f];
                    e = -1;
                    for (var c = 0; c < f.data.length; c++) f.data[c] === d && (e = c); -
                    1 !== e && f.data.splice(e, 1)
                }
            }
        }
    }

    c = a();
    d.newFrameOfReferenceSpecificToolStateManager = a;
    d.globalFrameOfReferenceSpecificToolStateManager = c
})($, cornerstone, cornerstoneTools);
(function(c, g) {
    function d() {
        var a = {};
        return {
            get: function(e, d) {
                var g = c.getEnabledElement(e);
                if (g.image && !1 !== a.hasOwnProperty(g.image.imageId) && (g = a[g.image.imageId], !1 !== g.hasOwnProperty(d))) return g[d]
            },
            add: function(e, d, g) {
                e = c.getEnabledElement(e);
                e.image && !1 !== a.hasOwnProperty(e.image.imageId) || (a[e.image.imageId] = {});
                e = a[e.image.imageId];
                !1 === e.hasOwnProperty(d) && (e[d] = { data: [] });
                e[d].data.push(g)
            },
            clear: function(e) {
                e = c.getEnabledElement(e);
                e.image && !1 !== a.hasOwnProperty(e.image.imageId) && delete a[e.image.imageId]
            },
            saveImageIdToolState: function(e) {
                return a[e]
            },
            restoreImageIdToolState: function(e, d) {
                a[e] = d
            },
            saveToolState: function() {
                return a
            },
            restoreToolState: function(e) {
                a = e
            },
            toolState: a
        }
    }

    var a = d();
    g.newImageIdSpecificToolStateManager = d;
    g.globalImageIdSpecificToolStateManager = a
})(cornerstone, cornerstoneTools);
(function(c) {
    c.loadHandlerManager = function() {
        var c, d, a;
        return {
            setStartLoadHandler: function(a) {
                c = a
            },
            getStartLoadHandler: function() {
                return c
            },
            setEndLoadHandler: function(a) {
                d = a
            },
            getEndLoadHandler: function() {
                return d
            },
            setErrorLoadingHandler: function(b) {
                a = b
            },
            getErrorLoadingHandler: function() {
                return a
            }
        }
    }()
})(cornerstoneTools);
(function(c, g) {
    var d = [];
    g.newStackSpecificToolStateManager = function(a, b) {
        var e = {};
        return {
            get: function(d, c) {
                return 0 <= a.indexOf(c) ? (!1 === e.hasOwnProperty(c) && (e[c] = { data: [] }), e[c]) : b.get(d, c)
            },
            add: function(d, c, g) {
                if (0 <= a.indexOf(c)) !1 === e.hasOwnProperty(c) && (e[c] = { data: [] }), e[c].data.push(g);
                else return b.add(d, c, g)
            },
            saveToolState: function() {
                return e
            },
            restoreToolState: function(a) {
                e = a
            },
            toolState: e
        }
    };
    g.addStackStateManager = function(a) {
        var b = g.getElementToolStateManager(a);
        b || (b = g.globalImageIdSpecificToolStateManager);
        b = g.newStackSpecificToolStateManager("stack stackPrefetch playClip volume slab referenceLines crosshairs".split(" "), b);
        d.push(b);
        g.setElementToolStateManager(a, b)
    }
})(cornerstone, cornerstoneTools);
(function(c) {
    c.textStyle = function() {
        var c = 15,
            d = c + "px Arial",
            a = "transparent";
        return {
            setFont: function(a) {
                d = a
            },
            getFont: function() {
                return d
            },
            setFontSize: function(a) {
                c = a
            },
            getFontSize: function() {
                return c
            },
            setBackgroundColor: function(b) {
                a = b
            },
            getBackgroundColor: function() {
                return a
            }
        }
    }()
})(cornerstoneTools);
(function(c, g) {
    var d = [];
    g.newTimeSeriesSpecificToolStateManager = function(a, b) {
        var e = {};
        return {
            get: function(d, c) {
                return 0 <= a.indexOf(c) ? (!1 === e.hasOwnProperty(c) && (e[c] = { data: [] }), e[c]) : b.get(d, c)
            },
            add: function(d, c, g) {
                if (0 <= a.indexOf(c)) !1 === e.hasOwnProperty(c) && (e[c] = { data: [] }), e[c].data.push(g);
                else return b.add(d, c, g)
            }
        }
    };
    g.addTimeSeriesStateManager = function(a, b) {
        b = b || ["timeSeries"];
        var e = g.getElementToolStateManager(a);
        void 0 === e && (e = g.globalImageIdSpecificToolStateManager);
        e = g.newTimeSeriesSpecificToolStateManager(b, e);
        d.push(e);
        g.setElementToolStateManager(a, e)
    }
})(cornerstone, cornerstoneTools);
(function(c) {
    c.toolColors = function() {
        var c = "yellow",
            d = "greenyellow",
            a = "transparent";
        return {
            setFillColor: function(b) {
                a = b
            },
            getFillColor: function() {
                return a
            },
            setToolColor: function(a) {
                c = a
            },
            getToolColor: function() {
                return c
            },
            setActiveColor: function(a) {
                d = a
            },
            getActiveColor: function() {
                return d
            }
        }
    }()
})(cornerstoneTools);
(function(c) {
    c.toolCoordinates = function() {
        var c = "";
        return {
            setCoords: function(d) {
                c = d.currentPoints.canvas
            },
            getCoords: function() {
                return c
            }
        }
    }()
})(cornerstoneTools);
(function(c, g) {
    function d(a) {
        a = c.getEnabledElement(a);
        void 0 === a.toolStateManager && (a.toolStateManager = g.globalImageIdSpecificToolStateManager);
        return a.toolStateManager
    }

    g.addToolState = function(a, b, e) {
        d(a).add(a, b, e)
    };
    g.getToolState = function(a, b) {
        return d(a).get(a, b)
    };
    g.removeToolState = function(a, b, e) {
        a = d(a).get(a, b);
        b = -1;
        for (var c = 0; c < a.data.length; c++) a.data[c] === e && (b = c); -
        1 !== b && a.data.splice(b, 1)
    };
    g.clearToolState = function(a, b) {
        var e = d(a).get(a, b);
        void 0 !== e && (e.data = [])
    };
    g.setElementToolStateManager = function(a, b) {
        c.getEnabledElement(a).toolStateManager = b
    };
    g.getElementToolStateManager = d
})(cornerstone, cornerstoneTools);
(function(c) {
    c.toolStyle = function() {
        var c = 1,
            d = 2;
        return {
            setToolWidth: function(a) {
                c = a
            },
            getToolWidth: function() {
                return c
            },
            setActiveWidth: function(a) {
                d = a
            },
            getActiveWidth: function() {
                return d
            }
        }
    }()
})(cornerstoneTools);
(function(c, g, d) {
    d.panZoomSynchronizer = function(a, b, e) {
        if (e !== b) {
            b = g.getViewport(b);
            var d = g.getViewport(e);
            if (d.scale !== b.scale || d.translation.x !== b.translation.x || d.translation.y !== b.translation.y) d.scale = b.scale, d.translation.x = b.translation.x, d.translation.y = b.translation.y, a.setViewport(e, d)
        }
    }
})($, cornerstone, cornerstoneTools);
(function(c, g, d) {
    d.stackImageIndexSynchronizer = function(a, b, e) {
        if (e !== b) {
            b = d.getToolState(b, "stack").data[0];
            var c = d.getToolState(e, "stack").data[0],
                h = b.currentImageIdIndex,
                h = Math.min(Math.max(h, 0), c.imageIds.length - 1);
            if (h !== c.currentImageIdIndex) {
                b = d.loadHandlerManager.getStartLoadHandler();
                var m = d.loadHandlerManager.getEndLoadHandler(),
                    k = d.loadHandlerManager.getErrorLoadingHandler();
                b && b(e);
                g.loadAndCacheImage(c.imageIds[h]).then(function(b) {
                    var d = g.getViewport(e);
                    c.currentImageIdIndex = h;
                    a.displayImage(e, b, d);
                    m && m(e)
                }, function(a) {
                    var b = c.imageIds[h];
                    k && k(e, b, a)
                })
            }
        }
    }
})($, cornerstone, cornerstoneTools);
(function(c, g, d) {
    d.stackImagePositionOffsetSynchronizer = function(a, b, e, c, h) {
        if (e !== b) {
            b = g.getEnabledElement(b);
            b = d.metaData.get("imagePlane", b.image.imageId).imagePositionPatient;
            var m = d.getToolState(e, "stack").data[0],
                k = Number.MAX_VALUE,
                l = -1;
            if (h) {
                var n = b.clone().add(h);
                m.imageIds.forEach(function(a, b) {
                    var e = d.metaData.get("imagePlane", a).imagePositionPatient,
                        e = n.distanceToSquared(e);
                    e < k && (k = e, l = b)
                });
                if (l !== m.currentImageIdIndex && -1 !== l) {
                    h = d.loadHandlerManager.getStartLoadHandler();
                    var q = d.loadHandlerManager.getEndLoadHandler(),
                        w = d.loadHandlerManager.getErrorLoadingHandler();
                    h && h(e);
                    g.loadAndCacheImage(m.imageIds[l]).then(function(b) {
                        var d = g.getViewport(e);
                        m.currentImageIdIndex = l;
                        a.displayImage(e, b, d);
                        q && q(e)
                    }, function(a) {
                        var b = m.imageIds[l];
                        w && w(e, b, a)
                    })
                }
            }
        }
    }
})($, cornerstone, cornerstoneTools);
(function(c, g, d) {
    d.stackImagePositionSynchronizer = function(a, b, e) {
        if (e !== b) {
            b = g.getEnabledElement(b).image;
            var f = d.metaData.get("imagePlane", b.imageId).imagePositionPatient,
                h = d.getToolState(e, "stack").data[0],
                m = Number.MAX_VALUE,
                k = -1;
            c.each(h.imageIds, function(a, b) {
                var e = d.metaData.get("imagePlane", b).imagePositionPatient.distanceToSquared(f);
                e < m && (m = e, k = a)
            });
            if (k !== h.currentImageIdIndex) {
                b = d.loadHandlerManager.getStartLoadHandler();
                var l = d.loadHandlerManager.getEndLoadHandler(),
                    n = d.loadHandlerManager.getErrorLoadingHandler();
                b && b(e); -
                1 !== k && g.loadAndCacheImage(h.imageIds[k]).then(function(b) {
                    var d = g.getViewport(e);
                    h.currentImageIdIndex = k;
                    a.displayImage(e, b, d);
                    l && l(e)
                }, function(a) {
                    var b = h.imageIds[k];
                    n && n(e, b, a)
                })
            }
        }
    }
})($, cornerstone, cornerstoneTools);
(function(c, g, d) {
    d.stackScrollSynchronizer = function(a, b, e, c) {
        if (b !== e && c && c.direction) {
            var h = d.getToolState(e, "stack").data[0],
                m = h.currentImageIdIndex + c.direction,
                m = Math.min(Math.max(m, 0), h.imageIds.length - 1);
            if (h.currentImageIdIndex !== m) {
                b = d.loadHandlerManager.getStartLoadHandler();
                var k = d.loadHandlerManager.getEndLoadHandler(),
                    l = d.loadHandlerManager.getErrorLoadingHandler();
                b && b(e);
                g.loadAndCacheImage(h.imageIds[m]).then(function(b) {
                    var d = g.getViewport(e);
                    h.currentImageIdIndex = m;
                    a.displayImage(e, b, d);
                    k && k(e)
                }, function(a) {
                    var b = h.imageIds[m];
                    l && l(e, b, a)
                })
            }
        }
    }
})($, cornerstone, cornerstoneTools);
(function(c, g, d) {
    d.Synchronizer = function(a, b) {
        function e(a, b) {
            k.length && l.length && (n = !0, l.forEach(function(e) {
                var d = l.indexOf(e);
                if (-1 !== d) {
                    var d = q.imageIds.targetElements[d],
                        c = k.indexOf(a); -
                    1 !== c && (c = q.imageIds.sourceElements[c], w(m, a, e, b, c === d ? 0 : q.distances[c][d]))
                }
            }), n = !1)
        }

        function f(a, b) {
            !0 !== n && e(a.currentTarget, b)
        }

        function h(a, b) {
            m.remove(b.element)
        }

        var m = this,
            k = [],
            l = [],
            n = !1,
            q = {},
            w = b;
        this.setHandler = function(a) {
            w = a
        };
        this.getHandler = function() {
            return w
        };
        this.getDistances = function() {
            k.length && l.length && (q.distances = {}, q.imageIds = {
                sourceElements: [],
                targetElements: []
            }, k.forEach(function(a) {
                var b = g.getEnabledElement(a),
                    e = b.image.imageId,
                    c = d.metaData.get("imagePlane", e).imagePositionPatient;
                q.hasOwnProperty(b) || (q.distances[e] = {}, q.imageIds.sourceElements.push(e), l.forEach(function(b) {
                    var f = g.getEnabledElement(b).image.imageId;
                    q.imageIds.targetElements.push(f);
                    a === b || e === f || q.distances[e].hasOwnProperty(f) || (b = d.metaData.get("imagePlane", f).imagePositionPatient, q.distances[e][f] = b.clone().sub(c))
                }), Object.keys(q.distances[e]).length || delete q.distances[e])
            }))
        };
        this.addSource = function(b) {
            -1 === k.indexOf(b) && (k.push(b), c(b).on(a, f), m.getDistances(), m.updateDisableHandlers())
        };
        this.addTarget = function(a) {
            -1 === l.indexOf(a) && (l.push(a), m.getDistances(), w(m, a, a, 0), m.updateDisableHandlers())
        };
        this.add = function(a) {
            m.addSource(a);
            m.addTarget(a)
        };
        this.removeSource = function(b) {
            var d = k.indexOf(b); -
            1 !== d && (k.splice(d, 1), c(b).off(a, f), m.getDistances(), e(b), m.updateDisableHandlers())
        };
        this.removeTarget = function(a) {
            var b = l.indexOf(a); -
            1 !== b && (l.splice(b, 1), m.getDistances(), w(m, a, a, 0), m.updateDisableHandlers())
        };
        this.remove = function(a) {
            m.removeTarget(a);
            m.removeSource(a)
        };
        this.getSourceElements = function() {
            return k
        };
        this.getTargetElements = function() {
            return l
        };
        this.displayImage = function(a, b, e) {
            n = !0;
            g.displayImage(a, b, e);
            n = !1
        };
        this.setViewport = function(a, b) {
            n = !0;
            g.setViewport(a, b);
            n = !1
        };
        this.updateDisableHandlers = function() {
            c.unique(k.concat(l)).forEach(function(a) {
                c(a).off("CornerstoneElementDisabled", h);
                c(a).on("CornerstoneElementDisabled", h)
            })
        };
        this.destroy = function() {
            c.unique(k.concat(l)).forEach(function(a) {
                m.remove(a)
            })
        }
    }
})($, cornerstone, cornerstoneTools);
(function(c, g, d) {
    d.updateImageSynchronizer = function(a, b, e) {
        e !== b && g.updateImage(e)
    }
})($, cornerstone, cornerstoneTools);
(function(c, g, d) {
    d.wwwcSynchronizer = function(a, b, e) {
        if (e !== b) {
            b = g.getViewport(b);
            var d = g.getViewport(e);
            if (d.voi.windowWidth !== b.voi.windowWidth || d.voi.windowCenter !== b.voi.windowCenter || d.invert !== b.invert) d.voi.windowWidth = b.voi.windowWidth, d.voi.windowCenter = b.voi.windowCenter, d.invert = b.invert, a.setViewport(e, d)
        }
    }
})($, cornerstone, cornerstoneTools);
(function(c, g, d) {
    function a(a) {
        var e = [];
        a.timeSeries.stacks.forEach(function(d) {
            g.loadAndCacheImage(d.imageIds[a.imageIdIndex]).then(function(d) {
                var c = Math.round(a.handles.end.x) + Math.round(a.handles.end.y) * d.width;
                d = d.getPixelData()[c];
                e.push(d)
            })
        });
        a.lineSample.set(e)
    }

    d.probeTool4D = d.mouseButtonTool({
        createNewMeasurement: function(b) {
            var e = d.getToolState(b.element, "timeSeries");
            if (void 0 !== e && void 0 !== e.data && 0 !== e.data.length) return e = e.data[0], b = {
                timeSeries: e,
                lineSample: new d.LineSampleMeasurement,
                imageIdIndex: e.stacks[e.currentStackIndex].currentImageIdIndex,
                visible: !0,
                handles: { end: { x: b.currentPoints.image.x, y: b.currentPoints.image.y, highlight: !0, active: !0 } }
            }, a(b), d.MeasurementManager.add(b), b
        },
        onImageRendered: function(a, e) {
            var c = d.getToolState(a.currentTarget, "probe4D");
            if (void 0 !== c) {
                var h = e.canvasContext.canvas.getContext("2d");
                g.setToPixelCoordinateSystem(e.enabledElement, h);
                for (var m = 0; m < c.data.length; m++) {
                    h.save();
                    var k = c.data[m];
                    h.beginPath();
                    d.drawHandles(h, e, k.handles, "white");
                    h.stroke();
                    var l = d.setContextToDisplayFontSize(e.enabledElement, e.canvasContext, 15);
                    h.font = "" + l.fontSize + "px Arial";
                    var l = Math.round(k.handles.end.x),
                        n = Math.round(k.handles.end.y),
                        q = k.handles.end.x + 3,
                        k = k.handles.end.y - 3;
                    h.fillStyle = "white";
                    h.fillText("" + l + "," + n, q, k);
                    h.restore()
                }
            }
        },
        toolType: "probe4D"
    })
})($, cornerstone, cornerstoneTools);
(function(c, g, d) {
    d.incrementTimePoint = function(a, b, e) {
        var c = d.getToolState(a, "timeSeries");
        if (void 0 !== c && void 0 !== c.data && 0 !== c.data.length) {
            var h = c.data[0],
                m = h.stacks[h.currentStackIndex].currentImageIdIndex,
                k = h.currentStackIndex + b;
            e ? (k >= h.stacks.length && (k = 0), 0 > k && (k = h.stacks.length - 1)) : (k = Math.min(h.stacks.length - 1, k), k = Math.max(0, k));
            if (k !== h.currentStackIndex) {
                var l = g.getViewport(a),
                    n = h.stacks[k];
                b = d.loadHandlerManager.getStartLoadHandler();
                var q = d.loadHandlerManager.getEndLoadHandler(),
                    w = d.loadHandlerManager.getErrorLoadingHandler();
                b && b(a);
                g.loadAndCacheImage(n.imageIds[m]).then(function(b) {
                    h.currentImageIdIndex !== m && (n.currentImageIdIndex = m, h.currentStackIndex = k, g.displayImage(a, b, l), q && q(a))
                }, function(b) {
                    var e = n.imageIds[m];
                    w && w(a, e, b)
                })
            }
        }
    }
})($, cornerstone, cornerstoneTools);
(function(c, g, d) {
    d.timeSeriesPlayer = {
        start: function(a, b) {
            if (void 0 === a) throw "playClip: element must not be undefined";
            void 0 === b && (b = 30);
            var e = d.getToolState(a, "timeSeries");
            if (void 0 !== e && void 0 !== e.data && 0 !== e.data.length) {
                var e = d.getToolState(a, "timeSeriesPlayer"),
                    c;
                void 0 === e || 0 === e.data.length ? (c = {
                    intervalId: void 0,
                    framesPerSecond: b,
                    lastFrameTimeStamp: void 0,
                    frameRate: 0
                }, d.addToolState(a, "timeSeriesPlayer", c)) : (c = e.data[0], c.framesPerSecond = b);
                void 0 === c.intervalId && (c.intervalId = setInterval(function() {
                    0 < c.framesPerSecond ? d.incrementTimePoint(a, 1, !0) : d.incrementTimePoint(a, -1, !0)
                }, 1E3 / Math.abs(c.framesPerSecond)))
            }
        },
        stop: function(a) {
            a = d.getToolState(a, "timeSeriesPlayer");
            void 0 !== a && 0 !== a.data.length && (a = a.data[0], clearInterval(a.intervalId), a.intervalId = void 0)
        }
    }
})($, cornerstone, cornerstoneTools);
(function(c, g, d) {
    function a(e, d) {
        c(d.element).off("CornerstoneToolsMouseDrag", b);
        c(d.element).off("CornerstoneToolsMouseUp", a)
    }

    function b(a, b) {
        a.data.deltaY += b.deltaPoints.page.y;
        var g = d.getToolState(b.element, "timeSeries");
        if (void 0 !== g && void 0 !== g.data && 0 !== g.data.length) {
            var g = g.data[0],
                m = c(b.element).height() / g.stacks.length;
            void 0 !== a.data.options && void 0 !== a.data.options.timeSeriesScrollSpeed && (m = a.data.options.timeSeriesScrollSpeed);
            if (a.data.deltaY >= m || a.data.deltaY <= -m) g = Math.round(a.data.deltaY / m), m = a.data.deltaY % m, d.incrementTimePoint(b.element, g), a.data.deltaY = m;
            return !1
        }
    }

    d.timeSeriesScroll = d.simpleMouseButtonTool(function(e, f) {
        if (d.isMouseButtonEnabled(f.which, e.data.mouseButtonMask)) {
            var g = { deltaY: 0, options: e.data.options };
            c(f.element).on("CornerstoneToolsMouseDrag", g, b);
            c(f.element).on("CornerstoneToolsMouseUp", a);
            e.stopImmediatePropagation();
            return !1
        }
    });
    d.timeSeriesScrollWheel = d.mouseWheelTool(function(a, b) {
        d.incrementTimePoint(b.element, -b.direction)
    });
    d.timeSeriesScrollTouchDrag = d.touchDragTool(function(a) {
        var b = a.originalEvent.detail;
        a = { deltaY: 0 };
        a.deltaY += b.deltaPoints.page.y;
        b = d.getToolState(b.element, "stack");
        if (void 0 !== b && void 0 !== b.data && 0 !== b.data.length) {
            if (3 <= a.deltaY || -3 >= a.deltaY) b = a.deltaY % 3, d.setTimePoint(a.element, a.deltaY / 3), a.deltaY = b;
            return !1
        }
    })
})($, cornerstone, cornerstoneTools);
(function(c, g, d) {
    d.roundToDecimal = function(a, b) {
        var e = Math.pow(10, b);
        return Math.round(a * e) / e
    }
})($, cornerstone, cornerstoneTools);
(function(c) {
    c.calculateSUV = function(c, d) {
        if (void 0 !== c.data && "PT" === c.data.string("x00080060")) {
            var a = d * c.slope + c.intercept,
                b = c.data.floatString("x00101030");
            if (void 0 !== b) {
                var e = c.data.elements.x00540016;
                if (void 0 !== e) {
                    var e = e.items[0].dataSet,
                        f = e.time("x00181072"),
                        h = e.floatString("x00181074"),
                        e = e.floatString("x00181075"),
                        m = c.data.time("x00080032");
                    if (f && h && e && m) return f = h * Math.exp(-(m.fractionalSeconds + m.seconds + 60 * m.minutes + 3600 * m.hours - (f.fractionalSeconds + f.seconds + 60 * f.minutes + 3600 * f.hours)) * Math.log(2) / e), a * b / f * 1E3
                }
            }
        }
    }
})(cornerstoneTools);
(function(c, g, d, a) {
    a.copyPoints = function(a) {
        var e = d.point.copy(a.page),
            c = d.point.copy(a.image),
            g = d.point.copy(a.client);
        a = d.point.copy(a.canvas);
        return { page: e, image: c, client: g, canvas: a }
    }
})($, cornerstone, cornerstoneMath, cornerstoneTools);
(function(c) {
    c.drawEllipse = function(c, d, a, b, e) {
        var f = b / 2 * .5522848,
            h = e / 2 * .5522848,
            m = d + b,
            k = a + e;
        b = d + b / 2;
        e = a + e / 2;
        c.beginPath();
        c.moveTo(d, e);
        c.bezierCurveTo(d, e - h, b - f, a, b, a);
        c.bezierCurveTo(b + f, a, m, e - h, m, e);
        c.bezierCurveTo(m, e + h, b + f, k, b, k);
        c.bezierCurveTo(b - f, k, d, e + h, d, e);
        c.closePath();
        c.stroke()
    }
})(cornerstoneTools);
(function(c) {
    c.drawTextBox = function(g, d, a, b, e) {
        var f = c.textStyle.getFont(),
            h = c.textStyle.getFontSize(),
            m = c.textStyle.getBackgroundColor();
        g.save();
        g.font = f;
        f = g.measureText(d).width;
        g.textBaseline = "top";
        g.fillStyle = m;
        g.fillRect(a, b - h, f + 10, h + 10);
        g.fillStyle = e;
        g.fillText(d, a + 5, b - h + 5);
        g.restore()
    }
})(cornerstoneTools);
(function(c, g) {
    g.getLuminance = function(d, a, b, e, f) {
        if (!d) throw "getLuminance: parameter element must not be undefined";
        a = Math.round(a);
        b = Math.round(b);
        d = c.getEnabledElement(d);
        var g = [],
            m = 0,
            k = d.image.getPixelData(),
            l, n, q;
        if (d.image.color)
            for (n = 0; n < f; n++)
                for (q = 0; q < e; q++) {
                    l = 4 * ((n + b) * d.image.columns + (q + a));
                    var w = k[l],
                        p = k[l + 1];
                    l = k[l + 2];
                    g[m++] = .2126 * w + .7152 * p + .0722 * l
                } else
                    for (n = 0; n < f; n++)
                        for (q = 0; q < e; q++) l = (n + b) * d.image.columns + (q + a), g[m++] = k[l];
        return g
    }
})(cornerstone, cornerstoneTools);
(function(c, g) {
    function d() {
        var a = navigator.userAgent,
            e = a.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [],
            d;
        if (/trident/i.test(e[1])) return d = /\brv[ :]+(\d+)/g.exec(a) || [], "IE " + (d[1] || "");
        if ("Chrome" === e[1] && (d = a.match(/\b(OPR|Edge)\/(\d+)/), null !== d)) return d.slice(1).join(" ").replace("OPR", "Opera");
        e = e[2] ? [e[1], e[2]] : [navigator.appName, navigator.appVersion, "-?"];
        null !== (d = a.match(/version\/(\d+)/i)) && e.splice(1, 1, d[1]);
        return e.join(" ")
    }

    var a = {
        default: 6,
        IE: { 9: 6, 10: 8, default: 8 },
        Firefox: { default: 6 },
        Opera: { 10: 8, 11: 6, 12: 6, default: 6 },
        Chrome: { default: 6 },
        Safari: { default: 4 }
    };
    g.getMaxSimultaneousRequests = function() {
        var b = g.stackPrefetch.getConfiguration();
        if (b.maxSimultaneousRequests) return b.maxSimultaneousRequests;
        var e = d().split(" "),
            b = e[1];
        return (e = a[e[0]]) ? e[b] ? e[b] : e["default"] : a["default"]
    }
})(cornerstone, cornerstoneTools);
(function(c, g) {
    g.getRGBPixels = function(d, a, b, e, f) {
        if (!d) throw "getRGBPixels: parameter element must not be undefined";
        a = Math.round(a);
        b = Math.round(b);
        d = c.getEnabledElement(d);
        var g = [],
            m = 0,
            k = d.image.getPixelData(),
            l, n, q;
        if (d.image.color)
            for (n = 0; n < f; n++)
                for (q = 0; q < e; q++) {
                    l = 4 * ((n + b) * d.image.columns + (q + a));
                    var w = k[l],
                        p = k[l + 1],
                        u = k[l + 2];
                    l = k[l + 3];
                    g[m++] = w;
                    g[m++] = p;
                    g[m++] = u;
                    g[m++] = l
                }
        return g
    }
})(cornerstone, cornerstoneTools);
(function(c, g) {
    g.isMouseButtonEnabled = function(d, a) {
        return 0 !== (a & 1 << d - 1)
    }
})(cornerstone, cornerstoneTools);
(function(c, g, d) {
    d.pauseEvent = function(a) {
        a.stopPropagation && a.stopPropagation();
        a.preventDefault && a.preventDefault();
        a.cancelBubble = !0;
        return a.returnValue = !1
    }
})($, cornerstone, cornerstoneTools);
(function(c, g, d) {
    function a(a, b) {
        var d = b.rowCosines.clone().multiplyScalar(a.x);
        d.multiplyScalar(b.columnPixelSpacing);
        var c = b.columnCosines.clone().multiplyScalar(a.y);
        c.multiplyScalar(b.rowPixelSpacing);
        d = d.add(c);
        d.add(b.imagePositionPatient);
        return d
    }

    function b(a, b) {
        var d = [];
        Object.keys(b).forEach(function(c) {
            (c = a.intersectLine(b[c])) && d.push(c)
        });
        return d
    }

    d.projectPatientPointToImagePlane = function(a, b) {
        var d = a.clone().sub(b.imagePositionPatient),
            c = b.rowCosines.dot(d) / b.columnPixelSpacing,
            d = b.columnCosines.dot(d) / b.rowPixelSpacing;
        return { x: c, y: d }
    };
    d.imagePointToPatientPoint = a;
    d.planePlaneIntersection = function(d, c) {
        var g = d.rowCosines.clone().cross(d.columnCosines),
            m = new cornerstoneMath.Plane;
        m.setFromNormalAndCoplanarPoint(g, d.imagePositionPatient);
        var g = c.rowCosines.clone().cross(c.columnCosines),
            k = new cornerstoneMath.Plane;
        k.setFromNormalAndCoplanarPoint(g, c.imagePositionPatient);
        var m = k.clone().intersectPlane(m),
            g = m.origin,
            k = m.direction,
            m = a({
                x: c.columns,
                y: c.rows
            }, c),
            l = c.imagePositionPatient.distanceTo(m),
            m = new cornerstoneMath.Line3;
        m.start = g;
        m.end = g.clone().add(k.multiplyScalar(l));
        var g = a({ x: 0, y: 0 }, c),
            k = a({ x: c.columns, y: 0 }, c),
            l = a({ x: 0, y: c.rows }, c),
            n = a({
                x: c.columns,
                y: c.rows
            }, c),
            g = {
                top: new cornerstoneMath.Line3(g, k),
                left: new cornerstoneMath.Line3(g, l),
                right: new cornerstoneMath.Line3(k, n),
                bottom: new cornerstoneMath.Line3(l, n)
            },
            m = b(m, g);
        if (2 === m.length) return { start: m[0], end: m[1] }
    }
})($, cornerstone, cornerstoneTools);
(function(c, g) {
    g.scroll = function(d, a) {
        var b = g.getToolState(d, "stack");
        if (void 0 !== b && void 0 !== b.data && 0 !== b.data.length) {
            var b = b.data[0],
                c = b.currentImageIdIndex + a,
                c = Math.min(b.imageIds.length - 1, c),
                c = Math.max(0, c);
            g.scrollToIndex(d, c)
        }
    }
})(cornerstone, cornerstoneTools);
(function(c, g) {
    g.scrollToIndex = function(d, a) {
        function b(b) {
            h.currentImageIdIndex === a && (c.displayImage(d, b, l), m && m(d))
        }

        function e(b) {
            var c = h.imageIds[a];
            k && k(d, c, b)
        }

        var f = g.getToolState(d, "stack");
        if (void 0 !== f && void 0 !== f.data && 0 !== f.data.length) {
            var h = f.data[0];
            0 > a && (a += h.imageIds.length);
            var f = g.loadHandlerManager.getStartLoadHandler(),
                m = g.loadHandlerManager.getEndLoadHandler(),
                k = g.loadHandlerManager.getErrorLoadingHandler();
            if (a !== h.currentImageIdIndex) {
                f && f(d);
                f = { newImageIdIndex: a, direction: a - h.currentImageIdIndex };
                h.currentImageIdIndex = a;
                var l = c.getViewport(d),
                    n = h.imageIds[a],
                    q = g.stackScroll.getConfiguration();
                q && !0 === q.retryLoadOnScroll && (q = c.imageCache.getImagePromise(n)) && "rejected" === q.state() && c.imageCache.removeImagePromise(n);
                q = g.requestPoolManager;
                g.requestPoolManager.clearRequestStack("interaction");
                q.addRequest(d, n, "interaction", b, e);
                q.startGrabbing();
                $(d).trigger("CornerstoneStackScroll", f)
            }
        }
    };
    g.loadHandlers = {}
})(cornerstone, cornerstoneTools);
(function(c, g) {
    g.setContextToDisplayFontSize = function(d, a, b) {
        c.setToPixelCoordinateSystem(d, a, .1);
        return { fontSize: b / d.viewport.scale / .1, lineHeight: b / d.viewport.scale / .1, fontScale: .1 }
    }
})(cornerstone, cornerstoneTools)
window.cornerstoneTools = cornerstoneTools;