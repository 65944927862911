/*! cornerstoneMath - v0.1.2 - 2015-08-31 | (c) 2014 Chris Hafey | https://github.com/chafey/cornerstoneMath */
var cornerstoneMath = function(a) {
        "use strict";
        return void 0 === a && (a = {}), a.Vector3 = function(a, b, c) { this.x = a || 0, this.y = b || 0, this.z = c || 0 }, a.Vector3.prototype = {
            constructor: a.Vector3,
            set: function(a, b, c) { return this.x = a, this.y = b, this.z = c, this },
            setX: function(a) { return this.x = a, this },
            setY: function(a) { return this.y = a, this },
            setZ: function(a) { return this.z = a, this },
            setComponent: function(a, b) {
                switch (a) {
                    case 0:
                        this.x = b;
                        break;
                    case 1:
                        this.y = b;
                        break;
                    case 2:
                        this.z = b;
                        break;
                    default:
                        throw new Error("index is out of range: " + a)
                }
            },
            getComponent: function(a) {
                switch (a) {
                    case 0:
                        return this.x;
                    case 1:
                        return this.y;
                    case 2:
                        return this.z;
                    default:
                        throw new Error("index is out of range: " + a)
                }
            },
            copy: function(a) { return this.x = a.x, this.y = a.y, this.z = a.z, this },
            add: function(a, b) { return void 0 !== b ? (console.warn("DEPRECATED: Vector3's .add() now only accepts one argument. Use .addVectors( a, b ) instead."), this.addVectors(a, b)) : (this.x += a.x, this.y += a.y, this.z += a.z, this) },
            addScalar: function(a) { return this.x += a, this.y += a, this.z += a, this },
            addVectors: function(a, b) { return this.x = a.x + b.x, this.y = a.y + b.y, this.z = a.z + b.z, this },
            sub: function(a, b) { return void 0 !== b ? (console.warn("DEPRECATED: Vector3's .sub() now only accepts one argument. Use .subVectors( a, b ) instead."), this.subVectors(a, b)) : (this.x -= a.x, this.y -= a.y, this.z -= a.z, this) },
            subVectors: function(a, b) { return this.x = a.x - b.x, this.y = a.y - b.y, this.z = a.z - b.z, this },
            multiply: function(a, b) { return void 0 !== b ? (console.warn("DEPRECATED: Vector3's .multiply() now only accepts one argument. Use .multiplyVectors( a, b ) instead."), this.multiplyVectors(a, b)) : (this.x *= a.x, this.y *= a.y, this.z *= a.z, this) },
            multiplyScalar: function(a) { return this.x *= a, this.y *= a, this.z *= a, this },
            multiplyVectors: function(a, b) { return this.x = a.x * b.x, this.y = a.y * b.y, this.z = a.z * b.z, this },
            applyAxisAngle: function() { var b; return function(c, d) { return void 0 === b && (b = new a.Quaternion), this.applyQuaternion(b.setFromAxisAngle(c, d)), this } }(),
            applyMatrix3: function(a) {
                var b = this.x,
                    c = this.y,
                    d = this.z,
                    e = a.elements;
                return this.x = e[0] * b + e[3] * c + e[6] * d, this.y = e[1] * b + e[4] * c + e[7] * d, this.z = e[2] * b + e[5] * c + e[8] * d, this
            },
            applyMatrix4: function(a) {
                var b = this.x,
                    c = this.y,
                    d = this.z,
                    e = a.elements;
                return this.x = e[0] * b + e[4] * c + e[8] * d + e[12], this.y = e[1] * b + e[5] * c + e[9] * d + e[13], this.z = e[2] * b + e[6] * c + e[10] * d + e[14], this
            },
            applyProjection: function(a) {
                var b = this.x,
                    c = this.y,
                    d = this.z,
                    e = a.elements,
                    f = 1 / (e[3] * b + e[7] * c + e[11] * d + e[15]);
                return this.x = (e[0] * b + e[4] * c + e[8] * d + e[12]) * f, this.y = (e[1] * b + e[5] * c + e[9] * d + e[13]) * f, this.z = (e[2] * b + e[6] * c + e[10] * d + e[14]) * f, this
            },
            applyQuaternion: function(a) {
                var b = this.x,
                    c = this.y,
                    d = this.z,
                    e = a.x,
                    f = a.y,
                    g = a.z,
                    h = a.w,
                    i = h * b + f * d - g * c,
                    j = h * c + g * b - e * d,
                    k = h * d + e * c - f * b,
                    l = -e * b - f * c - g * d;
                return this.x = i * h + l * -e + j * -g - k * -f, this.y = j * h + l * -f + k * -e - i * -g, this.z = k * h + l * -g + i * -f - j * -e, this
            },
            transformDirection: function(a) {
                var b = this.x,
                    c = this.y,
                    d = this.z,
                    e = a.elements;
                return this.x = e[0] * b + e[4] * c + e[8] * d, this.y = e[1] * b + e[5] * c + e[9] * d, this.z = e[2] * b + e[6] * c + e[10] * d, this.normalize(), this
            },
            divide: function(a) { return this.x /= a.x, this.y /= a.y, this.z /= a.z, this },
            divideScalar: function(a) {
                if (0 !== a) {
                    var b = 1 / a;
                    this.x *= b, this.y *= b, this.z *= b
                } else this.x = 0, this.y = 0, this.z = 0;
                return this
            },
            min: function(a) { return this.x > a.x && (this.x = a.x), this.y > a.y && (this.y = a.y), this.z > a.z && (this.z = a.z), this },
            max: function(a) { return this.x < a.x && (this.x = a.x), this.y < a.y && (this.y = a.y), this.z < a.z && (this.z = a.z), this },
            clamp: function(a, b) { return this.x < a.x ? this.x = a.x : this.x > b.x && (this.x = b.x), this.y < a.y ? this.y = a.y : this.y > b.y && (this.y = b.y), this.z < a.z ? this.z = a.z : this.z > b.z && (this.z = b.z), this },
            clampScalar: function() { var b, c; return function(d, e) { return void 0 === b && (b = new a.Vector3, c = new a.Vector3), b.set(d, d, d), c.set(e, e, e), this.clamp(b, c) } }(),
            floor: function() { return this.x = Math.floor(this.x), this.y = Math.floor(this.y), this.z = Math.floor(this.z), this },
            ceil: function() { return this.x = Math.ceil(this.x), this.y = Math.ceil(this.y), this.z = Math.ceil(this.z), this },
            round: function() { return this.x = Math.round(this.x), this.y = Math.round(this.y), this.z = Math.round(this.z), this },
            roundToZero: function() { return this.x = this.x < 0 ? Math.ceil(this.x) : Math.floor(this.x), this.y = this.y < 0 ? Math.ceil(this.y) : Math.floor(this.y), this.z = this.z < 0 ? Math.ceil(this.z) : Math.floor(this.z), this },
            negate: function() { return this.multiplyScalar(-1) },
            dot: function(a) { return this.x * a.x + this.y * a.y + this.z * a.z },
            lengthSq: function() { return this.x * this.x + this.y * this.y + this.z * this.z },
            length: function() { return Math.sqrt(this.x * this.x + this.y * this.y + this.z * this.z) },
            lengthManhattan: function() { return Math.abs(this.x) + Math.abs(this.y) + Math.abs(this.z) },
            normalize: function() { return this.divideScalar(this.length()) },
            setLength: function(a) { var b = this.length(); return 0 !== b && a !== b && this.multiplyScalar(a / b), this },
            lerp: function(a, b) { return this.x += (a.x - this.x) * b, this.y += (a.y - this.y) * b, this.z += (a.z - this.z) * b, this },
            cross: function(a, b) {
                if (void 0 !== b) return console.warn("DEPRECATED: Vector3's .cross() now only accepts one argument. Use .crossVectors( a, b ) instead."), this.crossVectors(a, b);
                var c = this.x,
                    d = this.y,
                    e = this.z;
                return this.x = d * a.z - e * a.y, this.y = e * a.x - c * a.z, this.z = c * a.y - d * a.x, this
            },
            crossVectors: function(a, b) {
                var c = a.x,
                    d = a.y,
                    e = a.z,
                    f = b.x,
                    g = b.y,
                    h = b.z;
                return this.x = d * h - e * g, this.y = e * f - c * h, this.z = c * g - d * f, this
            },
            projectOnVector: function() { var b, c; return function(d) { return void 0 === b && (b = new a.Vector3), b.copy(d).normalize(), c = this.dot(b), this.copy(b).multiplyScalar(c) } }(),
            projectOnPlane: function() { var b; return function(c) { return void 0 === b && (b = new a.Vector3), b.copy(this).projectOnVector(c), this.sub(b) } }(),
            reflect: function() { var b; return function(c) { return void 0 === b && (b = new a.Vector3), this.sub(b.copy(c).multiplyScalar(2 * this.dot(c))) } }(),
            angleTo: function(b) { var c = this.dot(b) / (this.length() * b.length()); return Math.acos(a.clamp(c, -1, 1)) },
            distanceTo: function(a) { return Math.sqrt(this.distanceToSquared(a)) },
            distanceToSquared: function(a) {
                var b = this.x - a.x,
                    c = this.y - a.y,
                    d = this.z - a.z;
                return b * b + c * c + d * d
            },
            setEulerFromRotationMatrix: function(a, b) { console.error("REMOVED: Vector3's setEulerFromRotationMatrix has been removed in favor of Euler.setFromRotationMatrix(), please update your code.") },
            setEulerFromQuaternion: function(a, b) { console.error("REMOVED: Vector3's setEulerFromQuaternion: has been removed in favor of Euler.setFromQuaternion(), please update your code.") },
            getPositionFromMatrix: function(a) { return console.warn("DEPRECATED: Vector3's .getPositionFromMatrix() has been renamed to .setFromMatrixPosition(). Please update your code."), this.setFromMatrixPosition(a) },
            getScaleFromMatrix: function(a) { return console.warn("DEPRECATED: Vector3's .getScaleFromMatrix() has been renamed to .setFromMatrixScale(). Please update your code."), this.setFromMatrixScale(a) },
            getColumnFromMatrix: function(a, b) { return console.warn("DEPRECATED: Vector3's .getColumnFromMatrix() has been renamed to .setFromMatrixColumn(). Please update your code."), this.setFromMatrixColumn(a, b) },
            setFromMatrixPosition: function(a) { return this.x = a.elements[12], this.y = a.elements[13], this.z = a.elements[14], this },
            setFromMatrixScale: function(a) {
                var b = this.set(a.elements[0], a.elements[1], a.elements[2]).length(),
                    c = this.set(a.elements[4], a.elements[5], a.elements[6]).length(),
                    d = this.set(a.elements[8], a.elements[9], a.elements[10]).length();
                return this.x = b, this.y = c, this.z = d, this
            },
            setFromMatrixColumn: function(a, b) {
                var c = 4 * a,
                    d = b.elements;
                return this.x = d[c], this.y = d[c + 1], this.z = d[c + 2], this
            },
            equals: function(a) { return a.x === this.x && a.y === this.y && a.z === this.z },
            fromArray: function(a) { return this.x = a[0], this.y = a[1], this.z = a[2], this },
            toArray: function() { return [this.x, this.y, this.z] },
            clone: function() { return new a.Vector3(this.x, this.y, this.z) }
        }, a
    }(cornerstoneMath),
    cornerstoneMath = function(a) {
        "use strict";
        return void 0 === a && (a = {}), a.Line3 = function(b, c) { this.start = void 0 !== b ? b : new a.Vector3, this.end = void 0 !== c ? c : new a.Vector3 }, a.Line3.prototype = {
            constructor: a.Line3,
            set: function(a, b) { return this.start.copy(a), this.end.copy(b), this },
            copy: function(a) { return this.start.copy(a.start), this.end.copy(a.end), this },
            center: function(b) { var c = b || new a.Vector3; return c.addVectors(this.start, this.end).multiplyScalar(.5) },
            delta: function(b) { var c = b || new a.Vector3; return c.subVectors(this.end, this.start) },
            distanceSq: function() { return this.start.distanceToSquared(this.end) },
            distance: function() { return this.start.distanceTo(this.end) },
            at: function(b, c) { var d = c || new a.Vector3; return this.delta(d).multiplyScalar(b).add(this.start) },
            closestPointToPointParameter: function() {
                var b = new a.Vector3,
                    c = new a.Vector3;
                return function(d, e) {
                    b.subVectors(d, this.start), c.subVectors(this.end, this.start);
                    var f = c.dot(c),
                        g = c.dot(b),
                        h = g / f;
                    return e && (h = a.Math.clamp(h, 0, 1)), h
                }
            }(),
            closestPointToPoint: function(b, c, d) {
                var e = this.closestPointToPointParameter(b, c),
                    f = d || new a.Vector3;
                return this.delta(f).multiplyScalar(e).add(this.start)
            },
            applyMatrix4: function(a) { return this.start.applyMatrix4(a), this.end.applyMatrix4(a), this },
            equals: function(a) { return a.start.equals(this.start) && a.end.equals(this.end) },
            clone: function() { return (new a.Line3).copy(this) },
            intersectLine: function(a) {
                var b = this.end.clone().sub(this.start),
                    c = a.end.clone().sub(a.start),
                    d = a.start.clone().sub(this.start),
                    e = b.clone().cross(c),
                    f = d.clone().cross(c);
                if (0 !== d.dot(b)) {
                    var g = f.dot(e) / e.lengthSq();
                    if (!(g > 1 || isNaN(g))) {
                        var h = this.start.clone().add(b.clone().multiplyScalar(g)),
                            i = h.clone().sub(a.start).lengthSq() + h.clone().sub(a.end).lengthSq();
                        return i <= a.distanceSq() ? h : void 0
                    }
                }
            }
        }, a
    }(cornerstoneMath),
    cornerstoneMath = function(a) {
        "use strict";

        function b(a) { return a * a }

        function c(a, c) { return b(a.x - c.x) + b(a.y - c.y) }

        function d(a, b) { var d = c(a.start, a.end); if (0 === d) return c(b, a.start); var e = ((b.x - a.start.x) * (a.end.x - a.start.x) + (b.y - a.start.y) * (a.end.y - a.start.y)) / d; if (0 > e) return c(b, a.start); if (e > 1) return c(b, a.end); var f = { x: a.start.x + e * (a.end.x - a.start.x), y: a.start.y + e * (a.end.y - a.start.y) }; return c(b, f) }

        function e(a, b) { return Math.sqrt(d(a, b)) }
        return void 0 === a && (a = {}), a.lineSegment = { distanceToPoint: e }, a
    }(cornerstoneMath),
    cornerstoneMath = function(a) {
        "use strict";

        function b(a, b, c) { return b > a ? b : a > c ? c : a }

        function c(a) { var b = Math.PI / 180; return a * b }

        function d(a) { var b = 180 / Math.PI; return a * b }
        return void 0 === a && (a = {}), a.clamp = b, a.degToRad = c, a.radToDeg = d, a
    }(cornerstoneMath),
    cornerstoneMath = function(a) {
        "use strict";
        return void 0 === a && (a = {}), a.Matrix4 = function(a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p) {
            this.elements = new Float32Array(16);
            var q = this.elements;
            q[0] = void 0 !== a ? a : 1, q[4] = b || 0, q[8] = c || 0, q[12] = d || 0, q[1] = e || 0, q[5] = void 0 !== f ? f : 1, q[9] = g || 0, q[13] = h || 0, q[2] = i || 0, q[6] = j || 0, q[10] = void 0 !== k ? k : 1, q[14] = l || 0, q[3] = m || 0, q[7] = n || 0, q[11] = o || 0, q[15] = void 0 !== p ? p : 1
        }, a.Matrix4.prototype.makeRotationFromQuaternion = function(a) {
            var b = this.elements,
                c = a.x,
                d = a.y,
                e = a.z,
                f = a.w,
                g = c + c,
                h = d + d,
                i = e + e,
                j = c * g,
                k = c * h,
                l = c * i,
                m = d * h,
                n = d * i,
                o = e * i,
                p = f * g,
                q = f * h,
                r = f * i;
            return b[0] = 1 - (m + o), b[4] = k - r, b[8] = l + q, b[1] = k + r, b[5] = 1 - (j + o), b[9] = n - p, b[2] = l - q, b[6] = n + p, b[10] = 1 - (j + m), b[3] = 0, b[7] = 0, b[11] = 0, b[12] = 0, b[13] = 0, b[14] = 0, b[15] = 1, this
        }, a.Matrix4.prototype.multiplyMatrices = function(a, b) {
            var c = a.elements,
                d = b.elements,
                e = this.elements,
                f = c[0],
                g = c[4],
                h = c[8],
                i = c[12],
                j = c[1],
                k = c[5],
                l = c[9],
                m = c[13],
                n = c[2],
                o = c[6],
                p = c[10],
                q = c[14],
                r = c[3],
                s = c[7],
                t = c[11],
                u = c[15],
                v = d[0],
                w = d[4],
                x = d[8],
                y = d[12],
                z = d[1],
                A = d[5],
                B = d[9],
                C = d[13],
                D = d[2],
                E = d[6],
                F = d[10],
                G = d[14],
                H = d[3],
                I = d[7],
                J = d[11],
                K = d[15];
            return e[0] = f * v + g * z + h * D + i * H, e[4] = f * w + g * A + h * E + i * I, e[8] = f * x + g * B + h * F + i * J, e[12] = f * y + g * C + h * G + i * K, e[1] = j * v + k * z + l * D + m * H, e[5] = j * w + k * A + l * E + m * I, e[9] = j * x + k * B + l * F + m * J, e[13] = j * y + k * C + l * G + m * K, e[2] = n * v + o * z + p * D + q * H, e[6] = n * w + o * A + p * E + q * I, e[10] = n * x + o * B + p * F + q * J, e[14] = n * y + o * C + p * G + q * K, e[3] = r * v + s * z + t * D + u * H, e[7] = r * w + s * A + t * E + u * I, e[11] = r * x + s * B + t * F + u * J, e[15] = r * y + s * C + t * G + u * K, this
        }, a.Matrix4.prototype.multiply = function(a, b) { return void 0 !== b ? (console.warn("DEPRECATED: Matrix4's .multiply() now only accepts one argument. Use .multiplyMatrices( a, b ) instead."), this.multiplyMatrices(a, b)) : this.multiplyMatrices(this, a) }, a.Matrix4.prototype.getInverse = function(a, b) {
            var c = this.elements,
                d = a.elements,
                e = d[0],
                f = d[4],
                g = d[8],
                h = d[12],
                i = d[1],
                j = d[5],
                k = d[9],
                l = d[13],
                m = d[2],
                n = d[6],
                o = d[10],
                p = d[14],
                q = d[3],
                r = d[7],
                s = d[11],
                t = d[15];
            c[0] = k * p * r - l * o * r + l * n * s - j * p * s - k * n * t + j * o * t, c[4] = h * o * r - g * p * r - h * n * s + f * p * s + g * n * t - f * o * t, c[8] = g * l * r - h * k * r + h * j * s - f * l * s - g * j * t + f * k * t, c[12] = h * k * n - g * l * n - h * j * o + f * l * o + g * j * p - f * k * p, c[1] = l * o * q - k * p * q - l * m * s + i * p * s + k * m * t - i * o * t, c[5] = g * p * q - h * o * q + h * m * s - e * p * s - g * m * t + e * o * t, c[9] = h * k * q - g * l * q - h * i * s + e * l * s + g * i * t - e * k * t, c[13] = g * l * m - h * k * m + h * i * o - e * l * o - g * i * p + e * k * p, c[2] = j * p * q - l * n * q + l * m * r - i * p * r - j * m * t + i * n * t, c[6] = h * n * q - f * p * q - h * m * r + e * p * r + f * m * t - e * n * t, c[10] = f * l * q - h * j * q + h * i * r - e * l * r - f * i * t + e * j * t, c[14] = h * j * m - f * l * m - h * i * n + e * l * n + f * i * p - e * j * p, c[3] = k * n * q - j * o * q - k * m * r + i * o * r + j * m * s - i * n * s, c[7] = f * o * q - g * n * q + g * m * r - e * o * r - f * m * s + e * n * s, c[11] = g * j * q - f * k * q - g * i * r + e * k * r + f * i * s - e * j * s, c[15] = f * k * m - g * j * m + g * i * n - e * k * n - f * i * o + e * j * o;
            var u = e * c[0] + i * c[4] + m * c[8] + q * c[12];
            if (0 === u) { var v = "Matrix4.getInverse(): can't invert matrix, determinant is 0"; if (b) throw new Error(v); return console.warn(v), this.identity(), this }
            return this.multiplyScalar(1 / u), this
        }, a.Matrix4.prototype.applyToVector3Array = function() { var b = new a.Vector3; return function(a, c, d) { void 0 === c && (c = 0), void 0 === d && (d = a.length); for (var e = 0, f = c; d > e; e += 3, f += 3) b.x = a[f], b.y = a[f + 1], b.z = a[f + 2], b.applyMatrix4(this), a[f] = b.x, a[f + 1] = b.y, a[f + 2] = b.z; return a } }, a.Matrix4.prototype.makeTranslation = function(a, b, c) { return this.set(1, 0, 0, a, 0, 1, 0, b, 0, 0, 1, c, 0, 0, 0, 1), this }, a.Matrix4.prototype.multiplyScalar = function(a) { var b = this.elements; return b[0] *= a, b[4] *= a, b[8] *= a, b[12] *= a, b[1] *= a, b[5] *= a, b[9] *= a, b[13] *= a, b[2] *= a, b[6] *= a, b[10] *= a, b[14] *= a, b[3] *= a, b[7] *= a, b[11] *= a, b[15] *= a, this }, a.Matrix4.prototype.set = function(a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p) { var q = this.elements; return q[0] = a, q[4] = b, q[8] = c, q[12] = d, q[1] = e, q[5] = f, q[9] = g, q[13] = h, q[2] = i, q[6] = j, q[10] = k, q[14] = l, q[3] = m, q[7] = n, q[11] = o, q[15] = p, this }, a.Matrix4.prototype.makeScale = function(a, b, c) { return this.set(a, 0, 0, 0, 0, b, 0, 0, 0, 0, c, 0, 0, 0, 0, 1), this }, a
    }(cornerstoneMath),
    cornerstoneMath = function(a) {
        "use strict";
        return void 0 === a && (a = {}), a.Plane = function(b, c) { this.normal = void 0 !== b ? b : new a.Vector3(1, 0, 0), this.constant = void 0 !== c ? c : 0 }, a.Plane.prototype = {
            constructor: a.Plane,
            set: function(a, b) { return this.normal.copy(a), this.constant = b, this },
            setComponents: function(a, b, c, d) { return this.normal.set(a, b, c), this.constant = d, this },
            setFromNormalAndCoplanarPoint: function(a, b) { return this.normal.copy(a), this.constant = -b.dot(this.normal), this },
            setFromCoplanarPoints: function() {
                var b = new a.Vector3,
                    c = new a.Vector3;
                return function(a, d, e) { var f = b.subVectors(e, d).cross(c.subVectors(a, d)).normalize(); return this.setFromNormalAndCoplanarPoint(f, a), this }
            }(),
            copy: function(a) { return this.normal.copy(a.normal), this.constant = a.constant, this },
            normalize: function() { var a = 1 / this.normal.length(); return this.normal.multiplyScalar(a), this.constant *= a, this },
            negate: function() { return this.constant *= -1, this.normal.negate(), this },
            distanceToPoint: function(a) { return this.normal.dot(a) + this.constant },
            distanceToSphere: function(a) { return this.distanceToPoint(a.center) - a.radius },
            projectPoint: function(a, b) { return this.orthoPoint(a, b).sub(a).negate() },
            orthoPoint: function(b, c) {
                var d = this.distanceToPoint(b),
                    e = c || new a.Vector3;
                return e.copy(this.normal).multiplyScalar(d)
            },
            isIntersectionLine: function(a) {
                var b = this.distanceToPoint(a.start),
                    c = this.distanceToPoint(a.end);
                return 0 > b && c > 0 || 0 > c && b > 0
            },
            intersectLine: function() {
                var b = new a.Vector3;
                return function(c, d) {
                    var e = d || new a.Vector3,
                        f = c.delta(b),
                        g = this.normal.dot(f);
                    if (0 === g) return 0 === this.distanceToPoint(c.start) ? e.copy(c.start) : void 0;
                    var h = -(c.start.dot(this.normal) + this.constant) / g;
                    return 0 > h || h > 1 ? void 0 : e.copy(f).multiplyScalar(h).add(c.start)
                }
            }(),
            intersectPlane: function(b) {
                var c = this.normal.clone().cross(b.normal),
                    d = new a.Vector3,
                    e = { origin: d, direction: c };
                if (this.normal.clone().cross(b.normal).length < 1e-10) return e.direction = new a.Vector3, e;
                var f = this.constant,
                    g = b.constant,
                    h = this.normal.clone().dot(b.normal),
                    i = -(f - g * h) / (1 - h * h),
                    j = -(g - f * h) / (1 - h * h);
                return e.origin = this.normal.clone().multiplyScalar(i).add(b.normal.clone().multiplyScalar(j)), e
            },
            coplanarPoint: function(b) { var c = b || new a.Vector3; return c.copy(this.normal).multiplyScalar(-this.constant) },
            translate: function(a) { return this.constant = this.constant - a.dot(this.normal), this },
            equals: function(a) { return a.normal.equals(this.normal) && a.constant == this.constant },
            clone: function() { return (new a.Plane).copy(this) }
        }, a
    }(cornerstoneMath),
    cornerstoneMath = function(a) {
        "use strict";

        function b(a) { return { x: a.pageX, y: a.pageY } }

        function c(a, b) { return { x: a.x - b.x, y: a.y - b.y } }

        function d(a) { return { x: a.x, y: a.y } }

        function e(a, b) { return Math.sqrt(f(a, b)) }

        function f(a, b) { var d = c(a, b); return d.x * d.x + d.y * d.y }

        function g(a, b) { return a.x < b.left || a.x > b.left + b.width || a.y < b.top || a.y > b.top + b.height ? !1 : !0 }
        return void 0 === a && (a = {}), a.point = { subtract: c, copy: d, pageToPoint: b, distance: e, distanceSquared: f, insideRect: g }, a
    }(cornerstoneMath),
    cornerstoneMath = function(a) {
        "use strict";
        return void 0 === a && (a = {}), a.Quaternion = function(a, b, c, d) { this.x = a || 0, this.y = b || 0, this.z = c || 0, this.w = void 0 !== d ? d : 1 }, a.Quaternion.prototype.setFromAxisAngle = function(a, b) {
            var c = b / 2,
                d = Math.sin(c);
            return this.x = a.x * d, this.y = a.y * d, this.z = a.z * d, this.w = Math.cos(c), this
        }, a.Quaternion.prototype.multiplyQuaternions = function(a, b) {
            var c = a.x,
                d = a.y,
                e = a.z,
                f = a.w,
                g = b.x,
                h = b.y,
                i = b.z,
                j = b.w;
            return this.x = c * j + f * g + d * i - e * h, this.y = d * j + f * h + e * g - c * i, this.z = e * j + f * i + c * h - d * g, this.w = f * j - c * g - d * h - e * i, this
        }, a.Quaternion.prototype.setFromRotationMatrix = function(a) {
            var b, c = a.elements,
                d = c[0],
                e = c[4],
                f = c[8],
                g = c[1],
                h = c[5],
                i = c[9],
                j = c[2],
                k = c[6],
                l = c[10],
                m = d + h + l;
            return m > 0 ? (b = .5 / Math.sqrt(m + 1), this.w = .25 / b, this.x = (k - i) * b, this.y = (f - j) * b, this.z = (g - e) * b) : d > h && d > l ? (b = 2 * Math.sqrt(1 + d - h - l), this.w = (k - i) / b, this.x = .25 * b, this.y = (e + g) / b, this.z = (f + j) / b) : h > l ? (b = 2 * Math.sqrt(1 + h - d - l), this.w = (f - j) / b, this.x = (e + g) / b, this.y = .25 * b, this.z = (i + k) / b) : (b = 2 * Math.sqrt(1 + l - d - h), this.w = (g - e) / b, this.x = (f + j) / b, this.y = (i + k) / b, this.z = .25 * b), this
        }, a
    }(cornerstoneMath),
    cornerstoneMath = function(a) {
        "use strict";

        function b(a) {
            var b = { start: { x: a.left, y: a.top }, end: { x: a.left + a.width, y: a.top } },
                c = { start: { x: a.left + a.width, y: a.top }, end: { x: a.left + a.width, y: a.top + a.height } },
                d = { start: { x: a.left + a.width, y: a.top + a.height }, end: { x: a.left, y: a.top + a.height } },
                e = { start: { x: a.left, y: a.top + a.height }, end: { x: a.left, y: a.top } },
                f = [b, c, d, e];
            return f
        }

        function c(c, d) {
            var e = 655535,
                f = b(c);
            return f.forEach(function(b) {
                var c = a.lineSegment.distanceToPoint(b, d);
                e > c && (e = c)
            }), e
        }
        return void 0 === a && (a = {}), a.rect = { distanceToPoint: c }, a
    }(cornerstoneMath);

window.cornerstoneMath = cornerstoneMath;