var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dcm-container"},[_c('div',{staticClass:"cornerstone-enabled-image",staticStyle:{"width":"500px","height":"800px","display":"inline-block","position":"relative","color":"white"},attrs:{"id":"dicomImageWrapper","oncontextmenu":"return false","unselectable":"on","onselectstart":"return false;","onmousedown":"return false;"}},[_c('div',{staticStyle:{"width":"500px","height":"800px","top":"0px","left":"0px","position":"absolute"},attrs:{"id":"dicomImage"},on:{"mousedown":_vm.showSlider}}),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_c('div',{attrs:{"id":"alert-tip"}})]),_vm._m(5),_c('div',{staticStyle:{"display":"none"},attrs:{"id":"footer-slider"}},[_c('table',{staticStyle:{"width":"100%"},attrs:{"cellpadding":"0","cellspacing":"0"}},[_c('tr',[_c('td',{attrs:{"width":"36"}},[_c('span',{staticStyle:{"display":"inline-block"},attrs:{"id":"footer-slider-play-ctrl","tapmode":""},on:{"click":function($event){return _vm.doAction({ action1: 'Cine', action2: 'play' }, $event)}}},[_c('i',{staticClass:"iconfont icon-bofang",staticStyle:{"font-size":"24px","height":"30px","line-height":"30px"}})]),_c('span',{staticStyle:{"display":"none"},attrs:{"id":"footer-slider-playing-ctrl","tapmode":""},on:{"click":function($event){return _vm.doAction({ action1: 'Cine', action2: 'pause' }, $event)}}},[_c('i',{staticClass:"iconfont icon-zanting",staticStyle:{"font-size":"28px","height":"33px","line-height":"33px"}})])]),_c('td',{staticStyle:{"padding-right":"10px"},attrs:{"align":"right"}},[_c('table',{staticStyle:{"width":"100%"},attrs:{"cellpadding":"0","cellspacing":"0"}},[_c('tr',[_c('td',{staticStyle:{"width":"40px"},attrs:{"align":"left"}},[_c('i',{staticClass:"iconfont icon-fanhui1",attrs:{"tapmode":""},on:{"click":function($event){return _vm.doAction(
                        { action1: 'Cine', action2: 'previesImage' },
                        $event
                      )}}})]),_vm._m(6),_c('td',{staticStyle:{"width":"40px"},attrs:{"align":"right"}},[_c('i',{staticClass:"iconfont icon-fanhui2",attrs:{"tapmode":""},on:{"click":function($event){return _vm.doAction(
                        { action1: 'Cine', action2: 'nextImage' },
                        $event
                      )}}})]),_c('td',{staticStyle:{"width":"26px","position":"relative"},attrs:{"align":"right"}},[_c('div',{staticClass:"frame-rate-ctrl",attrs:{"tapmode":"","id":"frame-rate-val"},on:{"click":function($event){return _vm.doAction(
                        { action1: 'Cine', action2: 'showSetFrameRate' },
                        $event
                      )}}},[_vm._v(" 1 ")]),_c('div',{staticClass:"frame-rate-list",staticStyle:{"display":"none"},attrs:{"id":"frame-rate-list"}},[_c('span',{staticClass:"active",attrs:{"tapmode":""},on:{"click":function($event){return _vm.doAction(
                          { action1: 'Cine', action2: 'setFrameRate' },
                          $event
                        )}}},[_vm._v("1")]),_c('span',{attrs:{"tapmode":""},on:{"click":function($event){return _vm.doAction(
                          { action1: 'Cine', action2: 'setFrameRate' },
                          $event
                        )}}},[_vm._v("2")]),_c('span',{attrs:{"tapmode":""},on:{"click":function($event){return _vm.doAction(
                          { action1: 'Cine', action2: 'setFrameRate' },
                          $event
                        )}}},[_vm._v("3")]),_c('span',{attrs:{"tapmode":""},on:{"click":function($event){return _vm.doAction(
                          { action1: 'Cine', action2: 'setFrameRate' },
                          $event
                        )}}},[_vm._v("4")]),_c('span',{attrs:{"tapmode":""},on:{"click":function($event){return _vm.doAction(
                          { action1: 'Cine', action2: 'setFrameRate' },
                          $event
                        )}}},[_vm._v("5")]),_c('span',{attrs:{"tapmode":""},on:{"click":function($event){return _vm.doAction(
                          { action1: 'Cine', action2: 'setFrameRate' },
                          $event
                        )}}},[_vm._v("6")]),_c('span',{attrs:{"tapmode":""},on:{"click":function($event){return _vm.doAction(
                          { action1: 'Cine', action2: 'setFrameRate' },
                          $event
                        )}}},[_vm._v("7")]),_c('span',{attrs:{"tapmode":""},on:{"click":function($event){return _vm.doAction(
                          { action1: 'Cine', action2: 'setFrameRate' },
                          $event
                        )}}},[_vm._v("8")]),_c('span',{attrs:{"tapmode":""},on:{"click":function($event){return _vm.doAction(
                          { action1: 'Cine', action2: 'setFrameRate' },
                          $event
                        )}}},[_vm._v("9")]),_c('span',{attrs:{"tapmode":""},on:{"click":function($event){return _vm.doAction(
                          { action1: 'Cine', action2: 'setFrameRate' },
                          $event
                        )}}},[_vm._v("10")])])])])])])])])]),_c('div',{staticStyle:{"bottom":"64px","display":"none"},attrs:{"id":"footer2"}},[_c('div',{attrs:{"id":"footer_subfeature"}},[_c('ul',{staticClass:"footer-sub-wrapper",staticStyle:{"display":"block"},attrs:{"id":"footer-sub-windowing"}},[_c('li',{attrs:{"id":"footer2_invplain"},on:{"click":function($event){return _vm.doAction(
                { action1: 'Windowing', action2: 'setToInvplain' },
                $event
              )}}},[_vm._v(" 反色 ")]),_c('li',{on:{"click":function($event){return _vm.doAction(
                { action1: 'Windowing', action2: 'showPresetDialog' },
                $event
              )}}},[_vm._v(" 预设 ")]),_c('li',{on:{"click":function($event){return _vm.doAction(
                { action1: 'Windowing', action2: 'showPseudocolorDialog' },
                $event
              )}}},[_vm._v(" 伪彩 ")])]),_c('ul',{staticClass:"footer-sub-wrapper",attrs:{"id":"footer-sub-zoomPan"}},[_c('li',{on:{"click":function($event){return _vm.doAction({ action1: 'ZoomPan', action2: 'rotateLeft' }, $event)}}},[_vm._v(" 左旋 ")]),_c('li',{on:{"click":function($event){return _vm.doAction({ action1: 'ZoomPan', action2: 'rotateRight' }, $event)}}},[_vm._v(" 右旋 ")]),_c('li',{on:{"click":function($event){return _vm.doAction({ action1: 'ZoomPan', action2: 'FreeRotate' }, $event)}}},[_vm._v(" 自由旋 ")])]),_c('ul',{staticClass:"footer-sub-wrapper",attrs:{"id":"footer-sub-measure"}},[_c('li',{attrs:{"id":"footer2_line"},on:{"click":function($event){return _vm.doAction({ action1: 'Measure', action2: 'line' }, $event)}}},[_vm._v(" 直线 ")]),_c('li',{attrs:{"id":"footer2_rectangle"},on:{"click":function($event){return _vm.doAction({ action1: 'Measure', action2: 'rectangle' }, $event)}}},[_vm._v(" 矩形 ")]),_c('li',{attrs:{"id":"footer2_ellipse"},on:{"click":function($event){return _vm.doAction({ action1: 'Measure', action2: 'ellipse' }, $event)}}},[_vm._v(" 椭圆 ")]),_c('li',{attrs:{"id":"footer2_protractor"},on:{"click":function($event){return _vm.doAction({ action1: 'Measure', action2: 'protractor' }, $event)}}},[_vm._v(" 角度 ")]),_c('li',{attrs:{"id":"footer2_clear"},on:{"click":function($event){return _vm.doAction({ action1: 'Measure', action2: 'clear' }, $event)}}},[_vm._v(" 清理 ")])]),_c('ul',{staticClass:"footer-sub-wrapper",attrs:{"id":"windowLevelPresetWrapper"}},[_c('li',{on:{"click":function($event){return _vm.doAction(
                { action1: 'Windowing', action2: 'PresetDefault' },
                $event
              )}}},[_vm._v(" 默认 ")]),_c('li',{on:{"click":function($event){return _vm.doAction({ action1: 'Windowing', action2: 'MinMax' }, $event)}}},[_vm._v(" m/M ")]),_c('li',{staticStyle:{"display":"none"},on:{"click":function($event){return _vm.doAction({ action1: 'Windowing', action2: 'Customized' }, $event)}}},[_vm._v(" 自定义 ")]),_c('li',{staticClass:"ct-preset",staticStyle:{"display":"none"},on:{"click":function($event){return _vm.doAction(
                { action1: 'Windowing', action2: 'PresetMediastinum' },
                $event
              )}}},[_vm._v(" 纵膈 ")]),_c('li',{staticClass:"ct-preset",staticStyle:{"display":"none"},on:{"click":function($event){return _vm.doAction({ action1: 'Windowing', action2: 'PresetLung' }, $event)}}},[_vm._v(" 肺 ")]),_c('li',{staticClass:"ct-preset",staticStyle:{"display":"none"},on:{"click":function($event){return _vm.doAction({ action1: 'Windowing', action2: 'PresetBone' }, $event)}}},[_vm._v(" 骨 ")])])])]),_c('div',{attrs:{"id":"footer1"}},[_c('table',{attrs:{"id":"footer-table","cellpadding":"0","cellspacing":"0"}},[_c('tr',[_c('td',{staticClass:"selected",attrs:{"width":"20%","tapmode":"","id":"footer-series-tool"},on:{"click":function($event){return _vm.doAction({ action1: 'ToggleSeriesLayout' }, $event)}}},[_c('i',{staticClass:"iconfont icon-xulie"}),_c('span',{staticClass:"footer-title"},[_vm._v("序列")])]),_c('td',{attrs:{"id":"footer1_windowing","width":"20%","tapmode":""},on:{"click":function($event){return _vm.doAction(
                { action1: 'SwitchFeatureGroup', action2: 'windowing' },
                $event
              )}}},[_c('i',{staticClass:"iconfont icon-tiaose"}),_c('span',{staticClass:"footer-title"},[_vm._v("调窗")])]),_c('td',{attrs:{"id":"footer1_zoompan","width":"20%","tapmode":""},on:{"click":function($event){return _vm.doAction(
                { action1: 'SwitchFeatureGroup', action2: 'zoomPan' },
                $event
              )}}},[_c('i',{staticClass:"iconfont icon-pingyi"}),_c('span',{staticClass:"footer-title"},[_vm._v("移动")])]),_c('td',{attrs:{"id":"footer1_measure","width":"20%","tapmode":""},on:{"click":function($event){return _vm.doAction(
                { action1: 'SwitchFeatureGroup', action2: 'measure' },
                $event
              )}}},[_c('i',{staticClass:"iconfont icon-celiang"}),_c('span',{staticClass:"footer-title"},[_vm._v("测量")])]),_c('td',{attrs:{"id":"footer1_more","width":"20%","tapmode":""},on:{"click":function($event){return _vm.doActionReset($event)}}},[_c('i',{staticClass:"iconfont icon-reset"}),_c('span',{staticClass:"footer-title"},[_vm._v("重置")])])])])]),_vm._m(7),_vm._m(8),_vm._m(9),_c('van-overlay',{attrs:{"show":_vm.agreementShow,"z-index":111111,"class-name":"agreementStyle"},on:{"click":function($event){_vm.show = false}}},[_c('div',{staticClass:"agreement-dialog"},[_c('img',{staticClass:"banner",attrs:{"src":require("@/assets/img/dialog-banners.png")}}),_c('p',{staticClass:"title"},[_vm._v("授权上传影像数据")]),_c('p',{staticClass:"desc"},[_vm._v("您的影像数据已经准备好，您可以在线查阅。")]),_c('div',{staticClass:"agreement-checked"},[_c('van-checkbox',{attrs:{"shape":"square"},model:{value:(_vm.agreementType),callback:function ($$v) {_vm.agreementType=$$v},expression:"agreementType"}}),_c('p',{staticClass:"txt"},[_c('span',{on:{"click":function($event){_vm.agreementType = !_vm.agreementType}}},[_vm._v("我已阅读并同意")]),_c('a',{on:{"click":_vm.herfs}},[_vm._v("《授权上传影像协议》")])])],1),_c('div',{staticClass:"argeement-btn",on:{"click":_vm.empower}},[_vm._v(" 立即授权 ")])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"gg-640"},[_c('div',{staticClass:"left-jt",attrs:{"id":"left-jt"}},[_c('img',{attrs:{"src":require("@/views/dcm/dcmicons/left-jt.png"),"alt":""}})]),_c('p',{attrs:{"id":"xuliehao"}}),_c('div',{staticClass:"right-jt",attrs:{"id":"right-jt"}},[_c('img',{attrs:{"src":require("@/views/dcm/dcmicons/right-jt.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"infoLayer",staticStyle:{"position":"absolute","top":"10px","left":"3px"},attrs:{"id":"mrtopleft"}},[_c('div',{attrs:{"id":"patientName"}}),_c('div',{attrs:{"id":"patientId"}}),_c('div',{attrs:{"id":"accession_number"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"infoLayer",staticStyle:{"position":"absolute","top":"10px","right":"3px"},attrs:{"id":"mrtopright"}},[_c('div',{attrs:{"id":"modality"}}),_c('div',{attrs:{"id":"protocol_name"}}),_c('div',{attrs:{"id":"description"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"infoLayer",staticStyle:{"position":"absolute","bottom":"3px","right":"3px"},attrs:{"id":"mrbottomright"}},[_c('div',{attrs:{"id":"frameRate"}}),_c('div',{attrs:{"id":"zoomText"}},[_vm._v("Zoom:")]),_c('div',{attrs:{"id":"sliceText"}},[_vm._v("Im:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"infoLayer",staticStyle:{"position":"absolute","bottom":"3px","left":"3px"},attrs:{"id":"mrbottomleft"}},[_c('div',{attrs:{"id":"wwwcLabel"}},[_vm._v("WW/WC:")]),_c('div',{attrs:{"id":"studyDate"}},[_vm._v("Study Date:")]),_c('div',{attrs:{"id":"studyTime"}},[_vm._v("Study Time:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"position":"fixed","top":"0","left":"0","width":"100%","z-index":"10000","background":"#2e303b","display":"block","overflow":"hidden"},attrs:{"id":"series-view"}},[_c('div',{attrs:{"id":"series-view-table"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('span',{attrs:{"id":"imageSilderWrapper"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"position":"fixed","top":"0","left":"0","width":"100%","z-index":"10000","background":"#2e303b","display":"block","overflow":"hidden"},attrs:{"id":"series-view"}},[_c('div',{attrs:{"id":"series-view-table"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"position":"absolute","z-index":"9999999","background":"transparent","right":"2px","top":"2px","font-size":"12px","color":"red","display":"none"},attrs:{"id":"loading"}},[_c('img',{attrs:{"src":require("./Public/svg_loader/svg-loaders/oval.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"position":"fixed","top":"0px","left":"0","width":"100%","z-index":"10001","background":"#000","display":"none","padding":"90px 10px","border":"1px solid #000","text-align":"center"},attrs:{"id":"qrcodeDlg"}},[_c('img',{attrs:{"width":"220","src":""}}),_c('div',{staticStyle:{"text-align":"center","font-size":"12px","color":"#fff","margin-top":"10px"}})])
}]

export { render, staticRenderFns }