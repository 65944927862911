<template>
  <div class="dcm-container">
    <div
      id="dicomImageWrapper"
      style="
        width: 500px;
        height: 800px;
        display: inline-block;
        position: relative;
        color: white;
      "
      oncontextmenu="return false"
      class="cornerstone-enabled-image"
      unselectable="on"
      onselectstart="return false;"
      onmousedown="return false;"
    >
      <div
        id="dicomImage"
        style="
          width: 500px;
          height: 800px;
          top: 0px;
          left: 0px;
          position: absolute;
        "
        @mousedown="showSlider"
      ></div>
      <div class="gg-640" style="">
        <div class="left-jt" id="left-jt">
          <img src="@/views/dcm/dcmicons/left-jt.png" alt="" />
        </div>
        <p id="xuliehao"></p>
        <div class="right-jt" id="right-jt">
          <img src="@/views/dcm/dcmicons/right-jt.png" alt="" />
        </div>
      </div>

      <div
        class="infoLayer"
        id="mrtopleft"
        style="position: absolute; top: 10px; left: 3px"
      >
        <div id="patientName"></div>
        <div id="patientId"></div>
        <div id="accession_number"></div>
      </div>
      <div
        class="infoLayer"
        id="mrtopright"
        style="position: absolute; top: 10px; right: 3px"
      >
        <div id="modality"></div>
        <div id="protocol_name"></div>
        <div id="description"></div>
      </div>
      <div
        class="infoLayer"
        id="mrbottomright"
        style="position: absolute; bottom: 3px; right: 3px"
      >
        <div id="frameRate"></div>
        <div id="zoomText">Zoom:</div>
        <div id="sliceText">Im:</div>
      </div>
      <div
        class="infoLayer"
        id="mrbottomleft"
        style="position: absolute; bottom: 3px; left: 3px"
      >
        <div id="wwwcLabel">WW/WC:</div>
        <div id="studyDate">Study Date:</div>
        <div id="studyTime">Study Time:</div>
      </div>
      <div id="alert-tip"></div>
    </div>
    <div
      id="series-view"
      style="
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 10000;
        background: #2e303b;
        display: block;
        overflow: hidden;
      "
    >
      <div id="series-view-table"></div>
    </div>
    <div id="footer-slider" style="display: none">
      <table style="width: 100%" cellpadding="0" cellspacing="0">
        <tr>
          <td width="36">
            <span
              id="footer-slider-play-ctrl"
              tapmode
              @click="doAction({ action1: 'Cine', action2: 'play' }, $event)"
              style="display: inline-block"
            >
              <i
                class="iconfont icon-bofang"
                style="font-size: 24px; height: 30px; line-height: 30px"
              ></i>
            </span>
            <span
              id="footer-slider-playing-ctrl"
              style="display: none"
              tapmode
              @click="doAction({ action1: 'Cine', action2: 'pause' }, $event)"
            >
              <i
                class="iconfont icon-zanting"
                style="font-size: 28px; height: 33px; line-height: 33px"
              ></i>
            </span>
          </td>
          <td align="right" style="padding-right: 10px">
            <table style="width: 100%" cellpadding="0" cellspacing="0">
              <tr>
                <td style="width: 40px" align="left">
                  <i
                    class="iconfont icon-fanhui1"
                    tapmode
                    @click="
                      doAction(
                        { action1: 'Cine', action2: 'previesImage' },
                        $event
                      )
                    "
                  >
                  </i>
                </td>
                <td>
                  <span id="imageSilderWrapper"></span>
                </td>
                <td style="width: 40px" align="right">
                  <i
                    class="iconfont icon-fanhui2"
                    tapmode
                    @click="
                      doAction(
                        { action1: 'Cine', action2: 'nextImage' },
                        $event
                      )
                    "
                  >
                  </i>
                </td>
                <td style="width: 26px; position: relative" align="right">
                  <div
                    class="frame-rate-ctrl"
                    tapmode
                    id="frame-rate-val"
                    @click="
                      doAction(
                        { action1: 'Cine', action2: 'showSetFrameRate' },
                        $event
                      )
                    "
                  >
                    1
                  </div>
                  <div
                    class="frame-rate-list"
                    id="frame-rate-list"
                    style="display: none"
                  >
                    <span
                      class="active"
                      tapmode
                      @click="
                        doAction(
                          { action1: 'Cine', action2: 'setFrameRate' },
                          $event
                        )
                      "
                      >1</span
                    >
                    <span
                      tapmode
                      @click="
                        doAction(
                          { action1: 'Cine', action2: 'setFrameRate' },
                          $event
                        )
                      "
                      >2</span
                    >
                    <span
                      tapmode
                      @click="
                        doAction(
                          { action1: 'Cine', action2: 'setFrameRate' },
                          $event
                        )
                      "
                      >3</span
                    >
                    <span
                      tapmode
                      @click="
                        doAction(
                          { action1: 'Cine', action2: 'setFrameRate' },
                          $event
                        )
                      "
                      >4</span
                    >
                    <span
                      tapmode
                      @click="
                        doAction(
                          { action1: 'Cine', action2: 'setFrameRate' },
                          $event
                        )
                      "
                      >5</span
                    >
                    <span
                      tapmode
                      @click="
                        doAction(
                          { action1: 'Cine', action2: 'setFrameRate' },
                          $event
                        )
                      "
                      >6</span
                    >
                    <span
                      tapmode
                      @click="
                        doAction(
                          { action1: 'Cine', action2: 'setFrameRate' },
                          $event
                        )
                      "
                      >7</span
                    >
                    <span
                      tapmode
                      @click="
                        doAction(
                          { action1: 'Cine', action2: 'setFrameRate' },
                          $event
                        )
                      "
                      >8</span
                    >
                    <span
                      tapmode
                      @click="
                        doAction(
                          { action1: 'Cine', action2: 'setFrameRate' },
                          $event
                        )
                      "
                      >9</span
                    >
                    <span
                      tapmode
                      @click="
                        doAction(
                          { action1: 'Cine', action2: 'setFrameRate' },
                          $event
                        )
                      "
                      >10</span
                    >
                  </div>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </div>
    <div id="footer2" style="bottom: 64px; display: none">
      <div id="footer_subfeature">
        <ul
          id="footer-sub-windowing"
          class="footer-sub-wrapper"
          style="display: block"
        >
          <li
            id="footer2_invplain"
            @click="
              doAction(
                { action1: 'Windowing', action2: 'setToInvplain' },
                $event
              )
            "
          >
            反色
          </li>
          <li
            @click="
              doAction(
                { action1: 'Windowing', action2: 'showPresetDialog' },
                $event
              )
            "
          >
            预设
          </li>
          <li
            @click="
              doAction(
                { action1: 'Windowing', action2: 'showPseudocolorDialog' },
                $event
              )
            "
          >
            伪彩
          </li>
        </ul>
        <ul id="footer-sub-zoomPan" class="footer-sub-wrapper">
          <li
            @click="
              doAction({ action1: 'ZoomPan', action2: 'rotateLeft' }, $event)
            "
          >
            左旋
          </li>
          <li
            @click="
              doAction({ action1: 'ZoomPan', action2: 'rotateRight' }, $event)
            "
          >
            右旋
          </li>
          <li
            @click="
              doAction({ action1: 'ZoomPan', action2: 'FreeRotate' }, $event)
            "
          >
            自由旋
          </li>
          <!--<li @click="doAction({action1:'ZoomPan',action2:'Magnify'}, $event)">放大镜</li>-->
        </ul>
        <ul id="footer-sub-measure" class="footer-sub-wrapper">
          <li
            id="footer2_line"
            @click="doAction({ action1: 'Measure', action2: 'line' }, $event)"
          >
            直线
          </li>
          <li
            id="footer2_rectangle"
            @click="
              doAction({ action1: 'Measure', action2: 'rectangle' }, $event)
            "
          >
            矩形
          </li>
          <li
            id="footer2_ellipse"
            @click="
              doAction({ action1: 'Measure', action2: 'ellipse' }, $event)
            "
          >
            椭圆
          </li>
          <!-- <li id="footer2_probe" @click="doAction({action1:'Measure',action2:'probe'}, $event)">探针</li>-->
          <li
            id="footer2_protractor"
            @click="
              doAction({ action1: 'Measure', action2: 'protractor' }, $event)
            "
          >
            角度
          </li>
          <li
            id="footer2_clear"
            @click="doAction({ action1: 'Measure', action2: 'clear' }, $event)"
          >
            清理
          </li>
        </ul>
        <!--<ul id="footer-sub-more" class="footer-sub-wrapper">-->
        <!--<li id="footer2_reset" @click="doAction({action1:'MoreFeatures',action2:'reset'},$event)">重置图像</li>-->
        <!--<li @click="doAction({action1:'MoreFeatures',action2:'showAnnotations'},$event)">隐藏注解</li>-->
        <!--<li @click="showQrcode()">二维码</li>-->
        <!--<li @click="joinMeeting($event)">加入会议</li>-->
        <!--</ul>-->
        <ul id="windowLevelPresetWrapper" class="footer-sub-wrapper">
          <li
            @click="
              doAction(
                { action1: 'Windowing', action2: 'PresetDefault' },
                $event
              )
            "
          >
            默认
          </li>
          <li
            @click="
              doAction({ action1: 'Windowing', action2: 'MinMax' }, $event)
            "
          >
            m/M
          </li>
          <li
            style="display: none"
            @click="
              doAction({ action1: 'Windowing', action2: 'Customized' }, $event)
            "
          >
            自定义
          </li>
          <li
            class="ct-preset"
            style="display: none"
            @click="
              doAction(
                { action1: 'Windowing', action2: 'PresetMediastinum' },
                $event
              )
            "
          >
            纵膈
          </li>
          <li
            class="ct-preset"
            style="display: none"
            @click="
              doAction({ action1: 'Windowing', action2: 'PresetLung' }, $event)
            "
          >
            肺
          </li>
          <li
            class="ct-preset"
            style="display: none"
            @click="
              doAction({ action1: 'Windowing', action2: 'PresetBone' }, $event)
            "
          >
            骨
          </li>
        </ul>
      </div>
    </div>
    <div id="footer1">
      <table id="footer-table" cellpadding="0" cellspacing="0">
        <tr>
          <td
            width="20%"
            tapmode
            @click="doAction({ action1: 'ToggleSeriesLayout' }, $event)"
            class="selected"
            id="footer-series-tool"
          >
            <i class="iconfont icon-xulie"></i>
            <span class="footer-title">序列</span>
          </td>
          <td
            id="footer1_windowing"
            width="20%"
            tapmode
            @click="
              doAction(
                { action1: 'SwitchFeatureGroup', action2: 'windowing' },
                $event
              )
            "
          >
            <i class="iconfont icon-tiaose"></i>
            <span class="footer-title">调窗</span>
          </td>
          <td
            id="footer1_zoompan"
            width="20%"
            tapmode
            @click="
              doAction(
                { action1: 'SwitchFeatureGroup', action2: 'zoomPan' },
                $event
              )
            "
          >
            <i class="iconfont icon-pingyi"></i>
            <span class="footer-title">移动</span>
          </td>
          <td
            id="footer1_measure"
            width="20%"
            tapmode
            @click="
              doAction(
                { action1: 'SwitchFeatureGroup', action2: 'measure' },
                $event
              )
            "
          >
            <i class="iconfont icon-celiang"></i>
            <span class="footer-title">测量</span>
          </td>
          <td
            id="footer1_more"
            width="20%"
            tapmode
            @click="doActionReset($event)"
          >
            <i class="iconfont icon-reset"></i>
            <span class="footer-title">重置</span>
          </td>
        </tr>
      </table>
    </div>
    <div
      id="series-view"
      style="
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 10000;
        background: #2e303b;
        display: block;
        overflow: hidden;
      "
    >
      <div id="series-view-table"></div>
    </div>

    <div
      id="loading"
      style="
        position: absolute;
        z-index: 9999999;
        background: transparent;
        right: 2px;
        top: 2px;
        font-size: 12px;
        color: red;
        display: none;
      "
    >
      <img src="./Public/svg_loader/svg-loaders/oval.svg" />
    </div>
    <div
      id="qrcodeDlg"
      style="
        position: fixed;
        top: 0px;
        left: 0;
        width: 100%;
        z-index: 10001;
        background: #000;
        display: none;
        padding: 90px 10px;
        border: 1px solid #000;
        text-align: center;
      "
    >
      <img width="220" src="" />

      <div
        style="
          text-align: center;
          font-size: 12px;
          color: #fff;
          margin-top: 10px;
        "
      ></div>
    </div>
		<van-overlay :show="agreementShow" :z-index="111111" class-name="agreementStyle" @click="show = false" >
      <div class="agreement-dialog">
        <img class="banner" src="~@/assets/img/dialog-banners.png">
        <p class="title">授权上传影像数据</p>
        <p class="desc">您的影像数据已经准备好，您可以在线查阅。</p>
        <div class="agreement-checked">
          <van-checkbox v-model="agreementType" shape="square"></van-checkbox>
          <p class="txt"><span @click="agreementType = !agreementType">我已阅读并同意</span><a @click="herfs">《授权上传影像协议》</a></p>
        </div>
        <div class="argeement-btn" @click="empower">
          立即授权
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script >
import $ from "./js/jquery-2.0.0.min";
// import jquery from "./js/jquery-2.0.0.min";
// import "./js/socket.io-1.1.0";
import "./Public/tg/slider/bootstrap-slider.js";
import "./Public/ext/fastclick/fastclick";
import "./Public/js/touch-emulator";
import "./Public/js/hammer.min.js";
import "./Public/js/iscroll";

import "./Public/tg/jia_dcm.min";
import "./Public/tg/jia_math.min";
import "./Public/tg/jia_tools.min";
import "./Public/tg/jia_wadoloader.min";

import "./Public/tg/jia_webloader?v=108";
import "./Public/js/dicomParser";
import "./Public/tg/gui";
import "./Public/tg/common";

import { doAction, doActionReset } from "./Public/tg/common";
// import "./Public/tg/index";
import "./js/index";
import { showSlider, viewInit, loadData } from "./js/index";
import { dcmApprove } from "@/api/dcm"
export default {
  data() {
    return {
      agreementShow:false,
      agreementType:false,
      doAction,
      showSlider,
      doActionReset,
    };
  },
	created () {
    document.title = "影像";
    viewInit(this)
    loadData();
	},
	destroyed(){
		document.getElementsByTagName("body")[0].style.backgroundColor = '#fff'
	},
  methods: {
    herfs(){
      this.$router.push({
        path:"/dcm/argeement"
      })
    },
    empower(){
      if(!this.agreementType){
        this.$toast("请勾选协议")
        return;
      }
      dcmApprove({
        studyInstanceUID2:this.$route.query.studyInstanceUID2,
        cusApproved:1
      }).then(res =>{
        if(res.data.errorCode == 0){
          this.$router.go(0)
          // console.log(zindex);
        }
        // console.log(window);
      })
    },
  },
};
</script>

<style lang="less" scoped>
@import url("./css/style_tg.css");
@import url("./css/iconfont.css");
@import url("./css/bootstrap-slider.css");
.dialog-tips{z-index: 11111111111111111 !important;}
.agreementStyle{
  display: flex;
  align-items: center;
  justify-content: center;
  .agreement-dialog{
    width: 9.4rem;
    background: #fff;
    border-radius: 0.4rem;
    padding: 0 1.2rem 1.2rem;
    .banner{
      width: 5.74rem;
      height: 4.68rem;
      display: block;
      margin: 0 auto;
      margin-top: -1.8rem;
    }
    .title{
      margin: 0.2rem auto 0.8rem auto;
      text-align: center;
      font-size: 0.72rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: #333333;
    }
    .desc{
      font-size: 0.64rem;
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 0.96rem;
    }
    .agreement-checked{
      margin: 0.4rem 0 0.8rem 0;
      display: flex;
      align-items: flex-start;
      p{
        margin-left: 0.4rem;
        font-size: 0.56rem;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 0.8rem;
        color: #666666;
        a{
          color: #49A7FF;
        }
      }
      .van-checkbox{
        overflow: unset;
        .van-icon{
          font-size: 0.56rem;
          border: 0.02rem solid #49a7ff;
          background: #ccc;
        }
      }
    }
    .argeement-btn{
      height: 1.76rem;
      background: linear-gradient(0deg, #499FFF 0%, #49BFFF 100%);
      border-radius: 0.88rem;
      font-size: 0.72rem;
      text-align: center;
      font-family: PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 1.76rem;
    }
  }
}
</style>