var cornerstone;
"undefined" === typeof cornerstone && (cornerstone = { internal: {}, rendering: {} });
(function(d) {
    d.disable = function(a) {
        if (void 0 === a) throw "disable: element element must not be undefined";
        for (var c = d.getEnabledElements(), b = 0; b < c.length; b++)
            if (c[b].element === a) {
                var f = { element: a };
                $(a).trigger("CornerstoneElementDisabled", f);
                c[b].element.removeChild(c[b].canvas);
                c.splice(b, 1);
                break
            }
    }
})(cornerstone);
(function(d, a) {
    a.displayImage = function(c, b, f) {
        if (void 0 === c) throw "displayImage: parameter element cannot be undefined";
        if (void 0 === b) throw "displayImage: parameter image cannot be undefined";
        var g = a.getEnabledElement(c);
        g.image = b;
        void 0 === g.viewport && (g.viewport = a.internal.getDefaultViewport(g.canvas, b));
        if (f)
            for (var e in f) null !== f[e] && (g.viewport[e] = f[e]);
        b = new Date;
        var h;
        void 0 !== g.lastImageTimeStamp && (h = (1E3 / (b.getTime() - g.lastImageTimeStamp)).toFixed());
        g.lastImageTimeStamp = b.getTime();
        h = { viewport: g.viewport, element: g.element, image: g.image, enabledElement: g, frameRate: h };
        d(g.element).trigger("CornerstoneNewImage", h);
        a.updateImage(c)
    }
})($, cornerstone);
(function(d, a) {
    a.draw = function(c) {
        c = a.getEnabledElement(c);
        if (void 0 === c.image) throw "draw: image has not been loaded yet";
        a.drawImage(c)
    }
})($, cornerstone);
(function(d, a) { a.drawInvalidated = function() { for (var c = a.getEnabledElements(), b = 0; b < c.length; b++) { var d = c[b];!0 === d.invalid && a.drawImage(d) } } })($, cornerstone);
(function(d) {
    d.enable = function(a) {
        if (void 0 === a) throw "enable: parameter element cannot be undefined";
        var c = document.createElement("canvas");
        a.appendChild(c);
        d.addEnabledElement({ element: a, canvas: c, image: void 0, invalid: !1, data: {} });
        d.resize(a, !0);
        return a
    }
})(cornerstone);
(function(d) {
    d.getElementData = function(a, c) { var b = d.getEnabledElement(a);!1 === b.data.hasOwnProperty(c) && (b.data[c] = {}); return b.data[c] };
    d.removeElementData = function(a, c) { delete d.getEnabledElement(a).data[c] }
})(cornerstone);
(function(d) {
    var a = [];
    d.getEnabledElement = function(c) {
        if (void 0 === c) throw "getEnabledElement: parameter element must not be undefined";
        for (var b = 0; b < a.length; b++)
            if (a[b].element == c) return a[b];
        throw "element not enabled";
    };
    d.addEnabledElement = function(c) {
        if (void 0 === c) throw "getEnabledElement: enabledElement element must not be undefined";
        a.push(c)
    };
    d.getEnabledElementsByImageId = function(c) {
        var b = [];
        a.forEach(function(a) { a.image && a.image.imageId === c && b.push(a) });
        return b
    };
    d.getEnabledElements = function() { return a }
})(cornerstone);
(function(d) {
    d.fitToWindow = function(a) {
        var c, b, f = d.getEnabledElement(a);
        0 === f.viewport.rotation || 180 === f.viewport.rotation ? (c = f.image.width, b = f.image.height) : (c = f.image.height, b = f.image.width);
        b = f.canvas.height / b;
        c = f.canvas.width / c;
        f.viewport.scale = c < b ? c : b;
        f.viewport.translation.x = 0;
        f.viewport.translation.y = 0;
        d.updateImage(a)
    }
})(cornerstone);
(function(d, a) { a.getDefaultViewportForImage = function(c, b) { var d = a.getEnabledElement(c); return a.internal.getDefaultViewport(d.canvas, b) } })($, cornerstone);
(function(d, a) { a.getImage = function(c) { return a.getEnabledElement(c).image } })($, cornerstone);
(function(d) {
    d.getPixels = function(a, c, b, f, g) {
        c = d.getStoredPixels(a, c, b, f, g);
        a = d.getEnabledElement(a);
        a = d.internal.getModalityLUT(a.image.slope, a.image.intercept, a.viewport.modalityLUT);
        return c.map(a)
    }
})(cornerstone);
(function(d) {
    d.getStoredPixels = function(a, c, b, f, g) {
        if (void 0 === a) throw "getStoredPixels: parameter element must not be undefined";
        c = Math.round(c);
        b = Math.round(b);
        a = d.getEnabledElement(a);
        for (var e = [], h = 0, n = a.image.getPixelData(), m = 0; m < g; m++)
            for (var k = 0; k < f; k++) {
                var l = (m + b) * a.image.columns + (k + c);
                e[h++] = n[l]
            }
        return e
    }
})(cornerstone);
(function(d) { d.getViewport = function(a) { a = d.getEnabledElement(a).viewport; return void 0 === a ? void 0 : { scale: a.scale, translation: { x: a.translation.x, y: a.translation.y }, voi: { windowWidth: a.voi.windowWidth, windowCenter: a.voi.windowCenter }, invert: a.invert, pixelReplication: a.pixelReplication, rotation: a.rotation, hflip: a.hflip, vflip: a.vflip, modalityLUT: a.modalityLUT, voiLUT: a.voiLUT } } })(cornerstone);
(function(d) {
    function a() {
        function a(b, c) { return b.timeStamp > c.timeStamp ? -1 : b.timeStamp < c.timeStamp ? 1 : 0 }
        if (!(g <= f)) {
            for (b.sort(a); g > f;) {
                var h = b[b.length - 1];
                g -= h.sizeInBytes;
                delete c[h.imageId];
                h.imagePromise.reject();
                b.pop();
                $(d).trigger("CornerstoneImageCachePromiseRemoved", { imageId: h.imageId })
            }
            h = d.imageCache.getCacheInfo();
            $(d).trigger("CornerstoneImageCacheFull", h)
        }
    }
    var c = {},
        b = [],
        f = 1073741824,
        g = 0;
    d.imageCache = {
        putImagePromise: function(d, f) {
            if (void 0 === d) throw "getImagePromise: imageId must not be undefined";
            if (void 0 === f) throw "getImagePromise: imagePromise must not be undefined";
            if (!0 === c.hasOwnProperty(d)) throw "putImagePromise: imageId already in cache";
            var n = { loaded: !1, imageId: d, imagePromise: f, timeStamp: new Date, sizeInBytes: 0 };
            c[d] = n;
            b.push(n);
            f.then(function(b) {
                n.loaded = !0;
                if (void 0 === b.sizeInBytes) throw "putImagePromise: image does not have sizeInBytes property or";
                if (void 0 === b.sizeInBytes.toFixed) throw "putImagePromise: image.sizeInBytes is not a number";
                n.sizeInBytes = b.sizeInBytes;
                g += n.sizeInBytes;
                a()
            })
        },
        getImagePromise: function(b) {
            if (void 0 === b) throw "getImagePromise: imageId must not be undefined";
            b = c[b];
            if (void 0 !== b) return b.timeStamp = new Date, b.imagePromise
        },
        removeImagePromise: function(a) {
            if (void 0 === a) throw "removeImagePromise: imageId must not be undefined";
            var d = c[a];
            if (void 0 === d) throw "removeImagePromise: imageId must not be undefined";
            b.splice(b.indexOf(d), 1);
            g -= d.sizeInBytes;
            delete c[a];
            return d.imagePromise
        },
        setMaximumSizeBytes: function(b) {
            if (void 0 === b) throw "setMaximumSizeBytes: parameter numBytes must not be undefined";
            if (void 0 === b.toFixed) throw "setMaximumSizeBytes: parameter numBytes must be a number";
            f = b;
            a()
        },
        getCacheInfo: function() { return { maximumSizeInBytes: f, cacheSizeInBytes: g, numberOfImagesCached: b.length } },
        purgeCache: function() {
            for (; 0 < b.length;) {
                var a = b.pop();
                delete c[a.imageId];
                a.imagePromise.reject()
            }
            g = 0
        },
        cachedImages: b
    }
})(cornerstone);
(function(d, a) {
    function c(c) {
        var e = c.indexOf(":"),
            e = c.substring(0, e),
            e = b[e];
        if (void 0 === e || null === e) { if (void 0 !== f) return c = f(c) } else return c = e(c), c.then(function(b) { d(a).trigger("CornerstoneImageLoaded", { image: b }) }), c
    }
    var b = {},
        f;
    a.loadImage = function(b) {
        if (void 0 === b) throw "loadImage: parameter imageId must not be undefined";
        var d = a.imageCache.getImagePromise(b);
        if (void 0 !== d) return d;
        d = c(b);
        if (void 0 === d) throw "loadImage: no image loader for imageId";
        return d
    };
    a.loadAndCacheImage = function(b) {
        if (void 0 === b) throw "loadAndCacheImage: parameter imageId must not be undefined";
        var d = a.imageCache.getImagePromise(b);
        if (void 0 !== d) return d;
        d = c(b);
        if (void 0 === d) throw "loadAndCacheImage: no image loader for imageId";
        a.imageCache.putImagePromise(b, d);
        return d
    };
    a.registerImageLoader = function(c, a) { b[c] = a };
    a.registerUnknownImageLoader = function(b) {
        var c = f;
        f = b;
        return c
    }
})($, cornerstone);
(function(d) {
    d.internal.calculateTransform = function(a, c) {
        var b = new d.internal.Transform;
        b.translate(a.canvas.width / 2, a.canvas.height / 2);
        var f = a.viewport.rotation;
        0 !== f && b.rotate(f * Math.PI / 180);
        var g = a.viewport.scale,
            e = a.viewport.scale;
        a.image.rowPixelSpacing < a.image.columnPixelSpacing ? g *= a.image.columnPixelSpacing / a.image.rowPixelSpacing : a.image.columnPixelSpacing < a.image.rowPixelSpacing && (e *= a.image.rowPixelSpacing / a.image.columnPixelSpacing);
        b.scale(g, e);
        0 !== f && b.rotate(-f * Math.PI / 180);
        b.translate(a.viewport.translation.x, a.viewport.translation.y);
        0 !== f && b.rotate(f * Math.PI / 180);
        void 0 !== c && b.scale(c, c);
        a.viewport.hflip && b.scale(-1, 1);
        a.viewport.vflip && b.scale(1, -1);
        b.translate(-a.image.width / 2, -a.image.height / 2);
        return b
    }
})(cornerstone);
(function(d, a) {
    function c(b, c) {
        var a = new Date;
        b.image.render(b, c);
        var e = b.canvas.getContext("2d"),
            a = { viewport: b.viewport, element: b.element, image: b.image, enabledElement: b, canvasContext: e, renderTimeInMs: new Date - a };
        isPseudocolor && setPseudocolor();
        d(b.element).trigger("CornerstoneImageRendered", a);
        b.invalid = !1
    }
    a.internal.drawImage = c;
    a.drawImage = c
})($, cornerstone);
(function(d) {
    function a(b, c, a, e, h, n) {
        void 0 === b.lut && (b.lut = new Int16Array(b.maxPixelValue - Math.min(b.minPixelValue, 0) + 1));
        var m = b.lut,
            k = b.maxPixelValue,
            l = b.minPixelValue;
        h = d.internal.getModalityLUT(b.slope, b.intercept, h);
        c = d.internal.getVOILUT(c, a, n);
        a = 0;
        0 > l && (a = l);
        for (b = b.minPixelValue; b <= k; b++) l = h(b), l = c(l), l = Math.min(Math.max(l, 0), 255), m[b + -a] = e ? Math.round(255 - l) : Math.round(l);
        return m
    }

    function c(b, c, d, e, h, n) {
        if (h || n) return a(b, c, d, e, h, n);
        void 0 === b.lut && (b.lut = new Int16Array(b.maxPixelValue - Math.min(b.minPixelValue, 0) + 1));
        h = b.lut;
        n = b.maxPixelValue;
        var m = b.minPixelValue,
            k = b.slope,
            l = b.intercept,
            p = 0;
        0 > m && (p = m);
        if (!0 === e)
            for (b = b.minPixelValue; b <= n; b++) e = b * k + l, e = 255 * ((e - d) / c + .5), e = Math.min(Math.max(e, 0), 255), h[b + -p] = Math.round(255 - e);
        else
            for (b = b.minPixelValue; b <= n; b++) e = b * k + l, e = 255 * ((e - d) / c + .5), e = Math.min(Math.max(e, 0), 255), h[b + -p] = Math.round(e)
    }
    d.internal.generateLutNew = a;
    d.internal.generateLut = c;
    d.generateLutNew = a;
    d.generateLut = c
})(cornerstone);
(function(d) {
    function a(c, b) {
        if (void 0 === c) throw "getDefaultViewport: parameter canvas must not be undefined";
        if (void 0 === b) throw "getDefaultViewport: parameter image must not be undefined";
        var a = { scale: 1, translation: { x: 0, y: 0 }, voi: { windowWidth: b.windowWidth, windowCenter: b.windowCenter }, invert: b.invert, pixelReplication: !1, rotation: 0, hflip: !1, vflip: !1, modalityLUT: b.modalityLUT, voiLUT: b.voiLUT },
            d = c.height / b.rows,
            e = c.width / b.columns;
        a.scale = e < d ? e : d;
        return a
    }
    d.internal.getDefaultViewport = a;
    d.getDefaultViewport = a
})(cornerstone);
(function(d) { d.internal.getTransform = function(a) { return d.internal.calculateTransform(a) } })(cornerstone);
(function(d, a) {
    a.drawImage = a.internal.drawImage;
    a.generateLut = a.internal.generateLut;
    a.storedPixelDataToCanvasImageData = a.internal.storedPixelDataToCanvasImageData;
    a.storedColorPixelDataToCanvasImageData = a.internal.storedColorPixelDataToCanvasImageData
})($, cornerstone);
(function(d) {
    function a(b, c) { return function(a) { return a * b + c } }

    function c(b) {
        var c = b.lut[0],
            a = b.lut[b.lut.length - 1],
            d = b.firstValueMapped + b.lut.length;
        return function(h) { return h < b.firstValueMapped ? c : h >= d ? a : b.lut[h] }
    }
    d.internal.getModalityLUT = function(b, d, g) { return g ? c(g) : a(b, d) }
})(cornerstone);
(function(d) {
    function a(c, b, a) {
        var d = c.minPixelValue,
            e = 0,
            h = 0,
            n = c.width * c.height * 4;
        c = c.getPixelData();
        if (0 > d)
            for (; h < n;) a[e++] = b[c[h++] + -d], a[e++] = b[c[h++] + -d], a[e] = b[c[h] + -d], h += 2, e += 2;
        else
            for (; h < n;) a[e++] = b[c[h++]], a[e++] = b[c[h++]], a[e] = b[c[h]], h += 2, e += 2
    }
    d.internal.storedColorPixelDataToCanvasImageData = a;
    d.storedColorPixelDataToCanvasImageData = a
})(cornerstone);
(function(d) {
    function a(a, b, d) {
        var g = a.getPixelData();
        a = a.minPixelValue;
        var e = 3,
            h = 0,
            n = g.length;
        if (0 > a)
            for (; h < n;) d[e] = b[g[h++] + -a], e += 4;
        else
            for (; h < n;) d[e] = b[g[h++]], e += 4
    }
    d.internal.storedPixelDataToCanvasImageData = a;
    d.storedPixelDataToCanvasImageData = a
})(cornerstone);
(function(d) {
    function a() { this.reset() }
    a.prototype.reset = function() { this.m = [1, 0, 0, 1, 0, 0] };
    a.prototype.clone = function() {
        var c = new a;
        c.m[0] = this.m[0];
        c.m[1] = this.m[1];
        c.m[2] = this.m[2];
        c.m[3] = this.m[3];
        c.m[4] = this.m[4];
        c.m[5] = this.m[5];
        return c
    };
    a.prototype.multiply = function(a) {
        var b = this.m[1] * a.m[0] + this.m[3] * a.m[1],
            d = this.m[0] * a.m[2] + this.m[2] * a.m[3],
            g = this.m[1] * a.m[2] + this.m[3] * a.m[3],
            e = this.m[0] * a.m[4] + this.m[2] * a.m[5] + this.m[4],
            h = this.m[1] * a.m[4] + this.m[3] * a.m[5] + this.m[5];
        this.m[0] = this.m[0] * a.m[0] + this.m[2] * a.m[1];
        this.m[1] = b;
        this.m[2] = d;
        this.m[3] = g;
        this.m[4] = e;
        this.m[5] = h
    };
    a.prototype.invert = function() {
        var a = 1 / (this.m[0] * this.m[3] - this.m[1] * this.m[2]),
            b = -this.m[1] * a,
            d = -this.m[2] * a,
            g = this.m[0] * a,
            e = a * (this.m[2] * this.m[5] - this.m[3] * this.m[4]),
            h = a * (this.m[1] * this.m[4] - this.m[0] * this.m[5]);
        this.m[0] = this.m[3] * a;
        this.m[1] = b;
        this.m[2] = d;
        this.m[3] = g;
        this.m[4] = e;
        this.m[5] = h
    };
    a.prototype.rotate = function(a) {
        var b = Math.cos(a);
        a = Math.sin(a);
        var d = this.m[1] * b + this.m[3] * a,
            g = this.m[0] * -a + this.m[2] * b,
            e = this.m[1] * -a + this.m[3] * b;
        this.m[0] = this.m[0] * b + this.m[2] * a;
        this.m[1] = d;
        this.m[2] = g;
        this.m[3] = e
    };
    a.prototype.translate = function(a, b) {
        this.m[4] += this.m[0] * a + this.m[2] * b;
        this.m[5] += this.m[1] * a + this.m[3] * b
    };
    a.prototype.scale = function(a, b) {
        this.m[0] *= a;
        this.m[1] *= a;
        this.m[2] *= b;
        this.m[3] *= b
    };
    a.prototype.transformPoint = function(a, b) {
        var d = a,
            g = b;
        a = d * this.m[0] + g * this.m[2] + this.m[4];
        b = d * this.m[1] + g * this.m[3] + this.m[5];
        return { x: a, y: b }
    };
    d.internal.Transform = a
})(cornerstone);
(function(d) {
    function a(a, c) { return function(d) { return 255 * ((d - c) / a + .5) } }

    function c(a) {
        var c = a.numBitsPerEntry - 8,
            d = a.lut[0] >> c,
            e = a.lut[a.lut.length - 1] >> c,
            h = a.firstValueMapped + a.lut.length - 1;
        return function(n) { return n < a.firstValueMapped ? d : n >= h ? e : a.lut[n - a.firstValueMapped] >> c }
    }
    d.internal.getVOILUT = function(b, d, g) { return g ? c(g) : a(b, d) }
})(cornerstone);
(function(d) {
    d.invalidate = function(a) {
        var c = d.getEnabledElement(a);
        c.invalid = !0;
        a = { element: a };
        $(c.element).trigger("CornerstoneInvalidated", a)
    }
})(cornerstone);
(function(d) { d.invalidateImageId = function(a) { d.getEnabledElementsByImageId(a).forEach(function(a) { d.drawImage(a, !0) }) } })(cornerstone);
(function(d) {
    d.pageToPixel = function(a, c, b) {
        var f = d.getEnabledElement(a);
        if (void 0 === f.image) throw "image has not been loaded yet";
        a = a.getBoundingClientRect();
        c = c - a.left - window.pageXOffset;
        b = b - a.top - window.pageYOffset;
        f = d.internal.getTransform(f);
        f.invert();
        return f.transformPoint(c, b)
    }
})(cornerstone);
(function(d) { d.pixelToCanvas = function(a, c) { var b = d.getEnabledElement(a); return d.internal.getTransform(b).transformPoint(c.x, c.y) } })(cornerstone);
(function(d) {
    function a(a, n) {
        if (void 0 === a) throw "drawImage: enabledElement parameter must not be undefined";
        var m = a.image;
        if (void 0 === m) throw "drawImage: image must be loaded before it can be drawn";
        var k = a.canvas.getContext("2d");
        k.setTransform(1, 0, 0, 1, 0, 0);
        k.fillStyle = "black";
        k.fillRect(0, 0, a.canvas.width, a.canvas.height);
        !0 === a.viewport.pixelReplication ? (k.imageSmoothingEnabled = !1, k.mozImageSmoothingEnabled = !1) : (k.imageSmoothingEnabled = !0, k.mozImageSmoothingEnabled = !0);
        k.save();
        d.setToPixelCoordinateSystem(a, k);
        var l;
        if (a.viewport.voi.windowWidth === a.image.windowWidth && a.viewport.voi.windowCenter === a.image.windowCenter && !1 === a.viewport.invert) l = m.getCanvas();
        else {
            if (!1 !== (m.imageId !== g || e.windowCenter !== a.viewport.voi.windowCenter || e.windowWidth !== a.viewport.voi.windowWidth || e.invert !== a.viewport.invert || e.rotation !== a.viewport.rotation || e.hflip !== a.viewport.hflip || e.vflip !== a.viewport.vflip ? !0 : !1) || !0 === n) {
                if (c.width !== m.width || c.height != m.height) c.width = m.width, c.height = m.height, b = c.getContext("2d"), b.fillStyle = "white", b.fillRect(0, 0, c.width, c.height), f = b.getImageData(0, 0, m.width, m.height);
                l = a.viewport;
                if (void 0 === m.lut || m.lut.windowCenter !== l.voi.windowCenter || m.lut.windowWidth !== l.voi.windowWidth || m.lut.invert !== l.invert) d.generateLut(m, l.voi.windowWidth, l.voi.windowCenter, l.invert), m.lut.windowWidth = l.voi.windowWidth, m.lut.windowCenter = l.voi.windowCenter, m.lut.invert = l.invert;
                l = m.lut;
                d.storedColorPixelDataToCanvasImageData(m, l, f.data);
                b.putImageData(f, 0, 0)
            }
            l = c
        }
        k.drawImage(l, 0, 0, m.width, m.height, 0, 0, m.width, m.height);
        k.restore();
        g = m.imageId;
        e.windowCenter = a.viewport.voi.windowCenter;
        e.windowWidth = a.viewport.voi.windowWidth;
        e.invert = a.viewport.invert;
        e.rotation = a.viewport.rotation;
        e.hflip = a.viewport.hflip;
        e.vflip = a.viewport.vflip
    }
    var c = document.createElement("canvas"),
        b, f, g, e = {};
    d.rendering.colorImage = a;
    d.renderColorImage = a
})(cornerstone);
(function(d) {
    function a(a, b) { return a || b ? a && b ? a.id !== b.id : !1 : !0 }

    function c(c, m) {
        if (void 0 === c) throw "drawImage: enabledElement parameter must not be undefined";
        var k = c.image;
        if (void 0 === k) throw "drawImage: image must be loaded before it can be drawn";
        var l = c.canvas.getContext("2d");
        l.setTransform(1, 0, 0, 1, 0, 0);
        l.fillStyle = "black";
        l.fillRect(0, 0, c.canvas.width, c.canvas.height);
        !0 === c.viewport.pixelReplication ? (l.imageSmoothingEnabled = !1, l.mozImageSmoothingEnabled = !1) : (l.imageSmoothingEnabled = !0, l.mozImageSmoothingEnabled = !0);
        d.setToPixelCoordinateSystem(c, l);
        var p;
        if (!1 !== (k.imageId !== e || h.windowCenter !== c.viewport.voi.windowCenter || h.windowWidth !== c.viewport.voi.windowWidth || h.invert !== c.viewport.invert || h.rotation !== c.viewport.rotation || h.hflip !== c.viewport.hflip || h.vflip !== c.viewport.vflip || h.modalityLUT !== c.viewport.modalityLUT || h.voiLUT !== c.viewport.voiLUT ? !0 : !1) || !0 === m) {
            if (b.width !== k.width || b.height != k.height) b.width = k.width, b.height = k.height, f = b.getContext("2d"), f.fillStyle = "white", f.fillRect(0, 0, b.width, b.height), g = f.getImageData(0, 0, k.width, k.height);
            p = c.viewport;
            void 0 !== k.lut && k.lut.windowCenter === p.voi.windowCenter && k.lut.windowWidth === p.voi.windowWidth && a(k.lut.modalityLUT, p.modalityLUT) && a(k.lut.voiLUT, p.voiLUT) && k.lut.invert === p.invert && !0 !== m || (d.generateLut(k, p.voi.windowWidth, p.voi.windowCenter, p.invert, p.modalityLUT, p.voiLUT), k.lut.windowWidth = p.voi.windowWidth, k.lut.windowCenter = p.voi.windowCenter, k.lut.invert = p.invert, k.lut.voiLUT = p.voiLUT, k.lut.modalityLUT = p.modalityLUT);
            p = k.lut;
            d.storedPixelDataToCanvasImageData(k, p, g.data);
            f.putImageData(g, 0, 0)
        }
        p = b;
        l.drawImage(p, 0, 0, k.width, k.height, 0, 0, k.width, k.height);
        e = k.imageId;
        h.windowCenter = c.viewport.voi.windowCenter;
        h.windowWidth = c.viewport.voi.windowWidth;
        h.invert = c.viewport.invert;
        h.rotation = c.viewport.rotation;
        h.hflip = c.viewport.hflip;
        h.vflip = c.viewport.vflip;
        h.modalityLUT = c.viewport.modalityLUT;
        h.voiLUT = c.viewport.voiLUT
    }
    var b = document.createElement("canvas"),
        f, g, e, h = {};
    d.rendering.grayscaleImage = c;
    d.renderGrayscaleImage = c
})(cornerstone);
(function(d) {
    function a(a, b) {
        if (void 0 === a) throw "drawImage: enabledElement parameter must not be undefined";
        var f = a.image;
        if (void 0 === f) throw "drawImage: image must be loaded before it can be drawn";
        var g = a.canvas.getContext("2d");
        g.setTransform(1, 0, 0, 1, 0, 0);
        g.fillStyle = "black";
        g.fillRect(0, 0, a.canvas.width, a.canvas.height);
        !0 === a.viewport.pixelReplication ? (g.imageSmoothingEnabled = !1, g.mozImageSmoothingEnabled = !1) : (g.imageSmoothingEnabled = !0, g.mozImageSmoothingEnabled = !0);
        d.setToPixelCoordinateSystem(a, g);
        a.viewport.voi.windowWidth === a.image.windowWidth && a.viewport.voi.windowCenter === a.image.windowCenter && !1 === a.viewport.invert ? g.drawImage(f.getImage(), 0, 0, f.width, f.height, 0, 0, f.width, f.height) : d.renderColorImage(a, b)
    }
    d.rendering.webImage = a;
    d.renderWebImage = a
})(cornerstone);
(function(d) {
    d.reset = function(a) {
        var c = d.getEnabledElement(a),
            b = d.internal.getDefaultViewport(c.canvas, c.image);
        c.viewport = b;
        d.updateImage(a)
    }
})(cornerstone);
(function(d) {
    d.resize = function(a, c) {
        var b = d.getEnabledElement(a),
            f = b.canvas;
        f.width = a.clientWidth;
        f.height = a.clientHeight;
        f.style.width = a.clientWidth + "px";
        f.style.height = a.clientHeight + "px";
        void 0 !== b.image && (!0 === c ? d.fitToWindow(a) : d.updateImage(a))
    }
})(cornerstone);
(function(d) {
    d.setToPixelCoordinateSystem = function(a, c, b) {
        if (void 0 === a) throw "setToPixelCoordinateSystem: parameter enabledElement must not be undefined";
        if (void 0 === c) throw "setToPixelCoordinateSystem: parameter context must not be undefined";
        a = d.internal.calculateTransform(a, b);
        c.setTransform(a.m[0], a.m[1], a.m[2], a.m[3], a.m[4], a.m[5], a.m[6])
    }
})(cornerstone);
(function(d) {
    d.setViewport = function(a, c) {
        var b = d.getEnabledElement(a);
        b.viewport.scale = c.scale;
        b.viewport.translation.x = c.translation.x;
        b.viewport.translation.y = c.translation.y;
        b.viewport.voi.windowWidth = c.voi.windowWidth;
        b.viewport.voi.windowCenter = c.voi.windowCenter;
        b.viewport.invert = c.invert;
        b.viewport.pixelReplication = c.pixelReplication;
        b.viewport.rotation = c.rotation;
        b.viewport.hflip = c.hflip;
        b.viewport.vflip = c.vflip;
        b.viewport.modalityLUT = c.modalityLUT;
        b.viewport.voiLUT = c.voiLUT;
        1E-6 > b.viewport.voi.windowWidth && (b.viewport.voi.windowWidth = 1E-6);
        1E-4 > b.viewport.scale && (b.viewport.scale = .25);
        if (360 === b.viewport.rotation || -360 === b.viewport.rotation) b.viewport.rotation = 0;
        d.updateImage(a)
    }
})(cornerstone);
(function(d) {
    d.updateImage = function(a, c) {
        var b = d.getEnabledElement(a);
        if (void 0 === b.image) throw "updateImage: image has not been loaded yet";
        d.drawImage(b, c)
    }
})(cornerstone)
window.cornerstone = cornerstone;