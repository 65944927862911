import IScroll from "../../Public/js/iscroll"
import { showSlider, specifyImageIndex } from "../../js/index"
String.prototype.format = function(args) {
        var result = this;
        if (arguments.length > 0) {
            if (arguments.length == 1 && typeof(args) == "object") {
                for (var key in args) {
                    if (args[key] != undefined) {
                        var reg = new RegExp("({" + key + "})", "g");
                        result = result.replace(reg, args[key]);
                    }
                }
            } else {
                for (var i = 0; i < arguments.length; i++) {
                    if (arguments[i] != undefined) {
                        var reg = new RegExp("({)" + i + "(})", "g");
                        result = result.replace(reg, arguments[i]);
                    }
                }
            }
        }
        return result;
    }
    //根据序列编号从缓存中获取相关序列对象
function getSeriesFromStudy(seriesNumber) {
    var series = null;
    for (var i = 0; i < study.series.length; i++) {
        if (study.series[i].series_number == seriesNumber) {
            series = study.series[i];
        }
    }
    return series;
}

//检查
export function renderStudyLayout(seriesInstanceUID) {

    var html = [];
    var winWidth = $(window).width(),
        width = winWidth * .3;
    var seriesThumbnailEle = $('#series-view-table');
    var tpl = '<div class="series-view-item" data-num="{2}" data-index="{4}" data-suid="{6}">' +
        '<div class="thumb" style="height:' + width + 'px;background-image: url({0}{1}/{2}_t.jpg)"></div><div class="info">' +
        '<span class="series-num">({5}幅)</span><span class="series-name">{3}</span></div></div>';

    var len = study.series.length;
    for (var i = 0; i < len; i++) {
        if (seriesInstanceUID) {
            if (seriesInstanceUID != study.series[i].series_instance_uid) {
                continue
            }
        }

        var desc = study.series[i].series_description;
        var instance_ids = study.series[i].instance_ids,
            instance_idsLength = 0;
        if (instance_ids) {
            instance_ids = instance_ids.split(',');
            instance_idsLength = instance_ids.length;
        }
        var h = tpl.format(
            domainName,
            // study.storage.replace('dicomweb://', window.location.protocol + '//'),
            study.study_instance_uid,
            study.series[i].series_number,
            desc,
            i + 1,
            instance_idsLength,
            study.series[i].series_instance_uid);
        html.push(h);
    }
    seriesThumbnailEle.html(html.join(''));
    setTimeout(function() {
        new IScroll('#series-view', {
            tap: true,
            scrollY: true,
            scrollX: false,
            mouseWheel: true
        });
        $('#series-view').on('tap', '.series-view-item', function(e) {
            var $target = $(e.currentTarget);

            //gaolixin 设置被选中的序列的背景---start
            $('.series-view-item').css('background-color', '#383b47');
            $target.css('background-color', '#3e98ff');
            //gaolixin 设置被选中的序列的背景---end

            switchSeries($target.data('num'), $target.data('index'), undefined, $target.data('suid'));
            showSlider();
            //

            $('#alert-tip').css('display', 'none');
            var index = $target.data('index');
            index -= 1;
            var str = (index + 1) + '/' + len
            $('#xuliehao').text(str);
            $('#right-jt').on('click', function(e) {
                if (len - 1 == index) {
                    $('#alert-tip').css('display', 'block');
                    $('#alert-tip').text('已经是最后一个序列了')
                    setTimeout(function() {
                        $('#alert-tip').css('display', 'none');
                    }, 1000)
                } else {
                    index++;
                    var item = $('.series-view-item:eq(' + index + ')');
                    switchSeries(item.data('num'), item.data('index'), undefined, item.data('suid'));
                    showSlider();
                }
                str = (index + 1) + '/' + len
                $('#xuliehao').text(str)
                e.stopPropagation()

            });
            $('#left-jt').on('click', function(e) {

                if (index == 0) {
                    $('#alert-tip').css('display', 'block');
                    $('#alert-tip').text('已经是第一个序列了')
                    setTimeout(function() {
                        $('#alert-tip').css('display', 'none');
                    }, 1000)
                } else {
                    index--;
                    var item = $('.series-view-item:eq(' + index + ')');
                    switchSeries(item.data('num'), item.data('index'), undefined, item.data('suid'));
                    showSlider();
                }
                str = (index + 1) + '/' + len
                $('#xuliehao').text(str)
                e.stopPropagation()
            });


        })
    }, 0)
}


/**
 * gaolixin, dicom滚轮播放
 * @param e
 */
export function onMousewheel(e) {
    if (imageSilder) {

    } else {
        return;
    }

    var delta = (e.originalEvent.wheelDelta && (e.originalEvent.wheelDelta > 0 ? 1 : -1)) || // chrome & ie
        (e.originalEvent.detail && (e.originalEvent.detail > 0 ? -1 : 1)); // firefox

    if (delta > 0) {
        // 向上滚
        console.log("wheelup");
    } else if (delta < 0) {
        // 向下滚
        console.log("wheeldown");
    }

    var cv = imageSilder.slider("getValue");
    cv = cv - delta;

    var max = imageSilder.slider("getAttribute", "max");
    if (cv > max) {
        cv = max;
    }
    if (cv < 1) {
        cv = 1;
    }
    imageSilder.slider('setValue', cv, true);
}

function set_hash(value) {
    // window.location.hash = '?' + value;
}


//切换序列
function switchSeries(seriesNumber, indexInStudy, isHideSeriesView, suid) {
    if (!imageElement) {
        window.imageElement = $('#dicomImage').get(0);
    }
    if (void 0 === isHideSeriesView) {
        isHideSeriesView = true;
    }

    if (suid) {
        set_hash(suid);
    }

    pausePlayImage();
    try {
        cornerstone.disable(imageElement);
    } catch (ex) {
        throw ex
    }
    try {
        cornerstone.enable(imageElement);
    } catch (ex) {
        throw ex
    }

    currentSeries = getSeriesFromStudy(seriesNumber);
    //$('#header_title').html(currentSeries.series_description);
    //$('#header_title_index').html('({0}/{1})'.format(indexInStudy, study.series.length));

    if (isHideSeriesView) {
        $('#mask,#loading').show();
        $('#footer-series-tool').removeClass('selected');
        hideSeriesView();
    }

    imageIds = [];

    var stack = {
        currentImageIdIndex: 0,
        imageIds: [],
        seriesDescription: currentSeries.series_desc,
        stackId: currentSeries.id,
        seriesIndex: 0,
        frameRate: parseInt(currentSeries.frame_rate)
    };

    if (currentSeries.frame_numbers != "0" && currentSeries.frame_numbers != "1") {
        var numberOfFrames = parseInt(currentSeries.frame_numbers);
        for (var i = 0; i < numberOfFrames; i++) {
            var imageId = '{0}{1}/{2}_{3}.png'.format(
                // study.storage.replace('dicomweb://', window.location.protocol + '//')
                domainName, study.study_instance_uid, currentSeries.series_number, i);
            stack.imageIds.push(imageId);
        }
    } else {
        if (study.source == 'jpg' || study.modality == 'MG' || study.modality == 'DR' || study.modality == 'CR' || study.modality == 'RF' || study.modality == 'DX') {
            for (var i = 0; i < currentSeries.urls.length; i++) {
                stack.imageIds.push(currentSeries.urls[i].replace('dicomweb://', window.location.protocol + '//'));
            }
        } else {
            for (var i = 0; i < currentSeries.urls.length; i++) {
                stack.imageIds.push(currentSeries.urls[i]);
            }
        }
    }
    imageIds = stack.imageIds;
    $('#imageSilderWrapper').html('<input id="image-slider" type="text"/>');
    imageSilder = $("#image-slider").slider({
        tooltip: 'none',
        min: 1,
        max: stack.imageIds.length,
        value: 1
    });
    imageSilder.on('slideStop', function(ev) {
        pausePlayImage();
        isSwitchSliceUseSlider = true;
        var currentImageIndex = parseInt(imageSilder.val());

        specifyImageIndex(currentImageIndex);
    });
    imageSilder.on('slide', function(ev) {
        pausePlayImage();
        isSwitchSliceUseSlider = true;
        var currentImageIndex = parseInt(imageSilder.val());
        specifyImageIndex(currentImageIndex);
    });

    cornerstone.loadAndCacheImage(imageIds[0]).then(function(image) {
        $('#mask,#loading').hide();
        try {
            cornerstone.displayImage(imageElement, image);
            cornerstone.fitToWindow(imageElement);
            // Set the stack as tool state
            cornerstoneTools.addStackStateManager(imageElement, ['stack']);
        } catch (error) {
            console.log(error);
        }
        cornerstoneTools.addToolState(imageElement, 'stack', stack);
        cornerstoneTools.stackPrefetch.enable(imageElement);

        if (meetingEnabled) return false;
        cornerstoneTools.orientationMarkers.enable(imageElement);
        cornerstoneTools.touchInput.enable(imageElement);
        // Prefetch the remaining images in the stack (?)
        // Play clip if stack is a movie (has framerate)
        if (stack.frameRate != 0) {
            $("#frame-rate-val").text(stack.frameRate>10?10:stack.frameRate)
            //cornerstoneTools.playClip(imageElement, stack.frameRate);
            frameRate = stack.frameRate;
            //playImage();
        }

        // Enable all tools we want to use with this element
        cornerstoneTools.wwwcTouchDrag.activate(imageElement);
        cornerstoneTools.zoomTouchPinch.activate(imageElement);
        //cornerstoneTools.rotateTouch.activate(imageElement);
        //cornerstoneTools.panMultiTouch.activate(imageElement);
        //if(isPseudocolor) setPseudocolor();


        cornerstoneTools.mouseInput.enable(imageElement);
        //cornerstoneTools.mouseWheelInput.enable(imageElement);
        // Enable all tools we want to use with this element
        cornerstoneTools.wwwc.activate(imageElement, 1); // ww/wc is the default tool for left mouse button
        cornerstoneTools.pan.activate(imageElement, 2); // pan is the default tool for middle mouse button
        cornerstoneTools.zoom.activate(imageElement, 4); // zoom is the default tool for right mouse button

        cornerstoneTools.length.enable(imageElement);
        cornerstoneTools.probe.enable(imageElement);
        cornerstoneTools.ellipticalRoi.enable(imageElement);
        cornerstoneTools.rectangleRoi.enable(imageElement);
        cornerstoneTools.angle.enable(imageElement);
    });

}

//播放

export function playImage() {
    $('#footer-slider-play-ctrl').hide();
    $('#footer-slider-playing-ctrl').css('display', 'inline-block');

    isSwitchSliceUseSlider = false;
    cornerstoneTools.playClip(imageElement, frameRate);
    isPlaying = true;
}

export function pausePlayImage() {
    try {
        if (isPlaying) {
            $('#footer-slider-playing-ctrl').hide();
            $('#footer-slider-play-ctrl').css('display', 'inline-block');
            cornerstoneTools.stopClip(imageElement);
            isPlaying = false;
        }
    } catch (ex) {}
}

//工具栏操作方法
export function openWindowLevelPresetDialog() {
    $('.footer-sub-wrapper').hide();
    $('#windowLevelPresetWrapper').show();
}


export function openPseudocolorDialog() {
    $('#pseudocolor-ctrl,#ctrl-close').show();
    $('.ctrl-box .footer-sub-wrapper').show();
}
window.setPseudocolor = () => {
    var canvas = $('canvas').get(0)
    var context = canvas.getContext("2d");
    var imageData = context.getImageData(0, 0, canvas.width, canvas.height);

    for (var i = 0; i < imageData.data.length; i += 4) {
        var gray = imageData.data[i];
        var R = gray,
            G = gray,
            B = gray,
            v = 0;
        if (gray < v && gray == v) {

        } else if (gray < 51 || gray == 51) {
            R = 0;
            G = 5 * (gray);
            B = 255;
        } else if (gray <= 102) {
            gray -= 51;
            R = 0;
            G = 255;
            B = 255 - 5 * gray;
        } else if (gray <= 153) {
            gray -= 102;
            R = 5 * gray;
            G = 255;
            B = 0;
        } else if (gray <= 204) {
            gray -= 153;
            R = 255;
            G = 255 - parseInt(128.0 * gray / 51.0 + 0.5);
            B = 0;
        } else if (gray < 255) {
            gray -= 204;
            R = 255;
            G = 127 - parseInt(127.0 * gray / 51.0 + 0.5);
            B = 0;
        }
        if (gray > v && gray < 255) {
            imageData.data[i] = R;
            imageData.data[i + 1] = G;
            imageData.data[i + 2] = B;
        }
    }

    context.putImageData(imageData, 0, 0);
}
export function setPseudocolor() {
    var canvas = $('canvas').get(0)
    var context = canvas.getContext("2d");
    var imageData = context.getImageData(0, 0, canvas.width, canvas.height);

    for (var i = 0; i < imageData.data.length; i += 4) {
        var gray = imageData.data[i];
        var R = gray,
            G = gray,
            B = gray,
            v = 0;
        if (gray < v && gray == v) {

        } else if (gray < 51 || gray == 51) {
            R = 0;
            G = 5 * (gray);
            B = 255;
        } else if (gray <= 102) {
            gray -= 51;
            R = 0;
            G = 255;
            B = 255 - 5 * gray;
        } else if (gray <= 153) {
            gray -= 102;
            R = 5 * gray;
            G = 255;
            B = 0;
        } else if (gray <= 204) {
            gray -= 153;
            R = 255;
            G = 255 - parseInt(128.0 * gray / 51.0 + 0.5);
            B = 0;
        } else if (gray < 255) {
            gray -= 204;
            R = 255;
            G = 127 - parseInt(127.0 * gray / 51.0 + 0.5);
            B = 0;
        }
        if (gray > v && gray < 255) {
            imageData.data[i] = R;
            imageData.data[i + 1] = G;
            imageData.data[i + 2] = B;
        }
    }

    context.putImageData(imageData, 0, 0);


}

export function toggleSeriesView(forceHide) {
    var v = $('#series-view');
    if (!forceHide && v[0].style.display == 'none') {
        showSeriesView();
        $('#footer-series-tool').addClass('selected');
        window.location.hash = '';
    } else {
        hideSeriesView();
        $('#footer-series-tool').removeClass('selected');

    }
}

function showSeriesView() {
    $('#series-view').show();
}

function hideSeriesView() {
    $('#series-view').hide();
}

//more features
window.isShowingMoreFeatures = false;