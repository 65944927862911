import { pausePlayImage, playImage, toggleSeriesView, openWindowLevelPresetDialog, setPseudocolor } from "./gui"
import { specifyImageIndex, disableAllTools } from "../../js/index"
export function doAction(parms, target) {
    console.log(target);
    switch (parms.action1) {
        case 'SwitchFeatureGroup': //切换功能组

            $('#footer-table td').removeClass('selected');
            $(target.target).addClass('selected');
            $('.footer-sub-wrapper,#footer-slider').hide();
            $('#footer2,#footer-sub-' + parms.action2).show();

            toggleSeriesView(true);

            if (meetingEnabled) return false;
            switch (parms.action2) {
                case 'windowing':
                    disableAllTools();
                    cornerstoneTools.wwwcTouchDrag.activate(imageElement);
                    cornerstoneTools.wwwc.activate(imageElement, 1);
                    break;
                case 'zoomPan':
                    $('#ctrl-close,#pseudocolor-ctrl,#preset-ctrl').hide();
                    disableAllTools();
                    cornerstoneTools.panTouchDrag.activate(imageElement);
                    cornerstoneTools.zoomTouchPinch.activate(imageElement);
                    break;
                case 'measure':
                    $('#ctrl-close,#pseudocolor-ctrl,#preset-ctrl').hide();
                    disableAllTools();
                    cornerstoneTools.lengthTouch.activate(imageElement);
                    break;
                case 'cine':
                    $('#ctrl-close,#pseudocolor-ctrl,#preset-ctrl').hide();
                    break;
                case 'more':
                    $('#ctrl-close,#pseudocolor-ctrl,#preset-ctrl').hide();
                    break;
                default:
                    break;
            }
            break;
        case 'ToggleSeriesLayout': //显示/隐藏序列视图
            if (meetingEnabled) return false;
            $('#footer-table td').removeClass('selected');
            toggleSeriesView();
            break;
        case 'Windowing': //调窗系列功能
            if (meetingEnabled) return false;
            switch (parms.action2) {
                case 'setToInvplain': //反窗
                    $('#footer-sub-windowing>li').removeClass('current-opr');
                    var viewport = cornerstone.getViewport(imageElement);
                    // Toggle invert
                    if (viewport.invert === true) {
                        viewport.invert = false;
                    } else {
                        viewport.invert = true;
                        $(target.target).addClass('current-opr');
                    }
                    cornerstone.setViewport(imageElement, viewport);
                    break;
                case 'showPresetDialog': //预设窗口
                    openWindowLevelPresetDialog();
                    break;
                case 'showPseudocolorDialog': //伪彩
                    $('#footer-sub-windowing>li').removeClass('current-opr');
                    if (isPseudocolor === true) {
                        isPseudocolor = false;
                        cornerstone.reset(imageElement);
                    } else {
                        isPseudocolor = true;
                        $(target.target).addClass('current-opr');
                        setPseudocolor();
                    }
                    break;
                case 'PresetDefault': //默认
                    var viewport = cornerstone.getViewport(imageElement);
                    var image = cornerstone.getImage(imageElement);
                    viewport.voi.windowWidth = image.windowWidth;
                    viewport.voi.windowCenter = image.windowCenter;
                    cornerstone.setViewport(imageElement, viewport);
                    break;
                case 'MinMax': //最大 最小
                    var viewport = cornerstone.getViewport(imageElement);
                    var image = cornerstone.getImage(imageElement);
                    viewport.voi.windowWidth = image.maxPixelValue;
                    viewport.voi.windowCenter = image.minPixelValue;
                    cornerstone.setViewport(imageElement, viewport);
                    break;
                case 'Customized': //自定义 todoMinMax
                    var viewport = cornerstone.getViewport(imageElement);
                    viewport.voi.windowWidth = parseFloat(350);
                    viewport.voi.windowCenter = parseFloat(0);
                    cornerstone.setViewport(imageElement, viewport);
                    break;
                case 'PresetMediastinum': //预设纵膈
                    var viewport = cornerstone.getViewport(imageElement);
                    viewport.voi.windowWidth = parseFloat(350);
                    viewport.voi.windowCenter = parseFloat(0);
                    cornerstone.setViewport(imageElement, viewport);
                    break;
                case 'PresetLung': //预设肺窗
                    var viewport = cornerstone.getViewport(imageElement);
                    viewport.voi.windowWidth = parseFloat(700);
                    viewport.voi.windowCenter = parseFloat(-600);
                    cornerstone.setViewport(imageElement, viewport);
                    break;
                case 'PresetBone': //预设骨窗
                    var viewport = cornerstone.getViewport(imageElement);
                    viewport.voi.windowWidth = parseFloat(1400);
                    viewport.voi.windowCenter = parseFloat(600);
                    cornerstone.setViewport(imageElement, viewport);
                    break;
                case 'PseudocolorRainbow': //预设伪彩
                    setPseudocolor('rainbow', target);
                    break;
                case 'PseudocolorPet':
                    setPseudocolor('pet', target);
                    break;
                case 'PseudocolorHot':
                    setPseudocolor('hot', target);
                    break;
                case 'PseudocolorHotIron':
                    setPseudocolor('hot iron', target);
                    break;
                case 'PseudocolorHotMetalBlue':
                    setPseudocolor('hot metal blue', target);
                    break;
                default:
                    break;
            }
            break;
        case 'ZoomPan':
            if (meetingEnabled) return false;
            $('#footer-sub-zoomPan>li').removeClass('current-opr');
            $(target.target).addClass('current-opr');
            switch (parms.action2) {
                case 'rotateLeft':
                    var viewport = cornerstone.getViewport(imageElement);
                    viewport.rotation -= 90;
                    cornerstone.setViewport(imageElement, viewport);
                    break;
                case 'rotateRight':
                    var viewport = cornerstone.getViewport(imageElement);
                    viewport.rotation += 90;
                    cornerstone.setViewport(imageElement, viewport);
                    break;
                case 'FreeRotate':
                    disableAllTools();
                    cornerstoneTools.rotateTouchDrag.activate(imageElement);
                    break;
                case 'Magnify':
                    disableAllTools();
                    var configM = {
                        magnifySize: 200,
                        magnificationLevel: 2
                    };
                    cornerstoneTools.magnify.setConfiguration(configM);
                    cornerstoneTools.magnifyTouchDrag.activate(imageElement);
                    break;
                case 'Highlight':
                    disableAllTools();
                    cornerstoneTools.highlightTouch.activate(imageElement);
                    break;
                default:
                    break;
            }
            break;
        case 'Measure':
            if (meetingEnabled) return false;
            $('#footer-sub-measure>li').removeClass('current-opr');

            $(target.target).addClass('current-opr');

            disableAllTools();
            switch (parms.action2) {
                case 'line':
                    try {
                        cornerstoneTools.lengthTouch.activate(imageElement);
                        cornerstoneTools.length.activate(imageElement, 1);
                    } catch (error) {
                        console.log(error);
                    }
                    break;
                case 'rectangle':
                    cornerstoneTools.rectangleRoiTouch.activate(imageElement);
                    cornerstoneTools.rectangleRoi.activate(imageElement, 1);
                    break;
                case 'ellipse':
                    cornerstoneTools.ellipticalRoiTouch.activate(imageElement);
                    cornerstoneTools.ellipticalRoi.activate(imageElement, 1);
                    break;
                case 'protractor':
                    cornerstoneTools.angleTouch.activate(imageElement);
                    cornerstoneTools.angle.activate(imageElement, 1);
                    break;
                case 'probe':
                    cornerstoneTools.probeTouch.activate(imageElement);
                    cornerstoneTools.probe.activate(imageElement, 1);
                    //cornerstoneTools.dragProbeTouch.activate(imageElement);
                    break;
                case 'clear':
                    var toolStateManager = cornerstoneTools.globalImageIdSpecificToolStateManager;
                    // Note that this only works on ImageId-specific tool state managers (for now)
                    toolStateManager.clear(imageElement)
                    cornerstone.updateImage(imageElement);
                    break;
                default:
                    break;
            }
            break;
        case 'MaskClick':
            isShowingMoreFeatures = false;
            $('#mask,#qrcodeDlg').hide();
            break;
        case 'MoreFeatures':
            if (meetingEnabled) return false;
            switch (parms.action2) {
                case 'reset':
                    cornerstone.reset(imageElement);
                    $('#footer_subfeature li').removeClass('current-opr');
                    doAction({ action1: 'MaskClick' }, document.getElementById('mask'));
                    break;
                case 'showAnnotations':
                    var infoLayer = $('.infoLayer');
                    if (infoLayer[0].style.display == 'none') {
                        infoLayer.show();
                        $(target).html('隐藏注解');
                    } else {
                        infoLayer.hide();
                        $(target).html('显示注解');
                    }
                    doAction({ action1: 'MaskClick' }, document.getElementById('mask'));
                    break;
                case 'showTags':
                    doAction({ action1: 'MaskClick' }, document.getElementById('mask'));
                    break;
                default:
                    break;
            }

            break;
        case 'Cine':
            if (meetingEnabled) return false;

            switch (parms.action2) {
                case 'play':
                    playImage();
                    break;
                case 'pause':
                    pausePlayImage();
                    break;
                case 'nextImage':
                    pausePlayImage();
                    isSwitchSliceUseSlider = false;
                    specifyImageIndex('next');
                    break;
                case 'previesImage':
                    pausePlayImage();
                    isSwitchSliceUseSlider = false;
                    specifyImageIndex('pre');
                    break;
                case 'showSetFrameRate':
                    var $frameRateEl = $('#frame-rate-list');
                    $frameRateEl.show();
                    setTimeout(function() {
                        $(document).one('click', function() {
                            $frameRateEl.hide();
                        });
                    }, 300);
                    break;
                case 'setFrameRate':
                    $('#frame-rate-list>span').removeClass('active');
                    $(target.target).addClass('active');
                    frameRate = parseInt($(target.target).text());
                    $('#frame-rate-val').text(frameRate);
                    if (isPlaying) {
                        pausePlayImage();
                        playImage();
                    }
                    break;
                default:
                    break;
            }
            break;

        default:
            break;
    }

    return parms;
}

export function doActionReset(target) {
    doAction({ action1: 'MoreFeatures', action2: 'reset' }, target);
    if (isPseudocolor === true) {
        doAction({ action1: 'Windowing', action2: 'showPseudocolorDialog' }, this);
    }
}